import React, { Component } from 'react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import { Container, Row, Col,Input } from 'reactstrap';
import { Line} from 'react-chartjs-2';
import AppConstants from '../AppConstants';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import {logoutUser,getProfile,dashboard} from '../actions/authentication';
import {ReactTitle} from 'react-meta-tags';
import DatePicker from "react-datepicker";
import PropTypes from 'prop-types';
//export default class  dashboard extends Component{
class companydashboard extends React.Component {
    constructor(props) {
        //console.log(props);
        super(props);
        this.state = {      
            rfprfiprofilesdata: [],
            errors: {},
            errMsg:false,
            companypostedrfps:0,
            companylikescount:0,
            companyvendorfavcount:0,
            subscribes:0,
            feedbackcount:0,
            placeholderSD:'MM/DD/YYYY',
            placeholderTD:'MM/DD/YYYY',
            type:'',
            start_date:null,
            end_date:null,
            startDate:'',
            endDate:'',
            type_error:'',
            type_error2:'',
            range_date:'',
            company_rfps_all_dates:[],
            lineone_values:[],
            lineone_values1:[],
            lineone_values2:[],
            fav_vendor_values:[],
            liked_rfps_values:[],
            company_rfps_values:[]

      };
      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleStartdateChange = this.handleStartdateChange.bind(this);
      this.handleEndDateChange = this.handleEndDateChange.bind(this); 
    }
    // componentWillReceiveProps(){
    //   this.props.dashboard("dashboard");
    // }
    handleInputChange(e) {
      console.log(e);
      this.setState({
          [e.target.name]: e.target.value
      });
      var Type = e.target.value;
      console.log(Type);
      console.log(this.state.start_date);
      console.log(this.state.end_date);
    
    
      this.getSDate(Type);
      if(Type == "Custom" && this.state.start_date !=null && this.state.end_date!=null) {
          this.getDPReport(Type,this.state.start_date,this.state.end_date);
      }
      else if(Type == "ThisWeek" || Type == "Monthly" ||  Type == "Yearly") {
        this.getDPReport(Type,this.state.start_date,this.state.end_date);
      }
    }

    handleStartdateChange (date) {
      //console.log('dfdsfdsfds');
      this.setState({
        start_date: date
        //end_date :date
      });
      console.log("start_date",date);
      //console.log(this.state.start_date);
      console.log(this.state.end_date);
      if(date && date!=null && this.state.end_date != null){
        this.getDPReport("Custom",date,this.state.end_date);
        //this.getMASReport("Custom",date,this.state.end_date);
        this.state.type_error='';
        this.state.type_error2='';
      }
      if(date && date==null)
      this.state.type_error = "Please select start date";
      else if(date && date!=null) {
      this.state.type_error = "";
      this.state.type = "Custom";
      }
    }
    getSDate(type) {
      let errors = {};
      let formIsValid = true;
    
      console.log(this.state.start_date);
      console.log(this.state.end_date);
          
      if(type == "Custom" && this.state.start_date == null && this.state.end_date == null){
          formIsValid = false;
          this.state.type = "Custom";
          this.state.type_error = "Please select start date";
          this.state.type_error2="Please select end date";
          this.state.placeholderSD = "MM/DD/YYYY";
          this.state.placeholderTD = "MM/DD/YYYY";
          return false;
      } else {
        this.state.type_error='';
        this.state.type_error2='';
        this.state.start_date=null;
        this.state.end_date=null;
      }
    };
    handleEndDateChange(date1) {
      this.setState({
        //start_date: date,
        end_date :date1
        
      });
      console.log("end_date",date1);
      console.log("start_date",this.state.start_date);
      console.log(date1);
      if(date1 && date1!=null && this.state.start_date != null){
        this.getDPReport("Custom",this.state.start_date,date1);
        //this.getMASReport("Custom",this.state.start_date,date1);
        this.state.type_error='';
        this.state.type_error2='';
      }
      
      if(date1 && date1==null)
      this.state.type_error2="Please select end date";
      else if(date1 && date1!=null){
      this.state.type_error2 = "";
      this.state.type = "Custom";
      }
    
      if(new Date(date1).toISOString() >= new Date(this.state.start_date).toISOString()) {
        this.getDPReport("Custom",this.state.start_date,date1);
        //this.getMASReport("Custom",this.state.start_date,date1);
      } else {
        this.state.type_error2="To date should be greater than From date";
      }     
      
    }
    getDPReport(Type,custom_date,end_date){
      var UserID = this.props.auth.user._id;
      fetch(AppConstants.API+'/reports/allpostedrfps/'+ UserID +'/'+Type+'/'+custom_date+'/'+end_date).then(response => response.json())
        .then(data => {
          if(data.status == "success")
          {
            fetch(AppConstants.API+'/reports/alllikedrfps/'+ UserID +'/'+Type+'/'+custom_date+'/'+end_date).then(response => response.json())
            .then(data1 => { 
              //console.log(data);
              if(data1.status == "success")
              {
                fetch(AppConstants.API+'/reports/allfavvendors/'+ UserID +'/'+Type+'/'+custom_date+'/'+end_date).then(response => response.json())
                .then(data2 => {
                    if(data2.status == "success")
                    {
                      var genLineDTv={
                        labels: data.company_rfps_all_dates,
                        datasets: [
                          {
                            label: 'Total',
                            backgroundColor: '#0062cc',
                            borderColor: '#0062cc',
                            borderWidth: 1,
                            data: data.company_rfps_values,
                            fill:false
                          },
                          {
                            label: 'Liked',
                            backgroundColor: '#1e7e34',
                            borderColor: '#1e7e34',
                            borderWidth: 1,
                            data: data1.liked_rfps_values,
                            fill:false
                          },
                          {
                            label: 'Favorite',
                            backgroundColor: '#bd2130',
                            borderColor: '#bd2130',
                            borderWidth: 1,
                            data: data2.fav_vendor_values,
                            fill:false
                           }
                        ],
                      };    
                      var sd = data.company_rfps_all_dates[0];
                      var ed = data.company_rfps_all_dates[data.company_rfps_all_dates.length-1];          
                      this.setState({ all_dates: data.company_rfps_all_dates,lineone_values:data.company_rfps_values,lineone_values1:data1.liked_rfps_values,lineone_values2:data1.fav_vendor_values,genLineDT:genLineDTv,type:Type,placeholderSD:sd,placeholderTD:ed }); 
                    }
                    else
                    {
                      this.setState({ fav_vendor_dates: [] });
                    }
                  });
              }
              else
              {
                this.setState({ liked_rfps_all_dates: [] });
              }
            });
          }
          else
          {
            this.setState({ company_rfps_all_dates: [] });
          }

        });
    }

    Allrelateddata = params => e => {
      e.preventDefault();
      fetch(AppConstants.API+'/rfprfiprofiles/getcompanypostedrfprfisdata/'+this.props.auth.user._id).then(response => response.json())
          .then(data => {
          //console.log(data);
          if(data.Status == "Success")
          {
              this.setState({ rfprfiprofilesdata: data.rfprfiprofilesdata,applyanimate:'applyoldClass',likedanimate:'likeoldClass',allanimate: "allnewClass"});
          }
          else
          {
              this.setState({ rfprfiprofilesdata: [],applyanimate:'applyoldClass',likedanimate:'likeoldClass',allanimate: "allnewClass"});
          }    
      });
    }

    //Apply Vendor RFPs
    applyrfps = params => e => {
      console.log('applyrfps');

    }
    //Liked Vendor RFPs
    likedrfps = params => e => {
      console.log('likedrfps');
    }

    componentDidMount() {
      this.props.dashboard("dashboard");
      window.scrollTo(0, 0);

      console.log(this.props.auth.user._id);
        //fetch(AppConstants.API+'/rfprfiprofiles/getallrfprfiprofiles').then(response => response.json())
         // .then(data => {
         //console.log(data);
        fetch(AppConstants.API+'/rfprfiprofiles/getcompanypostedrfprfisdata/'+this.props.auth.user._id).then(response => response.json())
         .then(data => {
          if(data.Status == "Success")
          {
            this.setState({ rfprfiprofilesdata: data.rfprfiprofilesdata });
          }
          else
          {
            this.setState({ rfprfiprofilesdata: []});
          }    
        });

        fetch(AppConstants.API+'/company/getallpostedrfprfiscount/'+this.props.auth.user._id).then(response => response.json())
         .then(data => {
          if(data.Status == "Success")
          {
            this.setState({ companypostedrfps: data.companypostedrfps });
          }            
        });
        fetch(AppConstants.API+'/company/getalllikedrfpscount/'+this.props.auth.user._id).then(response => response.json())
         .then(data => {
          if(data.Status == "Success")
          {
            this.setState({ companylikescount: data.companylikescount });
          }
            
        });
        fetch(AppConstants.API+'/company/getallfavcount/'+this.props.auth.user._id).then(response => response.json())
         .then(data => {
          if(data.Status == "Success")
          {
            this.setState({ companyvendorfavcount: data.companyvendorfavcount });
          }            
        });
        // fetch(AppConstants.API+'/company/getallsubscribecount/'+this.props.auth.user._id).then(response => response.json())
        //  .then(data => {
        //   if(data.Status == "Success")
        //   {
        //     this.setState({ subscribes: data.subscribes });
        //   }            
        // });
        fetch(AppConstants.API+'/reports/getallsubscribedvendorreport/'+this.props.auth.user._id).then(response => response.json())
         .then(data => {
          console.log(data);
          if(data.Status == "Success")
          {
            this.setState({ subscribes: data.subscribes });
          }
          else
          {
            this.setState({ subscribes: 0});
          }    
        });
        fetch(AppConstants.API+'/cutomers/feedbackcount/'+this.props.auth.user._id).then(response => response.json())
         .then(data => {
          if(data.Status == "Success")
          {
            this.setState({ feedbackcount: data.feedbackcount });
          }
            
        });
        this.getDPReport("ThisWeek",null,null);
    }

    renderShowsTotal(start, to, total) {
        return (
          <p className="page-show">
          Showing rows { start } to { to } of { total }
          </p>
        );
      }
      actionBtn(cell,row) {
    /* return  <div className="action-block"><a href="" onClick={this.Action} className="view-icon"><i class="fa fa-eye" aria-hidden="true"></i></a><a href="" onClick={this.Action} className="btn btn-sm"><i class="fa fa-edit" aria-hidden="true"></i></a></div>;  */       
    return  <div className="action-block"><Link to={`${AppConstants.STAGRFP}${"/rfpinfo/"}${row._id}`}  className="view-icon" title="View Opportunity"><i class="fa fa-eye" aria-hidden="true"></i></Link><Link to={`${AppConstants.STAGRFP}${"/editrfprfi/"}${row._id}`} className="view-icon edit-icon" title="Edit Opportunity"><i class="fa fa-edit" aria-hidden="true"></i></Link><Link to={`${AppConstants.STAGRFP}${"/viewfeedback/"}${row._id}`} className="view-icon feedback count-btn"  title="Opportunity Feedback"><i class="fa fa-comments" aria-hidden="true"></i><span>{row.feedbackvendorsdata}</span></Link>
    <Link to={`${AppConstants.STAGRFP}${"/likedrfpvendors/"}${row._id}`} className="applied liked rfpliked count-btn"  title="Opportunity Liked"><em></em><span>{row.likedvendorsdata}</span></Link>
    <Link to={`${AppConstants.STAGRFP}${"/appliedrfpvendors/"}${row._id}`} className="applied rfpliked  count-btn"  title="Opportunity Applied"><em></em><span>{row.applyvendorsdata}</span></Link>
    </div>; 
    }

    dateFormat(cell,row) {
       // var date = row.rfp_post_date;
       var posteddate = row.rfp_post_date;
        var posteddate= new Date(posteddate);             
        return   ("0" + (posteddate.getMonth() + 1)).slice(-2) + "-" + (posteddate.getDate() < 10 ? '0' : '')+ posteddate.getDate() + "-" +  posteddate.getFullYear();      

        }

        duedateFormat(cell,row){
            var duedate = row.due_dateandtime;
            var duedate= new Date(duedate);
            var hours = duedate.getHours();
            var minutes = duedate.getMinutes();
            var ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes < 10 ? '0'+minutes : minutes;
            var strTime = hours + ':' + minutes + ' ' + ampm;
            return  ("0" + (duedate.getMonth() + 1)).slice(-2) + "-" + (duedate.getDate() < 10 ? '0' : '')+ duedate.getDate() + "-" + duedate.getFullYear() + " " + strTime;
        }
    
        naicInfo(cell,row) {
          return  <div>
                    <span className="naiccodes">
                        <ul style={{listStyle:'none', padding:'0'}}>
                            {row.naiccodes.map(recipe => {
                                    return (
                                        
                                        <li>{recipe}<span>,</span></li>
                                    
                                        )
                                    })
                                }                           
                        </ul>
                        {row.naiccodes == '' && <span>No NAICS Codes</span>} 
                    </span>
                </div> ;        
          }
    
    
  render() {    
    
const options = {
  page: 1,  // which page you want to show as default
  sizePerPageList: [ {
    text: '10', value: 10
  }, {
    text: '25', value: 25
  }, {
    text: 'All', value: this.state.rfprfiprofilesdata.length
  } ], // you can change the dropdown list for size per page
  sizePerPage: 10,  // which size per page you want to locate as default
  pageStartIndex: 1, // where to start counting the pages
  paginationSize: 5,  // the pagination bar size.
  prePage: '<', // Previous page button text
  nextPage: '>', // Next page button text
  firstPage: 'First', // First page button text
  lastPage: 'Last', // Last page button text
  paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
  paginationPosition: 'bottom'  // default is bottom, top and both is all available
 
};
if(localStorage.jwtToken != undefined && this.props.auth.user.type =='company'){
    return (
        <div>
            <ReactTitle title="Dashboard" />
            <div className="inner-banner">
            </div>
            <div className="inner-content">
                <Container>
                    <h1>Dashboard</h1>
                    <div className="dash-stats-block">
                        <Row className="row-flex justify-content-md-center">
                            <div className="col-md-4 col-lg-4 dash-stats-list">
                                <Link to={`${AppConstants.STAGRFP}${"/allrfprfis"}`}>
                                    <div className="dash-stats-inner com-stat" title="Total opportunities">
                                        <h2>{this.state.companypostedrfps}</h2>
                                        <h6>Total</h6>
                                        <span>Opportunities Posted</span>
                                        <div className="stat-icon"></div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-4 col-lg-4 dash-stats-list">
                                <Link to={`${AppConstants.STAGRFP}${"/rfplikedvendors"}`} >
                                    <div className="dash-stats-inner post-stat" title="Liked opportunities">
                                        <h2>{this.state.companylikescount}</h2>
                                        <h6>Liked</h6>
                                        <span>Opportunities Liked by Vendors</span>
                                        <div className="stat-icon"></div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-4 col-lg-4 dash-stats-list">
                                <Link to={`${AppConstants.STAGRFP}${"/allfavoritevendors"}`} >
                                    <div className="dash-stats-inner companies-stat" title="Favorited">
                                        <h2>{this.state.companyvendorfavcount}</h2>
                                        <h6>Favorite</h6>
                                        <span>Favorite Vendors</span>
                                        <div className="stat-icon"></div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-4 col-lg-4 dash-stats-list">
                                <Link to={`${AppConstants.STAGRFP}${"/vendorrfpfeedbacks"}`} >
                                    <div className="dash-stats-inner applied-stat" title="Feedback opportunities">
                                        <h2>{this.state.feedbackcount}</h2>
                                        <h6>Feedback</h6>
                                        <span>Opportunity Feedback</span>
                                        <div className="stat-icon"></div>
                                    </div>
                                </Link>
                            </div>       
                                                
                            <div className="col-md-4 col-lg-4 dash-stats-list">
                                <Link to={`${AppConstants.STAGRFP}${"/subscribedvendors"}`} >
                                    <div className="dash-stats-inner won-stat" title="Subscribed">
                                        <h2>{this.state.subscribes}</h2>
                                        <h6>Subscribed</h6>
                                        <span>Vendors Subscribed</span>
                                        <div className="stat-icon"></div>
                                    </div>
                                </Link>
                            </div>                            
                        </Row>                                                                                          
                    </div>
                    
                    <div className="dashboard-rfp-list">
                        <div className="dashboard-rfp-head">
                            <h6>Opportunities List</h6>
                            {/* <span className={this.state.allanimate +" all"}  onClick={ this.Allrelateddata('alldata') } title="All RFPs">All</span>
                            <span className={this.state.applyanimate +" applied"} title="Applied RFPs" onClick={ this.applyrfps('apply') }>Applied</span>
                            <span className={this.state.likedanimate +" liked"} title="Liked RFPs" onClick={ this.likedrfps('liked') }>Liked</span> */}
                        </div>
                        <BootstrapTable  className="last-width-table" data={this.state.rfprfiprofilesdata} pagination={ true } options={ options } search={true} searchPlaceholder={'Search by Opportunity Name or NAICS Code' } >
                            
                            {/* <TableHeaderColumn dataField='_id' isKey={ true } dataSort={ true } hidden={true}>ID</TableHeaderColumn> */}

                            <TableHeaderColumn dataField='name_ofrfp' isKey={ true } dataSort={ true }>Name Of Opportunity</TableHeaderColumn>
                            <TableHeaderColumn dataField='naiccodes' dataFormat={ this.naicInfo.bind(this) } dataSort={ true }>NAICS Code</TableHeaderColumn>
                            <TableHeaderColumn dataFormat={this.dateFormat.bind(this)} dataField='rfp_post_date' width={'13%'} dataSort={ true }>Posted Date</TableHeaderColumn>
                            <TableHeaderColumn dataField='due_dateandtime' dataFormat={this.duedateFormat.bind(this)}  dataSort={ true }>Due Date & Time</TableHeaderColumn>
                            <TableHeaderColumn dataField='Action' dataFormat={ this.actionBtn.bind(this) }>Actions</TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                    <div className="graph-block">
                                <div className="graph-date">
                                <Row className="justify-content-md-center ">
                                <div className="col-md-2 mb-0">
                                <Input type="select" name = "reports"  onChange={ this.handleInputChange } value={this.state.type}>
                                    <option value="ThisWeek">This Week </option>
                                    <option value="Monthly">This Month</option>
                                    <option value="Yearly">Yearly</option>
                                    <option value="Custom">Custom</option>
                                </Input>
                                </div>
                                <div className="form-field  col-md-2">
                                    <div className="graph-field">
                                        <DatePicker
                                            name="start_date"
                                            className="form-control"
                                            //placeholderText="MM-DD-YYYY"
                                            placeholderText={this.state.placeholderSD}
                                            //placeholderText="02/09/2019"
                                            selected={this.state.start_date}
                                            selectsStart
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            startDate={this.state.startDate}
                                            endDate={this.state.endDate}
                                            onChange={this.handleStartdateChange}
                                            autoComplete = "off"
                                            minDate={new Date().setFullYear(new Date().getFullYear() - 5)}   
                                            maxDate={new Date()}
                                             />
                                        <i class="fa fa-calendar" aria-hidden="true"></i>
                                        <span className="error" style={{color: 'red'}}>{this.state.type_error}</span>
                                        </div>
                                    </div>
                                    <div className="form-field  col-md-2">
                                        
                                        <div className="graph-field">
                                        <DatePicker
                                            name="end_date"
                                            className="form-control"
                                            //placeholderText="MM-DD-YYYY"
                                            placeholderText={this.state.placeholderTD}                    
                                            selected={this.state.end_date}
                                            selectsEnd
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            //startDate={this.state.startDate}
                                            //
                                            endDate={this.state.endDate}
                                            onChange={this.handleEndDateChange}
                                            autoComplete = "off"

                                            minDate={new Date().setFullYear(new Date().getFullYear() - 5)}   
                                            maxDate={new Date()}
                                            className="form-control" />
                                        <i class="fa fa-calendar" aria-hidden="true"></i>
                                        <span className="error" style={{color: 'red'}}>{this.state.type_error2}</span>
                                        </div>
                                    </div>
                                    <span className="error">{this.state.errors["range_date"]}</span>
                                    </Row>
                                    </div>                                
                                <Line data={this.state.genLineDT} width={100} height={50} />
                                </div>
                </Container>
            </div>
        </div>
        
        );
    }
    else
    {
        window.location.href = AppConstants.STAGRFP+'/';
    }
  }
}
companydashboard.propTypes = {
    dashboard: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
    auth: state.auth,
    
  })
  //export default Header;
  export default connect(mapStateToProps, {logoutUser,getProfile,dashboard})(withRouter(companydashboard));

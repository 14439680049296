import React, { Component } from 'react';
import { MDBInput,MDBBtn } from 'mdbreact';
import {
  MDBSelect,
  MDBSelectInput,
  MDBSelectOptions,
  MDBSelectOption,
} from "mdbreact";


import '../css/style-guide.css';
export default class  styleguide extends Component{
  render() {
    return (
      
      <body class="style-guide">

      <div class="wrapper">
          <section class="col-md-12 content-block">
              <div class="row row-eq-height justify-content-center">
                  <div class="col-md-5 style-block">
                      <div class="style-title">Headings</div>
                      <h1>Heading H1</h1>
                      <code class="language-html" data-lang="html"><span>&lt;h1&gt;</span>Heading H1<span>&lt;/h1&gt;</span></code><br/>
                      <h2>Heading H2</h2>
                      <code class="language-html" data-lang="html"><span>&lt;h2&gt;</span>Heading H2<span>&lt;/h2&gt;</span></code><br/>
                      <h3>Heading H3</h3>
                      <code class="language-html" data-lang="html"><span>&lt;h3&gt;</span>Heading H3<span>&lt;/h3&gt;</span></code><br/>
                      <h4>Heading H4</h4>
                      <code class="language-html" data-lang="html"><span>&lt;h4&gt;</span>Heading H4<span>&lt;/h4&gt;</span></code><br/>
                      <h5>Heading H5</h5>
                      <code class="language-html" data-lang="html"><span>&lt;h5&gt;</span>Heading H5<span>&lt;/h5&gt;</span></code><br/>
                      <h6>Heading H6</h6>
                      <code class="language-html" data-lang="html"><span>&lt;h6&gt;</span>Heading H6<span>&lt;/h6&gt;</span></code><br/>
                  </div>
              </div>
              <div class="row row-eq-height justify-content-center">
                  <div class="col-md-5 style-block">
                      <div class="style-title">Title</div>
                      <div class="page-title"><h1>Title Text</h1></div>
                      <code class="language-html" data-lang="html">
                          <span>&lt;div </span>class="page-title"<span>&gt;</span><span>&lt;h1</span><span>&gt;</span>Title Text<span>&lt;/h1&gt;</span><span>&lt;/div&gt;</span><br/>
                      </code>
                  </div>
              </div>
              
              <div class="row row-eq-height justify-content-center">
                  <div class="col-md-5 style-block paragraph-block">
                      <div class="style-title">Paragraph</div>
                      <p>Paragraph</p>
                      <code class="language-html" data-lang="html">
                        <span>&lt;p&gt;</span>Paragraph<span>&lt;/p&gt;</span>
                      </code>
                  </div>
              </div>
              <div class="row row-eq-height justify-content-center">
                  <div class="col-md-5 style-block color-blocks">
                      <div class="style-title">Colors for Text <small>If we need to change any text color, add below class name to any tag ( p tag, anchor tag, heading tag, div tag etc).</small></div>
                      <div class="color-yellow">
                          <em>Color Yellow</em>
                          <code class="language-html" data-lang="html">class="color-yellow"</code>
                          <div class="example">
                              <em>Examples:</em>
                              <code class="language-html" data-lang="html"><span>&lt;div </span>class="color-yellow"<span>&gt;</span>Color Yellow<span>&lt;/div&gt;</span><br/></code>
                              <code class="language-html" data-lang="html"><span>&lt;p </span>class="color-dark-blue"<span>&gt;</span>Color Dark Blue<span>&lt;/p&gt;</span><br/></code>
                              <code class="language-html" data-lang="html"><span>&lt;a </span>class="color-dark-gray"<span>&gt;</span>Color Dark Gray<span>&lt;/a&gt;</span><br/></code>
                          </div>
                      </div>
                      <div class="color-blue-light">
                          <em>Color Dark Blue</em>
                          <code class="language-html" data-lang="html">class="color-dark-blue"</code>
                      </div>
                      <div class="color-dark-gray">
                          <em>Color Dark Gray</em>
                          <code class="language-html" data-lang="html">class="color-dark-gray"</code>
                      </div>
                      <div class="color-gray">
                          <em>Color Gray</em>
                          <code class="language-html" data-lang="html">class="color-gray"</code>
                      </div>
                  </div>
              </div>
              <div class="row row-eq-height justify-content-center">
                  <div class="col-md-5 style-block background-blocks">
                      <div class="style-title">Backgrounds <small>If we need to change any background color, add below class name to any tag ( p tag, anchor tag, heading tag, div tag etc).</small></div>
                      <div class="bg-yellow">
                          Background Yellow
                      </div>
                      <code class="language-html" data-lang="html">class="bg-yellow"</code>
                      <div class="example">
                          <em>Examples:</em>
                          <code class="language-html" data-lang="html"><span>&lt;div </span>class="bg-yellow"<span>&gt;</span>Background Yellow<span>&lt;/div&gt;</span><br/></code>
                          <code class="language-html" data-lang="html"><span>&lt;p </span>class="bg-dark-blue"<span>&gt;</span>Background Dark Blue<span>&lt;/p&gt;</span><br/></code>
                          <code class="language-html" data-lang="html"><span>&lt;a </span>class="bg-dark-gray"<span>&gt;</span>Background Dark Gray<span>&lt;/a&gt;</span><br/></code>
                      </div>
                      <div class="bg-dark-blue">
                          Background Dark Blue
                      </div>
                      <code class="language-html" data-lang="html">class="bg-dark-gray"</code><br/>
                      <div class="bg-dark-gray">
                          Background Dark Gray
                      </div>
                      <code class="language-html" data-lang="html">class="bg-dark"</code><br/>
                      <div class="bg-gray">
                          Background Gray
                      </div>
                  </div>
              </div>
              <div class="row row-eq-height justify-content-center">
                  <div class="col-md-5 style-block list-blocks">
                      <div class="style-title">List Arrow</div>
                      <ul class="list-arrow">
                          <li>Default List Item 01</li>
                          <li>Default List Item 02</li>
                          <li>Default List Item 03</li>
                          <li>Default List Item 04</li>
                          <li>Default List Item 05</li>
                      </ul>
                      <code class="language-html" data-lang="html">
                          <span>&lt;ul <span>class="list-arrow"</span>&gt;</span><br/>
                              <span>&lt;li&gt;</span>Default List Item 01<span>&lt;/li&gt;</span><br/>
                              <span>&lt;li&gt;</span>Default List Item 02<span>&lt;/li&gt;</span><br/>
                              <span>&lt;li&gt;</span>Default List Item 03<span>&lt;/li&gt;</span><br/>
                              <span>&lt;li&gt;</span>Default List Item 04<span>&lt;/li&gt;</span><br/>
                              <span>&lt;li&gt;</span>Default List Item 05<span>&lt;/li&gt;</span><br/>
                          <span>&lt;/ul&gt;</span>
                      </code>
                  </div>
              </div>
              <div class="row row-eq-height justify-content-center">
                  <div class="col-md-5 style-block list-blocks">
                      <div class="style-title">List Bullet</div>
                      <ul class="list-bullet">
                          <li>Custom List Item 01</li>
                          <li>Custom List Item 02</li>
                          <li>Custom List Item 03</li>
                          <li>Custom List Item 04</li>
                          <li>Custom List Item 05</li>
                      </ul>
                      <code class="language-html" data-lang="html">
                          <span>&lt;ul <span>class="list-bullet"</span>&gt;</span><br/>
                              <span>&lt;li&gt;</span>Custom List Item 01<span>&lt;/li&gt;</span><br/>
                              <span>&lt;li&gt;</span>Custom List Item 02<span>&lt;/li&gt;</span><br/>
                              <span>&lt;li&gt;</span>Custom List Item 03<span>&lt;/li&gt;</span><br/>
                              <span>&lt;li&gt;</span>Custom List Item 04<span>&lt;/li&gt;</span><br/>
                              <span>&lt;li&gt;</span>Custom List Item 05<span>&lt;/li&gt;</span><br/>
                          <span>&lt;/ul&gt;</span>
                      </code>
                  </div>
              </div>
              <div class="row row-eq-height justify-content-center">
                  <div class="col-md-5 style-block btn-blocks">
                      <div class="style-title">Buttons</div>
                      <a href="#" class="btn">Button</a>
                      <code class="language-html" data-lang="html"><span>&lt;a</span> <span>href="#"</span> <span>class="btn"</span><span>&gt;</span>Button<span>&lt;/a&gt;</span><br/></code><br/>
                      <em>(Normal Button)</em>
                      <a href="#" class="btn">Button<i class="fa fa-angle-right" aria-hidden="true"></i></a>
                      <code class="language-html" data-lang="html"><span>&lt;a</span> <span>href="#"</span> <span>class="btn"</span><span>&gt;</span>Button&lt;i class="fa fa-angle-right" aria-hidden="true"&gt;&lt;/i&gt;<span>&lt;/a&gt;</span><br/></code><br/>
                      <em>(Icon Button)</em>
                      <button type="submit" value="" class="btn">Submit</button>
                      <code class="language-html" data-lang="html"><span>&lt;button</span> <span>type="submit"</span> <span>value=""</span> <span>class="btn"</span><span>&gt;</span>Submit<span>&lt;/button&gt;</span></code><br/>
                      <em>(button type="submit")</em>
                      <input type="submit" value="Submit" class="btn" />
                      <code class="language-html" data-lang="html"><span>&lt;input</span> <span>type="submit"</span> <span>value="Submit"</span> <span>class="btn"</span><span>&gt;</span></code><br/>
                      <em>(input type="submit")</em>
                  </div>
              </div>
              <div class="row row-eq-height justify-content-center">
                  <div class="col-md-5 style-block btn-blocks">
                      <div class="style-title">Button Colors</div>
                      <a href="#" class="btn">Background Yellow</a>
                      <code class="language-html" data-lang="html">
                          <span>&lt;a</span> <span>href="#"</span> <span>class="btn"</span><span>&gt;</span>Background Yellow<span>&lt;/a&gt;</span>
                      </code><br/>
                      <a href="#" class="btn btn-white-bg">White Background</a>
                      <code class="language-html" data-lang="html">
                          <span>&lt;a</span> <span>href="#"</span> <span>class="btn btn-white-bg"</span><span>&gt;</span>White Background<span>&lt;/a&gt;</span>
                      </code><br/>
                      
                  </div>
              </div>
              <div class="row row-eq-height justify-content-center">
                  <div class="col-md-5 style-block btn-blocks">
                      <div class="style-title">Button Sizes</div>
                      <a href="#" class="btn btn-lg">Large</a>
                      <code class="language-html" data-lang="html">
                          <span>&lt;a <span>href="#"</span></span> class="btn btn-lg"<span>&gt;</span>Large<span>&lt;/a&gt;</span>
                      </code><br/>
                      <a href="#" class="btn btn-md">Medium</a>
                      <code class="language-html" data-lang="html">
                          <span>&lt;a <span>href="#"</span></span> class="btn btn-md"<span>&gt;</span>Medium<span>&lt;/a&gt;</span>
                      </code><br/>
                      <a href="#" class="btn btn-sm">Small</a>
                      <code class="language-html" data-lang="html">
                          <span>&lt;a <span>href="#"</span></span> class="btn btn-sm"<span>&gt;</span>Small<span>&lt;/a&gt;</span>
                      </code><br/>
                  </div>
              </div>
              <div class="row row-eq-height justify-content-center">
                  <div class="col-md-5 style-block btn-blocks">
                      <div class="style-title">Form Fields</div>
                      
                      <div className="form-field">
                        <MDBInput label="User Name" />
                      </div>
                      <div className="form-field">
                        <MDBInput label="User Name" className="form-success" />
                      </div>
                      <div className="form-field">
                        <MDBInput label="User Name" className="form-error" />
                      </div>
                      <div class="form-group">
                          <select class="cs-select cs-skin-elastic form-control">
                              <option value="" disabled selected>Select Box</option>
                              <option value="">Option 1</option>
                              <option value="">Option 2</option>
                              <option value="">Option 3</option>
                              <option value="">Option 4</option>
                          </select>
                      </div>
                      <div class="form-group">
                          <MDBInput type="textarea" label="Textarea" rows="5" />
                      </div>
                      <div class="form-group form-check">
                          <input type="checkbox" id="checkbox1" />
                          <label for="checkbox1">Checkbox 1</label>
                      </div>
                      <div class="form-group form-check">
                          <input type="checkbox" id="checkbox2" />
                          <label for="checkbox2">Checkbox 2</label>
                      </div>
                      <div class="form-group form-check">
                          <input type="radio" id="radio1" name="radio" />
                          <label for="radio1">Radio 1</label>
                      </div>
                      <div class="form-group form-check">
                          <input type="radio" id="radio2" name="radio" />
                          <label for="radio2">Radio 2</label>
                      </div>
                      <div class="form-group form-check inline">
                          <input type="checkbox" id="checkboxinline1" />
                          <label for="checkboxinline1">Checkbox inline 1</label>
                      </div>
                      <div class="form-group form-check inline">
                          <input type="checkbox" id="checkboxinline2" />
                          <label for="checkboxinline2">Checkbox inline 2</label>
                      </div>
                      <br/>
                      <div class="form-group form-check inline">
                          <input type="radio" id="radio3" name="radioinline" />
                          <label for="radio3">Radio inline 1</label>
                      </div>
                      <div class="form-group form-check inline">
                          <input type="radio" id="radio4" name="radioinline" />
                          <label for="radio4">Radio inline 2</label>
                      </div>
      
                  </div>
              </div>
            
              
      
      
      
      
          </section>
      </div>
      
      </body>
  
  )
  }
}

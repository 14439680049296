import axios from 'axios';
import { GET_ERRORS, SET_USER,SET_COMPANY_USER,GET_COMPANY_DETAILS,GET_VENDOR_DETAILS,UPDATE_VENDOR,UPDATE_COMPANY,COMPANY_DASH_BOARD,VENDOR_DASH_BOARD} from './types';
import setAuthToken from '../setAuthToken';
import jwt_decode from 'jwt-decode';
import AppConstants from '../AppConstants';

//Company login
export const loginCompany = (user) => dispatch => {
    axios.post(AppConstants.API+'/cutomers/customerlogin', user).then(res =>
    {
        if(res.data.Status == 'Failed' )
        {
            dispatch({
                type: GET_ERRORS,
                payload: res.data
            }); 
        }else{
            //console.log(res.data);
            const { token } = res.data;
            localStorage.setItem('jwtToken', token);
            setAuthToken(token);
            const decoded = jwt_decode(token);
            dispatch(setCompanyUser(decoded));
            //history.push('/companydashboard')
            window.location.href=AppConstants.STAGRFP+'/companydashboard';
        }
    })
}
// export const getactiveclass= (getclass) => dispatch=>{
//     axios.post(AppConstants.API+'')
// }
/* export const addcompany = (companyids) => dispatch => {
    console.log(companyids);
    dispatch({
        type: GET_COMPANY_ID,
        payload: companyids
    });
} */

//Set session current Company
export const setCompanyUser = decoded => {
    return {
        type: SET_USER,
        payload: decoded
    }
}
export const dashboard=(val)=>dispatch=>{
    //console.log(val)
    dispatch({
        type: COMPANY_DASH_BOARD,
        payload: val,
    });
}
export const handlevendordashboard=(val)=>dispatch=>{
    //console.log(val)
    dispatch({
        type:VENDOR_DASH_BOARD,
        payload:val
    })
}
//Vendor login
export const loginVendor = (vendorlogin) => dispatch => {
    axios.post(AppConstants.API+'/vendors/vendorlogin', vendorlogin).then(res =>
    {
        if(res.data.Status == 'Failed' )
        {
            dispatch({
                type: GET_ERRORS,
                payload: res.data
            }); 
        }else{
            //console.log(res.data);
            const { token } = res.data;
            localStorage.setItem('jwtToken', token);
            setAuthToken(token);
            const decoded = jwt_decode(token);
            dispatch(setCurrentCompanyUser(decoded));
            window.location.href=AppConstants.STAGRFP+'/dashboard';
        }
    })
}

//Set session current Company
export const setCurrentCompanyUser = decoded => {
    return {
        type: SET_COMPANY_USER,
        payload: decoded
    }
}
//get company user details
export const getProfile = (id,history )=> async dispatch =>
{
   //console.log(id);
    fetch(AppConstants.API+'/cutomers/editcustomer/'+id).then(response => response.json())
    .then(data => {
        
        if(data.Status == "Failed" )
        {
            dispatch({
                type: GET_ERRORS,
                payload: data.msg
            });
        }
        else
        {
            //console.log(data);
            dispatch({
                type: GET_COMPANY_DETAILS,
                payload: data.getcustomerdata,

            });
        }
    });
};

//get company user details
export const getVendorProfile = (id,history )=> async dispatch =>
{
   //console.log(id);
    fetch(AppConstants.API+'/vendors/getvendordetails/'+id).then(response => response.json())
    .then(data => {
        //console.log(data);
        
        if(data.Status == "Failed" )
        {
            dispatch({
                type: GET_ERRORS,
                payload: data.msg
            });
        }
        else
        {
            dispatch({
                type: GET_VENDOR_DETAILS,
                payload: data.getvendorsdata
            });
        }
    });
};

//Vendor Profile update
export const updateVendorProfile = (formData,config,history) => dispatch => {

    //console.log(formData);
    axios.post(AppConstants.API+'/vendors/updatevendor', formData,config).then(res => 
    {
        //console.log("aaa" + res.data.msg);
        if(res.data.Status == 'Success'){
            const update_data = res.data;
            dispatch(updateVendor(update_data));              
            //history.push('/dashboard')
            setTimeout(() =>
            window.location.href=AppConstants.STAGRFP+'/dashboard',3000)
        }else
        {
            dispatch({
                type: GET_ERRORS,
                payload: res.data.msg
            });
            setTimeout(() =>
            window.location.href=AppConstants.STAGRFP+'/vendorinfo',3000)
        }       
       
    });
}

//Update Vendor details
export const updateVendor = update_data => {
	//console.log(update_data);
    return {
        type: UPDATE_VENDOR,
        payload: update_data
    }
}

//Company Profile update
export const updateCompanyProfile = (formData,config,history) => dispatch => {

    console.log(formData);
    axios.post(AppConstants.API+'/cutomers/updatecustomer', formData,config).then(res => 
    {
        //console.log("aaa" + res.data.msg);
        if(res.data.Status == 'Success'){
            const update_data = res.data;
            dispatch(updateCompany(update_data));              
            //history.push('/dashboard')
            setTimeout(() =>
            window.location.href=AppConstants.STAGRFP+'/companydashboard',3000)
        }else
        {
            dispatch({
                type: GET_ERRORS,
                payload: res.data.msg
            });
        }       
       
    });
}

//Update Company details
export const updateCompany = update_data => {
	//console.log(update_data);
    return {
        type: UPDATE_COMPANY,
        payload: update_data
    }
}

export const logoutUser = (history) => dispatch => {
    localStorage.removeItem('jwtToken');
    setAuthToken(false);
    dispatch(setCurrentCompanyUser({}));
    window.location.href=AppConstants.STAGRFP+'/';
}

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import { Container, Row, Col } from 'reactstrap';
import AppConstants from '../AppConstants';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {logoutUser,getProfile,getVendorProfile,dashboard} from '../actions/authentication';
import nologo from './nologo.png';
import {ReactTitle} from 'react-meta-tags';
import PropTypes from 'prop-types';
class appliedrfpvendors extends React.Component {
    constructor(props) {
        console.log(props);
        super(props);
        this.state = {      
          applieddata: [],
          errors: {},
          errMsg:false,
          rfprfiname:''
      };
    }

    componentDidMount() {
      this.props.dashboard("dashboard");      
       // fetch(AppConstants.API+'/vendors/companyrfprfinaicrelatedvendors/'+this.props.auth.user._id).then(response => response.json())
         //.then(data => {
        fetch(AppConstants.API+'/rfprfiprofiles/applyvendors/'+this.props.match.params.rfprfi_id).then(response => response.json())
         .then(data => {
          console.log(data);
          if(data.Status == "Success")
          {
            this.setState({ applieddata: data.applieddata });
          }
          else
          {
            this.setState({ applieddata: []});
          }    
        });
        fetch(AppConstants.API+'/rfprfiprofiles/getrfpname/'+this.props.match.params.rfprfi_id).then(response => response.json())
         .then(data => {
          if(data.Status == "Success")
          {
            this.setState({ rfprfiname: data.rfprfiname });
          }
          else
          {
            this.setState({ rfprfiname: ''});
          }    
        }); 
    }

    renderShowsTotal(start, to, total) {
        return (
          <p className="page-show">
          Showing rows { start } to { to } of { total }
          </p>
        );
      }
      actionBtn(cell,row) {
        return  <div className="action-block"><Link to={`${AppConstants.STAGRFP}${"/viewvendordetails/"}${row.vendor_id}`} className="view-icon" title="View Vendor Details"><i class="fa fa-eye" aria-hidden="true"></i></Link>
        
        {/* <Link to={"/rfprfis"} className="btn btn-sm" title="Edit Rfp/Rfis"><i class="fa fa-eye" aria-hidden="true"></i>View</Link> */}
        </div>;        
    }
    logoInfo(cell,row) {
        return  <div>
          {row.vendor_company_name}
          {/* <div className="vendor-logo"><img src={row.companyLogo || nologo} alt={row.companyLogo || nologo} className="img-responsive"/></div> */}
          </div>; ;        
    }
    companyInfo(cell,row) {
        return  <div className="contact-info">
          {/* <b>Name: </b>{row.vendor_company_name} <br/>  */}
          <span className="location">{row.vendor_company_address}, <br />
          {row.state}, {row.city} {row.zipcode} </span>
          {(row.vendor_company_phoneno && row.vendor_company_phoneno !='') ? (<span className="phone-no"> {row.vendor_company_phoneno}</span>) : ''}
        {(row.vendor_company_website && row.vendor_company_website !='') ? ( <span className="website"><a href={row.vendor_company_website} target="_blank" >{row.vendor_company_website}</a></span>) : ''}
          </div> ;        
    }
    naicInfo(cell,row) {
      return  <div>
      {row.industry} <br />
          <b>NAICS Codes:</b><br/>                
              <span className="naiccodes">
                  <ul style={{listStyle:'none', padding:'0'}}>
                      {row.naiccodesdes.map(recipe => {
                              return (
                                  
                                  <li>{recipe}<span>,</span></li>
                              
                                  )
                              })
                          }                           
                  </ul>
                  {row.naiccodesdes == '' && <span>No Naics Codes</span>} 
              </span>
          </div> ;         
    } 

    
    
    
  render() {    
    
const options = {
  page: 1,  // which page you want to show as default
  sizePerPageList: [ {
    text: '10', value: 10
  }, {
    text: '25', value: 25
  }, {
    text: 'All', value: this.state.applieddata.length
  } ], // you can change the dropdown list for size per page
  sizePerPage: 10,  // which size per page you want to locate as default
  pageStartIndex: 1, // where to start counting the pages
  paginationSize: 5,  // the pagination bar size.
  prePage: '<', // Previous page button text
  nextPage: '>', // Next page button text
  firstPage: 'First', // First page button text
  lastPage: 'Last', // Last page button text
  paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
  paginationPosition: 'bottom'  // default is bottom, top and both is all available
 
};
if(localStorage.jwtToken != undefined){
    return (
        <div>
            <ReactTitle title="Vendors" />
            <div className="inner-banner">
            </div>
            <div className="inner-content">
                <Container>
                    {/* <h1>Vendors List</h1>*/}
                    
                  <div className="dashboard-rfp-list">
                      <div className="dashboard-rfp-head">
                      <h6>Opportunity Name : {this.state.rfprfiname}</h6>
                      </div>
                        <BootstrapTable data={this.state.applieddata} pagination={ true } options={ options } search={true} searchPlaceholder={'Search by Name or Industry or NAICS Codes'}>
                            
                            {/* <TableHeaderColumn dataField='_id' isKey={ true } dataSort={ true } hidden={true}>ID</TableHeaderColumn> */}

                            <TableHeaderColumn dataField='vendor_company_name' dataFormat={ this.logoInfo.bind(this) } isKey={ true } dataSort={ true } width={'20%'}>Name</TableHeaderColumn>
                            <TableHeaderColumn dataField='vendor_company_address' dataSort={ true } width={'25%'} dataFormat={ this.companyInfo.bind(this) }>Company Address </TableHeaderColumn>

                            <TableHeaderColumn dataField='state' dataSort={ true } hidden={true}>NAICS Codes</TableHeaderColumn> 

                            <TableHeaderColumn dataField='city' dataSort={ true } hidden={true}>NAICS Codes</TableHeaderColumn> 

                            <TableHeaderColumn dataField='zipcode' dataSort={ true } hidden={true}>NAICS Codes</TableHeaderColumn> 

                            <TableHeaderColumn dataField='naiccodesdes' dataSort={ true } hidden={true}>NAICS Codes</TableHeaderColumn>                            
                            <TableHeaderColumn dataField='industry' dataFormat={ this.naicInfo.bind(this) } dataSort={ true }>Industry Type / NAICS Codes</TableHeaderColumn>
                            {/* <TableHeaderColumn dataField='industry' dataSort={ true }>Industry Type</TableHeaderColumn> */}
                            <TableHeaderColumn dataField='Action' width={'10%'} dataFormat={ this.actionBtn.bind(this) }>Actions</TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                </Container>
            </div>
        </div>
        
        );
    }
    else
    {
      window.location.href = AppConstants.STAGRFP+'/';
    }
  }
}
appliedrfpvendors.propTypes = {
  dashboard:PropTypes.func.isRequired
};
const mapStateToProps = (state) => ({
    auth: state.auth
  })
  //export default Header;
  export default connect(mapStateToProps, {dashboard})(withRouter(appliedrfpvendors));
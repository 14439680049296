import React, { Component } from 'react';
import { MDBInput,MDBBtn } from 'mdbreact';
import {Row, Col } from 'reactstrap';
import SlidingPane from 'react-sliding-pane';
import axios from 'axios';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AppConstants from '../AppConstants';
import Login from '../pages/login';
import Register from '../pages/register';
import Loading from '../pages/loader.gif';

/* function LoadPane(props) {
    const isOpenPaneName = props.isOpenPaneName;
    if (isOpenPaneName == 'login') {
      //alert('login');
      return <Login />;
    }
  } */
  
class Forgotpassword extends React.Component  {
    constructor(props) {
        super(props);
        this.state = {
            isPaneOpen: false,
            isPaneOpenLeft: false,
            paneName:'login',
            fields: {},
            errors: {},
            errMsg:'',
            successMsg:''
        };
        this.password=this.password.bind(this)
        this.regCallback=this.regCallback.bind(this)
        this.loginCallback=this.loginCallback.bind(this)
    }

    regCallback = (dataFromChild) => {
      //console.log(dataFromChild);
      this.setState({paneName : 'login'});
      }
      loginCallback(e){
      //console.log(e)
      this.setState({paneName : 'register'});
      }
      password(e){
      this.setState({isPaneOpen:true})
      //console.log(e);
      this.props.callbackFromParent3(this.state);
      }

    handleChange(field, e){    		
        let fields = this.state.fields;
        fields[field] = e.target.value;        
        this.setState({fields});
    }
   /** Validations */
  handleValidation(){
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if(this.actionInput.value == 'company'){
      //console.log(this.actionInput.value);
      //Email
      if(!fields["company_email"]){
        formIsValid = false;
        errors["company_email"] = "Please enter email";
      }
      if(typeof fields["company_email"] !== "undefined"){
        let lastAtPos = fields["company_email"].lastIndexOf('@');
        let lastDotPos = fields["company_email"].lastIndexOf('.');

        if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["company_email"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["company_email"].length - lastDotPos) > 2)) {
          formIsValid = false;
          errors["company_email"] = "Please enter valid email1";
        }
      }      
    }else{
      //Email
      if(!fields["vendor_company_email"]){
        formIsValid = false;
        errors["vendor_company_email"] = "Please enter email";
      }
      if(typeof fields["vendor_company_email"] !== "undefined"){
        let lastAtPos = fields["vendor_company_email"].lastIndexOf('@');
        let lastDotPos = fields["vendor_company_email"].lastIndexOf('.');

        if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["vendor_company_email"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["vendor_company_email"].length - lastDotPos) > 2)) {
          formIsValid = false;
          errors["vendor_company_email"] = "Please enter valid email";
        }
      }     
    }
    this.setState({errors: errors});
    return formIsValid;
  }

  handleSubmit = event => {
    if(this.actionInput.value == 'company'){
      event.preventDefault();
      if(this.handleValidation()){
        this.setState({loading:1});
        const companyforgotpwd = {
          company_email: this.state.fields['company_email'],
          type: 'company'
        }
        axios.post(AppConstants.API+'/cutomers/customerforgotpassword',companyforgotpwd)
        .then(res => {   
          
          if(res.data.Status == "Success"){
            this.setState({loading:''});
            setTimeout(() => {
              this.setState({
                successMsg: false,
              });
              }, 2000);  
            setTimeout((e) => {  
              //window.location.href='/';
              window.location.href = AppConstants.STAGRFP+'/';
              //this.props.history.push('/');
            },2000);
            this.setState({
              successMsg: res.data.msg
            });
          }
          else{
            this.setState({loading:''});
            this.setState({
              errMsg: res.data.msg
            });
            setTimeout(() => {
              this.setState({
                errMsg: '',
              });
              }, 2000);  
          } 
        });       
      }
      //let fields={}
      //fields["company_email"]='';
      //this.setState({fields:fields});
    }else{
      event.preventDefault();
      if(this.handleValidation()){
        this.setState({loading:1});
        const vendorforgotpwd = {
          vendor_company_email: this.state.fields['vendor_company_email'],
          type: 'vendor'
        }
        axios.post(AppConstants.API+'/vendors/vendorforgotpassword',vendorforgotpwd)
        .then(res => {   
          if(res.data.Status == "Success"){
            this.setState({loading:''});
            setTimeout(() => {
              this.setState({
                successMsg: false,
              });
              }, 2000); 
            setTimeout((e) => {
              //window.location.href='/';  
              window.location.href = AppConstants.STAGRFP+'/';         
              //this.props.history.push('/');
            }, 2000);
            this.setState({
              successMsg: res.data.msg
            });
          }
          else{
            this.setState({loading:''});
            this.setState({
              errMsg: res.data.msg
            });
            setTimeout(() => {
              this.setState({
                errMsg: '',
              });
              }, 2000); 
          } 
        });        
      }
      //let fields={}
      //fields["vendor_company_email"]='';
      //this.setState({fields:fields});
    }
  };
  componentDidMount() {   
      
   };
   

    render() {
      return (        
          <div className="login-block">
              <h3 className="color-yellow">Forgot Password</h3>
              <p>Please enter your email to reset password</p>
              <div className="form-block">
              <Tabs>                
                  <TabList className="row">
                      <Tab className="col-md-6"><em><span className="vendor"></span>Vendor</em></Tab>
                      <Tab className="col-md-6"><em><span className="company"></span>Company</em></Tab>
                     
                  </TabList>
                  <TabPanel>
                      <form  onSubmit= {this.handleSubmit.bind(this)}>
                      <span className="error-inva" style={{color: 'green', position: 'relative',    top: '-10px'}}>{this.state.successMsg}</span>
                      <span className="error-inva" style={{color: 'red', position: 'relative',    top: '-10px'}}>{this.state.errMsg}</span>
                      <Row>
                        <div className="form-field col-md-12">
                        <input type="hidden" name="type" value="vendor" 
                                  ref={(input) => { this.actionInput = input }} />
                            <MDBInput label="Email *"  type="text" name="vendor_company_email" onChange={this.handleChange.bind(this, "vendor_company_email")} />
                            <span className="error" style={{color: 'red'}}>{this.state.errors["vendor_company_email"]}</span>
                        </div>                       
                        <div className="form-field col-md-12">
                            <MDBBtn type="submit"  title="Send mail">Submit<div style={this.state.loading ? {} : { display: 'none' }} class="image-fill w-25 loader-login-div "><img src={Loading} alt="No Image" className="can-click " /></div></MDBBtn>
                        </div>
                        </Row>
                      </form>
                  </TabPanel>   
                  <TabPanel>
                      <form  onSubmit= {this.handleSubmit.bind(this)}>
                      <span className="error-inva" style={{color: 'green', position: 'relative',    top: '-10px'}}>{this.state.successMsg}</span>
                      <span className="error-inva" style={{color: 'red', position: 'relative',    top: '-10px'}}>{this.state.errMsg}</span>
                      <Row>
                        <div className="form-field col-md-12">                           
                            <input type="hidden" name="type" value="company" 
                                  ref={(input) => { this.actionInput = input }} />
                            <MDBInput label="Email *" type="text" name="company_email" onChange={this.handleChange.bind(this, "company_email")} />
                            <span className="error" style={{color: 'red'}}>{this.state.errors["company_email"]}</span>
                        </div>                        
                        <div className="form-field col-md-12">
                            <MDBBtn type="submit" title="Send mail">Submit<div style={this.state.loading ? {} : { display: 'none' }} class="image-fill w-25 loader-login-div "><img src={Loading} alt="No Image" className="can-click " /></div></MDBBtn>
                        </div>
                        </Row>
                      </form>
                  </TabPanel>
                                
              </Tabs>                
                {/* <div className="forgot-block text-center col-md-12 mar-top">Do you have an account <a onClick={() => this.setState({ isPaneOpen: true,paneName:'login' })} className="und">Login</a></div> */}
                <div className="forgot-block text-center col-md-12 mar-top">Do you have an account? <a onClick={this.password} >Login</a></div>
              </div>              
              <SlidingPane
                    className='some-custom-class'
                    overlayClassName='some-custom-overlay-class'
                    isOpen={ this.state.isPaneOpen }
                    onRequestClose={ () => {
                        // triggered on "<" on left top click or on outside click
                        this.setState({ isPaneOpen: false });
                    } }>
                    {/* <LoadPane isOpenPaneName={this.state.paneName} /> */}
                    {this.state.paneName == 'login' ? <Login callbackFromParent1={this.loginCallback} /> : <Register callbackFromParent={this.regCallback} />}
                    </SlidingPane>
          </div>
      );
    }
  }

  Forgotpassword.propTypes = {
    //loginCompany: PropTypes.func.isRequired,
    //loginVendor: PropTypes.func.isRequired,
    //auth: PropTypes.object.isRequired,
    //errors: PropTypes.object.isRequired
  };
  const mapStateToProps = (state) => ({
    //auth: state.auth,
    //errors: state.errors
  });
  export  default connect(mapStateToProps, {})(Forgotpassword);
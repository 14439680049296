import React, { Component } from 'react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import { Container, Row, Col,Button } from 'reactstrap';

export default class rfplist extends React.Component {

  renderShowsTotal(start, to, total) {
    return (
      <p className="page-show">
      Showing rows { start } to { to } of { total }
      </p>
    );
  }
  actionBtn(cell,row) {
    return  <a href="" onClick={this.Action} className="btn btn-sm">
                <i class="fa fa-eye" aria-hidden="true"></i>View
            </a>;
}
  render() {
    var rfp_list = [{
            name: "Mercy Basilisk",
            naic: "Dry Pea and Bean Farming - 111130",
            post_date: "01-07-19",
            due_date: "08-03-19   11:59 PM",
            Action:'https://outlook.office.com/owa/',
            },
            {
            name: "Wolverine",
            naic: "Wheat Farming - 111140",
            post_date: "01-03-19",
            due_date: "11-05-19	   11:59 PM",
            Action:'test',
        },{
            name: "Top Slugger",
            naic: "Corn Farming - 111150",
            post_date: "01-02-19",
            due_date: "04-03-19   11:59 PM",
            Action:'test',
        },{
            name: "Forklift",
            naic: "Oilseed and Grain Combination Farming - 111191",
            post_date: "01-02-19",
            due_date: "06-04-19   11:59 PM",
            Action:'test',
        },{
            name: "Snowmobile",
            naic: "Rice Farming - 111160",
            post_date: "01-02-19",
            due_date: "04-07-19   11:59 PM",
            Action:'test',
        },{
            name: "Mercy Basilisk",
            naic: "Dry Pea and Bean Farming - 111130",
            post_date: "01-07-19",
            due_date: "08-03-19   11:59 PM",
            Action:'test',
        },{
            name: "Wolverine",
            naic: "Wheat Farming - 111140",
            post_date: "01-03-19",
            due_date: "11-05-19	   11:59 PM",
            Action:'test',
        },{
            name: "Top Slugger",
            naic: "Corn Farming - 111150",
            post_date: "01-02-19",
            due_date: "04-03-19   11:59 PM",
            Action:'test',
        },{
            name: "Forklift",
            naic: "Oilseed and Grain Combination Farming - 111191",
            post_date: "01-02-19",
            due_date: "06-04-19   11:59 PM",
            Action:'test',
        },{
            name: "Snowmobile",
            naic: "Rice Farming - 111160",
            post_date: "01-02-19",
            due_date: "04-07-19   11:59 PM",
            Action:'test',
        },{
            name: "Snowmobile",
            naic: "Rice Farming - 111160",
            post_date: "01-02-19",
            due_date: "04-07-19   11:59 PM",
            Action:'test',
        },{
            name: "Snowmobile",
            naic: "Rice Farming - 111160",
            post_date: "01-02-19",
            due_date: "04-07-19   11:59 PM",
            Action:'test',
        },{
            name: "Snowmobile",
            naic: "Rice Farming - 111160",
            post_date: "01-02-19",
            due_date: "04-07-19   11:59 PM",
            Action:'test',
        },{
            name: "Snowmobile",
            naic: "Rice Farming - 111160",
            post_date: "01-02-19",
            due_date: "04-07-19   11:59 PM",
            Action:'test',
        },{
            name: "Snowmobile",
            naic: "Rice Farming - 111160",
            post_date: "01-02-19",
            due_date: "04-07-19   11:59 PM",
            Action:'test',
        },{
            name: "Snowmobile",
            naic: "Rice Farming - 111160",
            post_date: "01-02-19",
            due_date: "04-07-19   11:59 PM",
            Action:'test',
        },{
            name: "Snowmobile",
            naic: "Rice Farming - 111160",
            post_date: "01-02-19",
            due_date: "04-07-19   11:59 PM",
            Action:'test',
        },{
            name: "Snowmobile",
            naic: "Rice Farming - 111160",
            post_date: "01-02-19",
            due_date: "04-07-19   11:59 PM",
            Action:'test',
        },{
            name: "Snowmobile",
            naic: "Rice Farming - 111160",
            post_date: "01-02-19",
            due_date: "04-07-19   11:59 PM",
            Action:'test',
        },{
            name: "Snowmobile",
            naic: "Rice Farming - 111160",
            post_date: "01-02-19",
            due_date: "04-07-19   11:59 PM",
            Action:'test',
        },{
            name: "Snowmobile",
            naic: "Rice Farming - 111160",
            post_date: "01-02-19",
            due_date: "04-07-19   11:59 PM",
            Action:'test',
        },];
    const options = {
      page: 1,  // which page you want to show as default
      sizePerPageList: [ {
        text: '10', value: 10
      }, {
        text: '25', value: 25
      }, {
        text: 'All', value: rfp_list.length
      } ], // you can change the dropdown list for size per page
      sizePerPage: 10,  // which size per page you want to locate as default
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 5,  // the pagination bar size.
      prePage: '<', // Previous page button text
      nextPage: '>', // Next page button text
      firstPage: 'First', // First page button text
      lastPage: 'Last', // Last page button text
      paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
      paginationPosition: 'bottom'  // default is bottom, top and both is all available
     
    };
    
    return (
        <div>
            <div className="inner-banner">
            </div>
            <div className="inner-content">

                <Container>
                    <h1>All RFPs/RFIs List</h1>
                    <BootstrapTable data={ rfp_list } pagination={ true } options={ options } search>
                        <TableHeaderColumn dataField='name' isKey={ true } dataSort={ true }>Name of RFPs/RFIs</TableHeaderColumn>
                        <TableHeaderColumn dataField='naic' dataSort={ true }>NAIC Code </TableHeaderColumn>
                        <TableHeaderColumn dataField='post_date' dataSort={ true }>Post Date</TableHeaderColumn>
                        <TableHeaderColumn dataField='due_date' dataSort={ true }>Due Date & Time</TableHeaderColumn>
                        <TableHeaderColumn dataField='Action' dataFormat={ this.actionBtn.bind(this) }>Actions</TableHeaderColumn>
                    </BootstrapTable>
                </Container>
            </div>
        </div>
    );
  }
}
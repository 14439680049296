import React, { Component } from 'react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import { Container, Row, Col } from 'reactstrap';
import AppConstants from '../AppConstants';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import {logoutUser,getProfile,dashboard} from '../actions/authentication';
import {ReactTitle} from 'react-meta-tags';
import PropTypes from 'prop-types';
//export default class  dashboard extends Component{
class vendorrfpfeedbacks extends React.Component {
    constructor(props) {
        console.log(props);
        super(props);
        this.state = {      
            rfprfifeedback: [],
            errors: {},
            errMsg:false
      };
    }

    componentDidMount() {  
      this.props.dashboard("dashboard");     
      window.scrollTo(0, 0);
        fetch(AppConstants.API+'/reports/vendorrfpfeedbacks/'+this.props.auth.user._id).then(response => response.json())
         .then(data => {
          if(data.Status == "Success")
          {
            this.setState({ rfprfifeedback: data.rfprfifeedback });
          }
          else
          {
            this.setState({ rfprfifeedback: []});
          }    
        });       
        
    }

    renderShowsTotal(start, to, total) {
        return (
          <p className="page-show">
          Showing rows { start } to { to } of { total }
          </p>
        );
      }
    actionBtn(cell,row) {       
      return  <div className="action-block"><Link to={`${AppConstants.STAGRFP}${"/viewrfpinfo/"}${row.rfprfi_id}`} className="view-icon" title="View Opportunity Details"><i class="fa fa-eye" aria-hidden="true"></i></Link></div>; 
    }

    naicInfo(cell,row) {
      return  <div>
                <span className="naiccodes">
                    <ul style={{listStyle:'none', padding:'0'}}>
                        {row.naiccodesdes.map(recipe => {
                                return (
                                    <li>{recipe}<span>,</span></li>
                                    )
                                })
                            }                           
                    </ul>
                    {row.naiccodes == '' && <span>No NAICS Codes</span>} 
                </span>
            </div> ;        
      }
    
    
  render() {
const options = {
  page: 1,  // which page you want to show as default
  sizePerPageList: [ {
    text: '10', value: 10
  }, {
    text: '25', value: 25
  }, {
    text: 'All', value: this.state.rfprfifeedback.length
  } ], // you can change the dropdown list for size per page
  sizePerPage: 10,  // which size per page you want to locate as default
  pageStartIndex: 1, // where to start counting the pages
  paginationSize: 5,  // the pagination bar size.
  prePage: '<', // Previous page button text
  nextPage: '>', // Next page button text
  firstPage: 'First', // First page button text
  lastPage: 'Last', // Last page button text
  paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
  paginationPosition: 'bottom'  // default is bottom, top and both is all available
 
};
if(localStorage.jwtToken != undefined && this.props.auth.user.type =='company'){
    return (
        <div>
            <ReactTitle title="Opportunity Vendors Feedback" />
            <div className="inner-banner">
            </div>
            <div className="inner-content">
                <Container>
                    <div className="dashboard-rfp-list">
                        <div className="dashboard-rfp-head">
                            <h6>Opportunity Feedback</h6>
                        </div>
                        <BootstrapTable data={this.state.rfprfifeedback} pagination={ true } options={ options } search={true} searchPlaceholder={'Search by Opportunity Name or NAICS Code'}>
                            
                            {/* <TableHeaderColumn dataField='_id' isKey={ true } dataSort={ true } hidden={true}>ID</TableHeaderColumn> */}

                            <TableHeaderColumn dataField='name_ofrfp' isKey={ true } dataSort={ true }>Name Of Opportunity</TableHeaderColumn>
                            <TableHeaderColumn dataField='naiccodesdes' dataFormat={ this.naicInfo.bind(this) } dataSort={ true }>NAICS Code</TableHeaderColumn>                            
                            <TableHeaderColumn dataField='vendorname' dataSort={ true }>Vendor Name</TableHeaderColumn>
                            <TableHeaderColumn dataField='feedback' dataSort={ true }>Feedback</TableHeaderColumn>
                            <TableHeaderColumn dataField='Action' dataFormat={ this.actionBtn.bind(this) }>Actions</TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                </Container>
            </div>
        </div>
        
        );
    }
    else
    {
        window.location.href = AppConstants.STAGRFP+'/';
    }
  }
}
vendorrfpfeedbacks.propTypes = {
  dashboard:PropTypes.func.isRequired
};
const mapStateToProps = (state) => ({
    auth: state.auth
  })
  //export default Header;
  export default connect(mapStateToProps, {logoutUser,getProfile,dashboard})(withRouter(vendorrfpfeedbacks));

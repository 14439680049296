import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { MDBInput,MDBBtn } from 'mdbreact';
import {Row, Col } from 'reactstrap';
import axios from 'axios';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import Login from '../pages/login';
import AppConstants from '../AppConstants';
import NumberFormat from 'react-number-format';
import Customerotp from '../pages/customerotp';
import Vendorotp from '../pages/vendorotp';
import { connect } from 'react-redux';
import {Typeahead} from 'react-bootstrap-typeahead';
import AvatarUploader from 'react-avatar-uploader';
import Loading from '../pages/loader.gif';

let companyid = '';
let vendorid = '';
function LoadPane(props) {
    const isOpenPaneName = props.isOpenPaneName;
    console.log(isOpenPaneName);
    if (isOpenPaneName == 'login') {
      //alert('login');
      return <Login />;
    }
    if(isOpenPaneName == 'customerotp'){
        return <Customerotp company_id={companyid}/>;
    }
    if(isOpenPaneName == 'vendorotp'){
        return <Vendorotp vendor_id={vendorid}/>;
    }
   
  }
  class Register extends React.Component  {
//export default class  Register extends Component  {
    constructor(props) {
        super(props);
        this.state = {
            isPaneOpen: false,
            isPaneOpenLeft: false,
            paneName:'login',
            "company_name" : '',
            "company_logo": '',
            "company_email" : '',
            "company_type": '',
            "company_industrey": '',
            "company_address": '',
            "company_phoneno": '',
            "naic_code": [],
            "created_by": null,
            "country": '',
            "state": '',
            "city": '',
            "zipcode":'',
            'status':'Active',            
            companiesdata:[],
            industriesdata:[],
            naiccodes:[],
            countries: [],
            states: [],
            cities: [],
            errors: {},
            errMsg:'',
            successMsg:'',
            "vendor_company_name" : '',
            "vendor_company_email" : '',
            "vendor_company_type": '',
            "vendor_company_industrey": '',
            "vendor_company_email" : '',
            "vendor_company_address": '',
            "vendor_company_phoneno": '',
            "vendor_zipcode":'',
            "vendor_country":'',
            "vendor_state":'',
            "vendor_city":'',
            "accolades": '',
            "media": '',
            "multiplevalues": [],
            "multipleaccolades": [],
            "companyid":'',
            "vendorid":null,
            "vendor_naic_code": [],
            "certifications":[],
            "membership": '',
            "multiplemembership":[],
            "loading": '',
            "company_business_model":'',
            "vendor_business_model":''
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
       // this.handleCountryChange = this.handleCountryChange.bind(this);
       // this.handleStateChange = this.handleStateChange.bind(this);
        //this.handleCountryChange1 = this.handleCountryChange1.bind(this);
        //this.handleStateChange1 = this.handleStateChange1.bind(this);
        //this.handleMultiChange = this.handleMultiChange.bind(this);
        this.handleInputFileChange=this.handleInputFileChange.bind(this);
        this.onChangeNumberkey = this.onChangeNumberkey.bind(this);
        this.loginClicked = this.loginClicked.bind(this);

        this.handleCompanyModelChange = this.handleCompanyModelChange.bind(this);
        this.handleVendorModelChange = this.handleVendorModelChange.bind(this);
        
    }
    handleCompanyModelChange(event) {
        //console.log(event.target.value);
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleVendorModelChange(event) {
        //console.log(event.target.value);
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handlePhoneChange(e) {
        const re1 =/^[0-9)\(+ '-]+$/g;
        // var phone_num = e.target.value.replace(/\D/g,'');
         if (e.target.value === '' || re1.test(e.target.value)) {
            // phone_num = this.phoneFormat(phone_num)
             this.setState({
                 [e.target.name]: e.target.value
             });
         }
    }
    phoneFormat(input){
        // Strip all characters from the input except digits
        // Trim the remaining input to ten characters, to preserve phone number format
        input = input.substring(0,10);
        // Based upon the length of the string, we add formatting as necessary
        var size = input.length;
        if(size == 0){
                input = input;
        }else if(size < 4){
                input = '('+input;
        }else if(size < 7){
                input = '('+input.substring(0,3)+') '+input.substring(3,6);
        }else{
                input = '('+input.substring(0,3)+') '+input.substring(3,6)+'-'+input.substring(6,10);
        }
        return input; 
    }

    loginClicked(p) {
        //console.log(p)
        this.props.callbackFromParent(this.state);
    } 
    handleInputChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    handleKeyUp(e) {
        console.log(this.state.value);
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    onChangeNumberkey(e){
		const zip = this.state
		const re = /^[0-9)\(+ '-]+$/g;
		if (e.target.value === '' || re.test(e.target.value)) {
      zip[e.target.name] = e.target.value;
		this.setState({zip})
        }
    }
    handleInputFileChange(e) {
        //console.log('hii');
        this.setState({
        [e.target.name]: e.target.files[0]
        });
      }
    
    

      
    /** Validations */
  handleValidation(){
   // console.log(this.state.naic_code.length);
    let errors = {};
    let formIsValid = true;
    if(this.actionInput.value == 'company'){
        /* if(!this.state.company_logo){
            formIsValid = false;
            errors["company_logo"] = "Please upload logo";
          } */        
        
        if(!this.state.company_name){
            formIsValid = false;
            errors["company_name"] = "Please enter company name";
          }
        if(!this.state.company_email){
            formIsValid = false;
            errors["company_email"] = "Please enter email";
        }
        if(typeof this.state.company_email !== "undefined"){
            let lastAtPos = this.state.company_email.lastIndexOf('@');
            let lastDotPos = this.state.company_email.lastIndexOf('.');
      
            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && this.state.company_email.indexOf('@@') == -1 && lastDotPos > 2 && (this.state.company_email.length - lastDotPos) > 2)) {
              formIsValid = false;
              errors["company_email"] = "Please enter valid email";
            }
          }
          if(!this.state.company_type){
            formIsValid = false;
            errors["company_type"] = "Please select company type";
          }
          if(!this.state.company_industrey){
            formIsValid = false;
            errors["company_industrey"] = "Please select industry type";
          }
          if(!this.state.company_address){
            formIsValid = false;
            errors["company_address"] = "Please enter address";
          }
        //   if(!this.state.company_phoneno){
        //     formIsValid = false;
        //     errors["company_phoneno"] = "Please enter phone number";
        //   }
        //   else if(this.state.company_phoneno.length !=14){
        //     formIsValid = false;
        //     errors["company_phoneno"] = "Please enter 10 digits  phone number";
        //   }
          if(!this.state.zipcode){
            formIsValid = false;
            errors["zipcode"] = "Please enter zipcode";
          }else if(this.state.zipcode.length  > 15){
            formIsValid = false;
            errors["zipcode"] = "Please enter valid zipcode";
          }
          /* if(!this.state.country){
            formIsValid = false;
            errors["country"] = "Please select country";
          } */
          if(!this.state.state){
            formIsValid = false;
            errors["state"] = "Please enter state";
          }  
          if(!this.state.city){
            formIsValid = false;
            errors["city"] = "Please enter city";
          }
          if(this.state.naic_code.length ==0){
            formIsValid = false;
            errors["naic_code"] = "Please select naics codes";
          }
        //   else if(this.state.naic_code.length > 7){
        //     formIsValid = false;
        //     errors["naic_code"] = "Please select max 7 naics codes";
        //   }
          if(!this.state.company_business_model){
            formIsValid = false;
            errors["company_business_model"] = "Please select business model";
          }
    }
    else
    {
        //console.log(this.state.vendor_naic_code.length);
        if(!this.state.vendor_company_name){
            formIsValid = false;
            errors["vendor_company_name"] = "Please enter company name";
        }
        if(!this.state.vendor_company_email){
            formIsValid = false;
            errors["vendor_company_email"] = "Please enter email";
        }
        if(typeof this.state.vendor_company_email !== "undefined"){
            let lastAtPos = this.state.vendor_company_email.lastIndexOf('@');
            let lastDotPos = this.state.vendor_company_email.lastIndexOf('.');
      
            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && this.state.vendor_company_email.indexOf('@@') == -1 && lastDotPos > 2 && (this.state.vendor_company_email.length - lastDotPos) > 2)) {
              formIsValid = false;
              errors["vendor_company_email"] = "Please enter valid email";
            }
        }
        if(!this.state.vendor_company_type){
            formIsValid = false;
            errors["vendor_company_type"] = "Please select company type";
        }
        if(!this.state.vendor_company_industrey){
            formIsValid = false;
            errors["vendor_company_industrey"] = "Please select industry type";
        }
        if(!this.state.vendor_company_address){
            formIsValid = false;
            errors["vendor_company_address"] = "Please enter address";
        }
        // if(!this.state.vendor_company_phoneno){
        //     formIsValid = false;
        //     errors["vendor_company_phoneno"] = "Please enter phone number";
        // }
        // else if(this.state.vendor_company_phoneno.length !=14){
        //     formIsValid = false;
        //     errors["vendor_company_phoneno"] = "Please enter 10 digits phone number";
        // }
        if(!this.state.vendor_zipcode){
            formIsValid = false;
            errors["vendor_zipcode"] = "Please enter zipcode";
        }else if(this.state.vendor_zipcode.length > 15){
            formIsValid = false;
            errors["vendor_zipcode"] = "Please enter valid zipcode";
        }
        /* if(!this.state.vendor_country){
            formIsValid = false;
            errors["vendor_country"] = "Please select country";
        } */
        if(!this.state.vendor_state){
            formIsValid = false;
            errors["vendor_state"] = "Please enter state";
        }  
        if(!this.state.vendor_city){
            formIsValid = false;
            errors["vendor_city"] = "Please enter city";
        }
        if(this.state.vendor_naic_code.length ==0){
            formIsValid = false;
            errors["vendor_naic_code"] = "Please select naics codes";
        }
        else if(this.state.vendor_naic_code.length > 7){
            formIsValid = false;
            errors["vendor_naic_code"] = "Please select max 7 naics codes";
          }
        if(!this.state.vendor_business_model){
            formIsValid = false;
            errors["vendor_business_model"] = "Please select business model";
          }
        
    }
    this.setState({errors: errors});
    return formIsValid;
  }
  
  handleSubmit = event => {
    event.preventDefault(); 
    if(this.actionInput.value == 'company'){
        if(this.handleValidation()){
            this.setState({loading:1});
            let formData = new FormData();
                formData.append('company_name', this.state.company_name);
                //formData.append('company_logo', this.state.company_logo);
                formData.append('company_email', this.state.company_email);
                formData.append('company_type', this.state.company_type);
                formData.append('company_industrey',this.state.company_industrey);
                formData.append('company_phoneno',this.state.company_phoneno);
                formData.append('company_address',this.state.company_address);
                formData.append('zipcode',this.state.zipcode);
                formData.append('country',this.state.country);
                formData.append('state',this.state.state);;
                formData.append('city',this.state.city);
                formData.append('status', 'Active');
                formData.append('deleted', 0);
                formData.append('naic_code',JSON.stringify(this.state.naic_code));
                formData.append('request_from', "web");
                formData.append('company_business_model',this.state.company_business_model);
                axios.post(AppConstants.API+'/cutomers/addcustomer',formData)
                .then(res => {   
                //console.log(res);   
                if(res.data.Status == "Success"){
                    this.setState({loading:''});
                    this.setState({ isPaneOpen: false});
                    //this.props.callbackFromParent(this.state);
                    companyid = res.data.customer_id;
                    setTimeout((e) => {
                        this.setState({ isPaneOpen: true,paneName:'customerotp'});
                    }, 2000)          
                this.setState({
                    successMsg: res.data.msg
                });
                }
                else{
                setTimeout(() => {
                    this.setState({
                        errMsg: false,
                    });
                    }, 2000); 
                this.setState({loading:''});
                this.setState({
                    errMsg: res.data.msg,
                    'company_name':'',
                    'company_email':'',
                    'company_type':'',
                    'company_industrey':'',
                    'company_phoneno':'',
                    'company_address':'',
                    'zipcode':'',
                    'country':'',
                    'state':'',
                    'city':'',
                    'status':'',
                    'naic_code':'',
                    'request_from':'',
                    'naic_code':[],
                    'company_business_model':''
                });
                } 
            });

            // this.setState({
            //     'company_name':'',
            //     'company_email':'',
            //     'company_type':'',
            //     'company_industrey':'',
            //     'company_phoneno':'',
            //     'company_address':'',
            //     'zipcode':'',
            //     'country':'',
            //     'state':'',
            //     'city':'',
            //     'status':'',
            //     'naic_code':'',
            //     'request_from':''
            // })
        }
    }else    
    {
        if(this.handleValidation()){
            let formData = new FormData();
                formData.append('vendorid', this.state.vendorid);
                formData.append('vendor_company_name', this.state.vendor_company_name);
                //formData.append('company_logo', this.state.company_logo);
                formData.append('vendor_company_email', this.state.vendor_company_email);
                formData.append('vendor_company_type', this.state.vendor_company_type);
                formData.append('vendor_company_industrey',this.state.vendor_company_industrey);
                formData.append('vendor_company_phoneno',this.state.vendor_company_phoneno);
                formData.append('vendor_company_address',this.state.vendor_company_address);
                formData.append('zipcode',this.state.vendor_zipcode);
                formData.append('country',this.state.vendor_country);
                formData.append('state',this.state.vendor_state);;
                formData.append('city',this.state.vendor_city);
                formData.append('status', 'Active');
                formData.append('deleted', 0);
                //formData.append('naic_code',JSON.stringify(this.state.naic_code));
                formData.append('naic_code',JSON.stringify(this.state.vendor_naic_code));
                formData.append('accolades', this.state.accolades);
                formData.append('multipleaccolades', JSON.stringify(this.state.multipleaccolades));
                formData.append('media', this.state.media);
                formData.append('multipleValues', JSON.stringify(this.state.multiplevalues));
                formData.append('request_from', "web");
                formData.append('certifications', JSON.stringify(this.state.certifications));
                formData.append('membership', this.state.membership);
                formData.append('vendor_business_model', this.state.vendor_business_model); 
                formData.append('multiplemembership', JSON.stringify(this.state.multiplemembership));
                axios.post(AppConstants.API+'/vendors/addvendor',formData)
                .then(res => {   
               // console.log(res);   
                if(res.data.Status == "Success"){
                    this.setState({loading:''});
                    vendorid = res.data.vendor_id;
                    setTimeout((e) => {
                        this.setState({ isPaneOpen: true,paneName:'vendorotp'});
                    }, 2000)          
                    this.setState({
                        successMsg: res.data.msg
                    });
                }
                else{
                    setTimeout(() => {
                        this.setState({
                            errMsg: false,
                        });
                    }, 2000); 
                    this.setState({loading:''});
                    this.setState({
                        errMsg: res.data.msg,
                        'vendor_company_name':'',
                        'vendor_company_email':'',
                        'vendor_company_type':'',
                        'vendor_company_industrey':'',
                        'vendor_company_phoneno':'',
                        'vendor_company_address':'',
                        'vendor_zipcode':'',
                        'country':'',
                        'vendor_state':'',
                        'vendor_city':'',
                        'status':'',                
                        'naic_code':'',               
                        'accolades':'',
                        'multipleaccolades':'',
                        'certifications':'',
                        'vendor_naic_code':[],
                        'vendor_business_model':''
                    });
                } 
            });

            // this.setState({
            //     'vendor_company_name':'',
            //     'vendor_company_email':'',
            //     'vendor_company_type':'',
            //     'vendor_company_industrey':'',
            //     'vendor_company_phoneno':'',
            //     'vendor_company_address':'',
            //     'vendor_zipcode':'',
            //     'country':'',
            //     'vendor_state':'',
            //     'vendor_city':'',
            //     'status':'',                
            //     'naic_code':'',               
            //     'accolades':'',
            //     'multipleaccolades':'',
            //     'certifications':''
            // })
        }
    }
  }

  componentDidMount()
  {
      //Get Industries data
    fetch(AppConstants.API+'/industries/getallindustries').then(response => response.json())
    .then(data => {
   // console.log(data);
    if(data.Status == "Success")
    {
      this.setState({ industriesdata: data.industriesdata });
    }
    else
    {
      this.setState({ industriesdata: "" });
    }    
  });
  //Get companies data
  fetch(AppConstants.API+'/companies/getallcompanies').then(response => response.json())
    .then(data => {
    //console.log(" companies ", data);
    if(data.Status == "Success")
    {
      this.setState({ companiesdata: data.companiesdata });
    }
    else
    {
      this.setState({ companiesdata: "" });
    }    
  });

  //Get naic codes
  fetch(AppConstants.API+'/users/getallnaiccodes').then(response => response.json())
    .then(data => {
   //console.log(" naic codes ", data);
    if(data.Status == "Success")
    {
      this.setState({ naiccodes: data.naiccodes });
    }
    else
    {
      this.setState({ naiccodes: "" });
    }    
  });

  
  }
  
  
    render() {
      return (
          <div className="login-block">
              <h3 className="color-yellow">Register</h3>
              <Tabs>
                    <TabList className="row">
                    <Tab className="col-md-6"><em><span className="vendor"></span>Vendor</em></Tab>
                        <Tab className="col-md-6"><em><span className="company"></span>Company</em></Tab>
                        
                    </TabList>
                    
                    <TabPanel>
                    <form name="vendorregistration"  onSubmit= { this.handleSubmit }>
                        <span className="error" style={{color: 'green'}}>{this.state.successMsg}</span>
                        <span className="error" style={{color: 'red'}}>{this.state.errMsg}</span>
                           {/*  <div className="avatar-block">
                                <AvatarUploader
                                size={150}
                                uploadURL="../images/"
                                fileType={"image/png"}/>
                                <span>Upload Logo</span>
                            </div> */}
                            <div className="form-block">
                            <input type="hidden" name="type" value="vendor" ref={(input) => { this.actionInput = input }} />
                                <Row>
                                    <div className="form-field col-md-12">
                                        <MDBInput label='Company Name *' type="text" name="vendor_company_name" onChange={ this.handleInputChange } />
                                        <span className="error" style={{color: 'red'}}>{this.state.errors["vendor_company_name"]}</span>
                                    </div>
                                </Row>
                                <Row>
                                    <div className="form-field col-md-6">   
                                    <div className="md-form">    
                                        <select name="vendor_company_type" class="cs-select cs-skin-elastic form-control" onChange={ this.handleInputChange }>
                                            <option value="">Company Type *</option>
                                            {
                                                this.state.companiesdata.map((companies,index)=>{
                                                    if(companies.status == "Active"){
                                                    return <option key={index} value={companies._id}>{companies.companyname}</option> 
                                                    }                      
                                                })
                                            }
                                        </select>
                                        {this.state.vendor_company_type ? <label className="active">Company Type *</label> : <label></label>}
                                        </div>
                                        <span className="error" style={{color: 'red'}}>{this.state.errors["vendor_company_type"]}</span>
                                    </div>
                                    <div className="form-field col-md-6">
                                    <div className="md-form">
                                        <select name="vendor_company_industrey" class="cs-select cs-skin-elastic form-control"  onChange={ this.handleInputChange }>
                                            <option value="">Industry Type *</option>
                                            {
                                            this.state.industriesdata.map((industries,index)=>{
                                                if(industries.status == "Active"){
                                                return <option key={index} value={industries._id}>{industries.industryname}</option>
                                                }
                                                })                      
                                            }
                                        </select>
                                        {this.state.vendor_company_industrey ? <label className="active">Industry Type *</label> : <label></label>}
                                        </div>
                                        <span className="error" style={{color: 'red'}}>{this.state.errors["vendor_company_industrey"]}</span>
                                    </div>
                                </Row>
                                <Row>
                                    <div className="form-field col-md-6">
                                        <MDBInput label="Email *" type="text" name="vendor_company_email" onChange={ this.handleInputChange } />
                                        <span className="error" style={{color: 'red'}}>{this.state.errors["vendor_company_email"]}</span>
                                    </div>
                                    <div className="form-field col-md-6">
                                    <div className="md-form">
                                        {/* <NumberFormat  format="(###) ###-####" mask=""
                                            name="vendor_company_phoneno" onChange={this.handleInputChange} class="form-control rbt-input-main"
                                        />
                                        {this.state.vendor_company_phoneno ? <label className="active">Phone Number *</label> : <label>Phone Number *</label>} */}

                                        <MDBInput label="Phone Number" type="text" name="vendor_company_phoneno" value={this.state.vendor_company_phoneno} onChange={ this.handlePhoneChange } maxlength='15' />

                                        </div>
                                        {/* <span className="error" style={{color: 'red'}}>{this.state.errors["vendor_company_phoneno"]}</span>  */}
                                    </div>
                                </Row>
                                <Row>
                                    <div className="form-field col-md-6">
                                        <MDBInput label="Address *"  type="text" name="vendor_company_address"  onChange={ this.handleInputChange }
                                        />
                                        <span className="error" style={{color: 'red'}}>{this.state.errors["vendor_company_address"]}</span> 
                                    </div>
                                    
                                    <div className="form-field col-md-6">
                                            <MDBInput label="City *" type="text" name="vendor_city" onChange={ this.handleInputChange }/>
                                        <span className="error" style={{color: 'red'}}>{this.state.errors["vendor_city"]}</span> 
                                    </div>
                                </Row>
                                <Row>                                    
                                    {/* <div className="form-field col-md-6">
                                            <MDBInput label="State" type="text" name="vendor_state" onChange={ this.handleInputChange }/>
                                            <span className="error" style={{color: 'red'}}>{this.state.errors["vendor_state"]}</span> 
                                    </div> */}
                                    <div className="form-field col-md-6">
                                         
                                         {/* <div className="md-form">
                                             <select name="vendor_state" class="cs-select cs-skin-elastic form-control"  onChange={ this.handleInputChange }>
                                                 <option value="">State *</option>
                                                 <option value="Alabama">Alabama</option>
                                                       <option value="Alaska">Alaska</option>
                                                       <option value="Arizona">Arizona</option>
                                                       <option value="Arkansas">Arkansas</option>
                                                       <option value="California">California</option>
                                                       <option value="Colorado">Colorado</option>
                                                       <option value="Connecticut">Connecticut</option>
                                                       <option value="Delaware">Delaware</option>
                                                       <option value="District Of Columbia">District Of Columbia</option>
                                                       <option value="Florida">Florida</option>
                                                       <option value="Georgia">Georgia</option>
                                                       <option value="Hawaii">Hawaii</option>
                                                       <option value="Idaho">Idaho</option>
                                                       <option value="Illinois">Illinois</option>
                                                       <option value="Indiana">Indiana</option>
                                                       <option value="Iowa">Iowa</option>
                                                       <option value="Kansas">Kansas</option>
                                                       <option value="Kentucky">Kentucky</option>
                                                       <option value="Louisiana">Louisiana</option>
                                                       <option value="Maine">Maine</option>
                                                       <option value="Maryland">Maryland</option>
                                                       <option value="Massachusetts">Massachusetts</option>
                                                       <option value="Michigan">Michigan</option>
                                                       <option value="Minnesota">Minnesota</option>
                                                       <option value="Mississippi">Mississippi</option>
                                                       <option value="Missouri">Missouri</option>
                                                       <option value="Montana">Montana</option>
                                                       <option value="Nebraska">Nebraska</option>
                                                       <option value="Nevada">Nevada</option>
                                                       <option value="New Hampshire">New Hampshire</option>
                                                       <option value="New Jersey">New Jersey</option>
                                                       <option value="New Mexico">New Mexico</option>
                                                       <option value="New York">New York</option>
                                                       <option value="North Carolina">North Carolina</option>
                                                       <option value="North Dakota">North Dakota</option>
                                                       <option value="Ohio">Ohio</option>
                                                       <option value="Oklahoma">Oklahoma</option>
                                                       <option value="Oregon">Oregon</option>
                                                       <option value="Pennsylvania">Pennsylvania</option>
                                                       <option value="Rhode Island">Rhode Island</option>
                                                       <option value="South Carolina">South Carolina</option>
                                                       <option value="South Dakota">South Dakota</option>
                                                       <option value="Tennessee">Tennessee</option>
                                                       <option value="Texas">Texas</option>
                                                       <option value="Utah">Utah</option>
                                                       <option value="Vermont">Vermont</option>
                                                       <option value="Virginia">Virginia</option>
                                                       <option value="Washington">Washington</option>
                                                       <option value="West Virginia">West Virginia</option>
                                                       <option value="Wisconsin">Wisconsin</option>
                                                       <option value="Wyoming">Wyoming</option>
                                             </select>
                                             {this.state.vendor_state ? <label className="active">State *</label> : <label></label>}
                                             </div>
                                             <span className="error" style={{color: 'red'}}>{this.state.errors["vendor_state"]}</span> */}
     
     
                                            <MDBInput label="State *" type="text" name="vendor_state" onChange={ this.handleInputChange }/>
                                            <span className="error" style={{color: 'red'}}>{this.state.errors["vendor_state"]}</span> 
                                         </div>
                                    <div className="form-field col-md-6">
                                        <MDBInput label="Zip Code *" type="text" name="vendor_zipcode" onChange={this.onChangeNumberkey} value={this.state.vendor_zipcode} />
                                        <span className="error" style={{color: 'red'}}>{this.state.errors["vendor_zipcode"]}</span>
                                    </div>
                                    
                                </Row>
                                <Row>
                                    <div className="form-field col-md-12 form-tags">
                                       {/* <MDBInput label="Naics Codes" />NAICS */}
                                       <div className="md-form">
                                       <label>NAICS Codes *</label>
                                        <Typeahead
                                            clearButton
                                            labelKey={option => `${option.NAICS_Description}`+`(${option.NAICS})`}
                                            multiple
                                            options={this.state.naiccodes}
                                            name="vendor_naic_code"
                                            value={this.state.naiccodes}
                                            onChange={(selected) => {
                                                this.setState({vendor_naic_code:selected})
                                            }}                                                
                                        />
                                        {/* {this.state.vendor_naic_code.length > 0 ? <label className="active">NAICS Codes *</label> : <label>NAICS Codes *</label>} */}
                                        </div>
                                        <span className="error" style={{color: 'red'}}>{this.state.errors["vendor_naic_code"]}</span>
                                    </div>
                                </Row>
                                <Row>
                                    <div className="form-field col-md-12">
                                        <span className="d-block">What is your Company Business Model? *</span> &nbsp;  &nbsp;
                                        <div class="form-group form-check inline">
                                            <input type="radio"  name="vendor_business_model" value="B2B" onChange={ this.handleVendorModelChange }/>
                                            <label for="radio5">B2B</label>
                                        </div> &nbsp;  &nbsp;
                                        <div class="form-group form-check inline">
                                            <input type="radio" name="vendor_business_model" value="B2C"  onChange={ this.handleVendorModelChange }/>
                                            <label for="radio6">B2C</label>
                                        </div>&nbsp;  &nbsp;
                                        <div class="form-group form-check inline">
                                            <input type="radio" name="vendor_business_model" value="Both"  onChange={ this.handleVendorModelChange }/>
                                            <label for="radio7">Both</label>
                                        </div>
                                        <span className="error" style={{color: 'red'}}>{this.state.errors["vendor_business_model"]}</span>
                                    </div>
                                </Row>
                                <Row>
                                    <div className="form-field col-md-12">
                                        <MDBBtn type="submit" title="Vendor Registration">Register<div style={this.state.loading ? {} : { display: 'none' }} class="image-fill w-25 loader-login-div "><img src={Loading} alt="No Image" className="can-click " /></div></MDBBtn>
                                    </div>
                                </Row>
                                
                            </div>
                        </form>
                    </TabPanel>
                    <TabPanel>
                        <form name="companyregistration"  onSubmit= { this.handleSubmit }>
                        <span className="error" style={{color: 'green'}}>{this.state.successMsg}</span>
                        <span className="error" style={{color: 'red'}}>{this.state.errMsg}</span>
                        {/* <div className="avatar-block">
                            <AvatarUploader
                            size={150}
                            fileType={"image/png"}
                            name="avatar"/>
                            <span>Upload Logo</span>
                            <span className="error" style={{color: 'red'}}>{this.state.errors["company_logo"]}</span>
                        </div> */}
                            <div className="form-block">
                            <input type="hidden" name="type" value="company" ref={(input) => { this.actionInput = input }} />
                                <Row>
                                    <div className="form-field col-md-12">
                                        <MDBInput label="Company Name *" type="text" name="company_name" onChange={ this.handleInputChange } />
                                        <span className="error" style={{color: 'red'}}>{this.state.errors["company_name"]}</span>
                                    </div>
                                </Row>
                                <Row>
                                    <div className="form-field col-md-6"> 
                                    <div className="md-form">
                                        <select name="company_type" class="cs-select cs-skin-elastic form-control" onChange={ this.handleInputChange }>
                                            <option value="">Company Type *</option>
                                            {
                                                this.state.companiesdata.map((companies,index)=>{
                                                    if(companies.status == "Active"){
                                                    return <option key={index} value={companies._id}>{companies.companyname}</option> 
                                                    }                      
                                                })
                                            }
                                        </select>
                                        {this.state.company_type ? <label className="active">Company Type *</label> : <label></label>}
                                        </div>       
                                        <span className="error" style={{color: 'red'}}>{this.state.errors["company_type"]}</span>
                                    </div>
                                    <div className="form-field col-md-6">
                                    <div className="md-form">
                                        <select name="company_industrey" class="cs-select cs-skin-elastic form-control"  onChange={ this.handleInputChange }>
                                            <option value="">Industry Type *</option>
                                            {
                                            this.state.industriesdata.map((industries,index)=>{
                                                if(industries.status == "Active"){
                                                return <option key={index} value={industries._id}>{industries.industryname}</option>
                                                }
                                                })                      
                                            }
                                        </select>
                                        {this.state.company_industrey ? <label className="active">Industry Type *</label> : <label></label>}
                                        </div>
                                        <span className="error" style={{color: 'red'}}>{this.state.errors["company_industrey"]}</span>
                                    </div>
                                </Row>
                                <Row>
                                    <div className="form-field col-md-6">
                                        <MDBInput label="Email *" type="text" name="company_email" onChange={ this.handleInputChange } />
                                        <span className="error" style={{color: 'red'}}>{this.state.errors["company_email"]}</span>
                                    </div>
                                    <div className="form-field col-md-6">
                                    <div className="md-form">
                                        {/* <NumberFormat  format="(###) ###-####" mask=""
                                            name="company_phoneno" onChange={this.handleInputChange} class="form-control rbt-input-main" onKeyUp={this.handleKeyUp}
                                        /> */}
                                        {/* <label>Phone Number</label> */}
                                         {/* {this.state.company_phoneno ? <label className="active">Phone Number *</label> : <label>Phone Number *</label>}  */}
                                         <MDBInput label="Phone Number" type="text" name="company_phoneno" value={this.state.company_phoneno} onChange={ this.handlePhoneChange } maxlength='15' />
                                        </div>
                                        {/* <span className="error" style={{color: 'red'}}>{this.state.errors["company_phoneno"]}</span>  */}
                                    </div>
                                </Row>
                                <Row>
                                    <div className="form-field col-md-6">
                                        <MDBInput label="Address *"  type="text" name="company_address"  onChange={ this.handleInputChange }
                                        />
                                        <span className="error" style={{color: 'red'}}>{this.state.errors["company_address"]}</span> 
                                    </div>                                   
                                    <div className="form-field col-md-6">
                                            <MDBInput label="City *" type="text" name="city" value={this.state.city} onChange={ this.handleInputChange } />
                                            <span className="error" style={{color: 'red'}}>{this.state.errors["city"]}</span> 
                                    </div>
                                </Row>
                                <Row>
                                    <div className="form-field col-md-6">
                                         
                                    {/* <div className="md-form">
                                        <select name="state" class="cs-select cs-skin-elastic form-control"  onChange={ this.handleInputChange }>
                                            <option value="">State *</option>
                                            <option value="Alabama">Alabama</option>
                                                  <option value="Alaska">Alaska</option>
                                                  <option value="Arizona">Arizona</option>
                                                  <option value="Arkansas">Arkansas</option>
                                                  <option value="California">California</option>
                                                  <option value="Colorado">Colorado</option>
                                                  <option value="Connecticut">Connecticut</option>
                                                  <option value="Delaware">Delaware</option>
                                                  <option value="District Of Columbia">District Of Columbia</option>
                                                  <option value="Florida">Florida</option>
                                                  <option value="Georgia">Georgia</option>
                                                  <option value="Hawaii">Hawaii</option>
                                                  <option value="Idaho">Idaho</option>
                                                  <option value="Illinois">Illinois</option>
                                                  <option value="Indiana">Indiana</option>
                                                  <option value="Iowa">Iowa</option>
                                                  <option value="Kansas">Kansas</option>
                                                  <option value="Kentucky">Kentucky</option>
                                                  <option value="Louisiana">Louisiana</option>
                                                  <option value="Maine">Maine</option>
                                                  <option value="Maryland">Maryland</option>
                                                  <option value="Massachusetts">Massachusetts</option>
                                                  <option value="Michigan">Michigan</option>
                                                  <option value="Minnesota">Minnesota</option>
                                                  <option value="Mississippi">Mississippi</option>
                                                  <option value="Missouri">Missouri</option>
                                                  <option value="Montana">Montana</option>
                                                  <option value="Nebraska">Nebraska</option>
                                                  <option value="Nevada">Nevada</option>
                                                  <option value="New Hampshire">New Hampshire</option>
                                                  <option value="New Jersey">New Jersey</option>
                                                  <option value="New Mexico">New Mexico</option>
                                                  <option value="New York">New York</option>
                                                  <option value="North Carolina">North Carolina</option>
                                                  <option value="North Dakota">North Dakota</option>
                                                  <option value="Ohio">Ohio</option>
                                                  <option value="Oklahoma">Oklahoma</option>
                                                  <option value="Oregon">Oregon</option>
                                                  <option value="Pennsylvania">Pennsylvania</option>
                                                  <option value="Rhode Island">Rhode Island</option>
                                                  <option value="South Carolina">South Carolina</option>
                                                  <option value="South Dakota">South Dakota</option>
                                                  <option value="Tennessee">Tennessee</option>
                                                  <option value="Texas">Texas</option>
                                                  <option value="Utah">Utah</option>
                                                  <option value="Vermont">Vermont</option>
                                                  <option value="Virginia">Virginia</option>
                                                  <option value="Washington">Washington</option>
                                                  <option value="West Virginia">West Virginia</option>
                                                  <option value="Wisconsin">Wisconsin</option>
                                                  <option value="Wyoming">Wyoming</option>
                                        </select>
                                        {this.state.state ? <label className="active">State *</label> : <label></label>}
                                        </div>
                                        <span className="error" style={{color: 'red'}}>{this.state.errors["state"]}</span> */}


                                         <MDBInput label="State *" type="text" name="state" value={this.state.state} onChange={ this.handleInputChange } />
                                        <span className="error" style={{color: 'red'}}>{this.state.errors["state"]}</span> 
                                    </div>
                                    <div className="form-field col-md-6">
                                        <MDBInput label="Zip Code *" type="text" name="zipcode" value={this.state.zipcode} value={this.state.zipcode} onChange={this.onChangeNumberkey} />
                                        <span className="error" style={{color: 'red'}}>{this.state.errors["zipcode"]}</span>
                                    </div>
                                   
                                </Row>
                                <Row>
                                    <div className="form-field col-md-12 form-tags">
                                       {/* <MDBInput label="Naics Codes" />NAICS */}

                                       <div className="md-form">
                                       <label>NAICS Codes *</label>
                                        <Typeahead
                                            clearButton
                                            labelKey={option => `${option.NAICS_Description}`+`(${option.NAICS})`}
                                            multiple
                                            options={this.state.naiccodes}
                                            name="naic_code"
                                            value={this.state.naiccodes}
                                            onChange={(selected) => {
                                                this.setState({naic_code:selected})
                                              }}  

                                        />
                                        
                                        </div>
                                        <span className="error" style={{color: 'red'}}>{this.state.errors["naic_code"]}</span>
                                    </div>
                                </Row>
                                <Row>
                                    <div className="form-field col-md-12">
                                        <span className="d-block">What is your Company Business Model? *</span> &nbsp;  &nbsp;
                                        <div class="form-group form-check inline">
                                            <input type="radio"  name="company_business_model" value="B2B" onChange={ this.handleCompanyModelChange }/>
                                            <label for="radio2">B2B</label>
                                        </div> &nbsp;  &nbsp;
                                        <div class="form-group form-check inline">
                                            <input type="radio" name="company_business_model" value="B2C"  onChange={ this.handleCompanyModelChange }/>
                                            <label for="radio3">B2C</label>
                                        </div>&nbsp;  &nbsp;
                                        <div class="form-group form-check inline">
                                            <input type="radio" name="company_business_model" value="Both"  onChange={ this.handleCompanyModelChange }/>
                                            <label for="radio4">Both</label>
                                        </div>
                                        <span className="error" style={{color: 'red'}}>{this.state.errors["company_business_model"]}</span>
                                    </div>
                                </Row>
                                <Row>
                                    <div className="form-field col-md-12 mt-4">
                                        <MDBBtn type="submit" title="Company Register">Register<div style={this.state.loading ? {} : { display: 'none' }} class="image-fill w-25 loader-login-div "><img src={Loading} alt="No Image" className="can-click " /></div></MDBBtn>
                                    </div>
                                </Row>
                                
                            </div>
                        </form>
                    </TabPanel>
                </Tabs>
                <Row>
                    {/* <div className="forgot-block text-center col-md-12">Already have an account <a onClick={() => this.setState({ isPaneOpen: true,paneName:'login' })} className="und">Login</a></div> */}

                    <div className="forgot-block text-center col-md-12">Already have an account? <a onClick={this.loginClicked}>Login</a></div>

                </Row>
                <SlidingPane
                    className={this.state.paneName}
                    overlayClassName='some-custom-overlay-class'
                    isOpen={ this.state.isPaneOpen }
                    onRequestClose={ () => {
                    this.setState({ isPaneOpen: false });
                    } }>
                    <LoadPane isOpenPaneName={this.state.paneName} />
                </SlidingPane>
          </div>
        
      );
    }
  }
Register.propTypes = {
   //companyid: PropTypes.object.isRequired
  };
const mapStateToProps = (state) => ({    
    //companyid:state.companyid
  });
  export  default connect(mapStateToProps, {})(Register);

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import { Container, Row, Col, Form, FormGroup, Label, Input,  Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import AppConstants from '../AppConstants';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {ReactTitle} from 'react-meta-tags';
import {logoutUser,getProfile,getVendorProfile} from '../actions/authentication';

//export default class  dashboard extends Component{
class notappliedrfpsreport extends React.Component {
    constructor(props) {
        //console.log("dashboard",props);
        super(props);
        this.state = {      
          "notappliedreport": [],
          errors: {},
          errMsg:false
      };     
    }

    componentDidMount() {        
        fetch(AppConstants.API+'/vendors/notappliedvendorscount/'+this.props.auth.user._id).then(response => response.json())
         .then(data => {             
          if(data.Status == "Success")
          {
            this.setState({
                notappliedreport: data.notappliedreport            
            });
          }            
        });
    }
    
    renderShowsTotal(start, to, total) {
        return (
          <p className="page-show">
          Showing rows { start } to { to } of { total }
          </p>
        );
      }
      actionBtn(cell,row) {          
        return  <div className="action-block"><Link to={`${AppConstants.STAGRFP}${"/notappliedrfpdetails/"}${row.rfprfi_id}`} className="view-icon" title="View Opportunity Details"><i class="fa fa-eye" aria-hidden="true"></i></Link></div>;
    }

    dateFormat(cell,row) {
        // var date = row.rfp_post_date;
        var posteddate = row.rfp_post_date;
        var posteddate= new Date(posteddate);             
        return   ("0" + (posteddate.getMonth() + 1)).slice(-2) + "-" + (posteddate.getDate() < 10 ? '0' : '')+ posteddate.getDate() + "-" +  posteddate.getFullYear(); 
    }
    duedateFormat(cell,row){
        var duedate = row.due_dateandtime;
        var duedate= new Date(duedate);
        var hours = duedate.getHours();
        var minutes = duedate.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return  ("0" + (duedate.getMonth() + 1)).slice(-2) + "-" + (duedate.getDate() < 10 ? '0' : '')+ duedate.getDate() + "-" + duedate.getFullYear() + " " + strTime;
    }
    
    
  render() {    
    
const options = {
  page: 1,  // which page you want to show as default
  sizePerPageList: [ {
    text: '10', value: 10
  }, {
    text: '25', value: 25
  }, {
    text: 'All', value: this.state.notappliedreport.length
  } ], // you can change the dropdown list for size per page
  sizePerPage: 10,  // which size per page you want to locate as default
  pageStartIndex: 1, // where to start counting the pages
  paginationSize: 5,  // the pagination bar size.
  prePage: '<', // Previous page button text
  nextPage: '>', // Next page button text
  firstPage: 'First', // First page button text
  lastPage: 'Last', // Last page button text
  paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
  paginationPosition: 'bottom'  // default is bottom, top and both is all available
 
};
if(localStorage.jwtToken != undefined && this.props.auth.user.type =='vendor'){
    return (
        <div>          
            <ReactTitle title="Not Applied Opportunities on the platform" />      
            <div className="inner-banner">
            </div>
            <div className="inner-content">
                <Container>
                    <div className="dashboard-rfp-list">
                        <div className="dashboard-rfp-head">
                            <h6>Not Applied Opportunities on the platform</h6>                           
                        </div>
                        <BootstrapTable data={this.state.notappliedreport} pagination={ true } options={ options } search={true} searchPlaceholder={'Search by Opportunity Name or Company Name'}>
                            <TableHeaderColumn dataField='name_ofrfp' isKey={ true } dataSort={ true }>Name Of Opportunity</TableHeaderColumn>
                            <TableHeaderColumn dataField='company_name' dataSort={ true }>Company Name </TableHeaderColumn>
                            <TableHeaderColumn dataFormat={this.dateFormat.bind(this)} dataField='rfp_post_date' dataSort={ true }>Posted Date</TableHeaderColumn>
                            <TableHeaderColumn dataField='due_dateandtime' dataFormat={this.duedateFormat.bind(this)} dataSort={ true }>Due Date & Time</TableHeaderColumn>
                            <TableHeaderColumn dataField='Action' dataFormat={ this.actionBtn.bind(this) }>Actions</TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                </Container>
            </div>
        </div>
        
        );
    }
    else
    {
        window.location.href = AppConstants.STAGRFP+'/';
    }
  }
}
const mapStateToProps = (state) => ({
    auth: state.auth
  })
  //export default Header;
  export default connect(mapStateToProps, {})(withRouter(notappliedrfpsreport));

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {} from '../actions/authentication';
//export default class  services extends Component{
class services extends React.Component {
  render() {
    return (
  
    <div>Hello World services.</div>
  
  )
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth
})
//export default Header;
export default connect(mapStateToProps, {})(withRouter(services));
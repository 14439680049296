import React, { Component } from 'react';
import axios from 'axios';
import { Container, Row, Col,Button } from 'reactstrap';
import { MDBInput,MDBBtn } from 'mdbreact';
import AvatarUploader from 'react-avatar-uploader';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {updateCompanyProfile,dashboard} from '../actions/authentication';
import AppConstants from '../AppConstants';
import NumberFormat from 'react-number-format';
import "react-day-picker/lib/style.css";
import {Typeahead} from 'react-bootstrap-typeahead';
import nologo from './logo.jpg';
import Loading from '../pages/loader.gif';
import {ReactTitle} from 'react-meta-tags';

class companyinfo extends React.Component { 
    constructor(props,context){
        super(props,context);
        this.state = {
            _id:this.props.auth.user._id,
            "customer_id": this.props.auth.user._id,
            "company_username" : '',
            "company_name" : '',
            "company_logo" : '',
            "view_logo":'',
            "prev_logo":'',
            "company_email" : '',
            "company_password": '',
            "company_type": '',
            "company_otp" : '',
            "company_otp_status": '',
            "company_industrey": '',
            "company_address": '',
            "company_phoneno": '',
            "company_website": '',
            "company_primary_contact_firstname": '',
            "company_primary_contact_lastname": '',
            "company_primary_contact_middlename": '',
            "company_primary_contact_title": '',
            "company_primary_contact_phoneno": '',
            "company_primary_email": '',
            "company_primary_cellno": '',
            "company_primary_officeno": '',
            "company_primary_department": '',
            "company_secondary_contactno": '',
            "naic_code": [],
            "created_by": this.props.auth.user._id,
            "locations":'',
            "latitude": '',
            "longitude": '',
            "country": '',
            "state": '',
            "city": '',
            "zipcode":'',
            'status':'',       
            errors: {},
            errMsg:'',
            companiesdata:[],
            naiccodes:[],
            countries: [],
            states: [],
            cities: [],
            getcustomersdata:[],
            industriesdata:[],
            "loading": '',
            "company_business_model":'',
            successerrMsg:"",
            "subindustriesdata":[],
            "subindustries":[]
        }      
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleMultiChange = this.handleMultiChange.bind(this); 
        this.handleInputFileChange=this.handleInputFileChange.bind(this);
        this.onChangeNumberkey = this.onChangeNumberkey.bind(this);
        this.handleCompanyModelChange=this.handleCompanyModelChange.bind(this)
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.handleIndustryChange = this.handleIndustryChange.bind(this);
        this.handleSubCatChange = this.handleSubCatChange.bind(this);
       }

       handleSubCatChange(e) {
        const subindustries =this.state.subindustries;   
        let index
        // check if the check box is checked or unchecked
        if (e.target.checked) {
          // add the numerical value of the checkbox to options array
          subindustries.push(e.target.value)
        } else {
          // or remove the value from the unchecked checkbox from the array
          index = subindustries.indexOf(e.target.value)
          subindustries.splice(index, 1)
        }
        //console.log(subindustries);
        // update the state with the new array of options
        this.setState({ subindustries: subindustries })
      }
    
       handleIndustryChange(event) {
        //console.log(event.target.value);
        let company_industrey = '';
        if(event)
        {
          company_industrey = event.target.value;
          this.setState({subindustries: []});
        }
        else {
          company_industrey = this.state.company_industrey;
        }
    
        if(company_industrey !=''){
          fetch(AppConstants.API+'/industries/getsubindustries/' + company_industrey).then(response => response.json())
          .then(data => {
            console.log(" sub industries ", data.subindustriesdata);
            if(data.Status == "Success")
            {
              this.setState({ 
                subindustriesdata: data.subindustriesdata,
                company_industrey: company_industrey,
              });
            }
            else{
              this.setState({
                company_industrey: company_industrey
              });
            }
          });
        }
        else
        {
          this.setState({
            company_industrey: company_industrey        
          });
        }
      }

      handleInputChangeFund(e) {
        const subindustries =this.state.subindustries;   
        let index
        // check if the check box is checked or unchecked
        if (e.target.checked) {
          // add the numerical value of the checkbox to options array
          subindustries.push(e.target.value)
        } else {
          // or remove the value from the unchecked checkbox from the array
          index = subindustries.indexOf(e.target.value)
          subindustries.splice(index, 1)
        }
        //console.log(subindustries);
        // update the state with the new array of options
        this.setState({ subindustries: subindustries })
      }
    
    handleCompanyModelChange(e){
      this.setState({[e.target.name]:e.target.value})
    }

    handleInputChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }  
    handleInputFileChange(e) {
      this.setState({
        [e.target.name]: e.target.files[0]
        });
      e.preventDefault();

      let reader = new FileReader();
      let file = e.target.files[0];
     
      reader.onloadend = () => {
        this.setState({
          file: file,
          imagePreviewUrl: reader.result
        });
      }
  
      reader.readAsDataURL(file)

    
    }
    onChangeNumberkey(e){
        const zip = this.state
        //const re = /^[0-9\b]+$/;
        const re = /^[0-9)\(+ '-]+$/g;
        if (e.target.value === '' || re.test(e.target.value)) {
        zip[e.target.name] = e.target.value;
        this.setState({zip})
        }
    }
    handleMultiChange(e) {
        var options = e.target.options;
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
            value.push(options[i].value);
            }
        }
        this.setState({
            naic_code: value
        });
    }

    handlePhoneChange(e) {
      const re1 =/^[0-9)\(+ '-]+$/g;
     // var phone_num = e.target.value.replace(/\D/g,'');
      if (e.target.value === '' || re1.test(e.target.value)) {
         // phone_num = this.phoneFormat(phone_num)
          this.setState({
              [e.target.name]: e.target.value
          });
      }
  } 
  phoneFormat(input){
    // Strip all characters from the input except digits
    // Trim the remaining input to ten characters, to preserve phone number format
    input = input.substring(0,10);
    // Based upon the length of the string, we add formatting as necessary
    var size = input.length;
    if(size == 0){
            input = input;
    }else if(size < 4){
            input = '('+input;
    }else if(size < 7){
            input = '('+input.substring(0,3)+') '+input.substring(3,6);
    }else{
            input = '('+input.substring(0,3)+') '+input.substring(3,6)+'-'+input.substring(6,10);
    }
    return input; 
  } 

    /** Validations */
  handleValidation(){
    let errors = {};
    let formIsValid = true;
    /* if(!this.state.company_username){
      formIsValid = false;
      errors["company_username"] = "Please enter customer name";
    } */
    //Email
    if(!this.state.company_email){
      formIsValid = false;
      errors["company_email"] = "Please enter email";
    }
    if(typeof this.state.company_email !== "undefined"){
      let lastAtPos = this.state.company_email.lastIndexOf('@');
      let lastDotPos = this.state.company_email.lastIndexOf('.');

      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && this.state.company_email.indexOf('@@') == -1 && lastDotPos > 2 && (this.state.company_email.length - lastDotPos) > 2)) {
        formIsValid = false;
        errors["company_email"] = "Please enter valid email";
      }
    }
    if(!this.state.company_name){
      formIsValid = false;
      errors["company_name"] = "Please enter company name";
    }
    if(!this.state.company_type){
      formIsValid = false;
      errors["company_type"] = "Please select company type";
    }
    if(!this.state.company_industrey){
      formIsValid = false;
      errors["company_industrey"] = "Please select industry type";
    }
    if(!this.state.company_address){
      formIsValid = false;
      errors["company_address"] = "Please enter address";
    }
    /* if(this.state.company_phoneno !='' && this.state.company_phoneno.length !=14){
      formIsValid = false;
      errors["company_phoneno"] = "Please enter 10 digits company phone number";
    }
    if(this.state.company_primary_cellno !='' && this.state.company_primary_cellno.length !=14){
      formIsValid = false;
      errors["company_primary_cellno"] = "Please enter 10 digits company primary cell number";
    }
    if(this.state.company_primary_officeno !='' && this.state.company_primary_officeno.length !=14){
      formIsValid = false;
      errors["company_primary_officeno"] = "Please enter 10 digits company primary office number";
    }
    if(this.state.company_secondary_contactno !='' && this.state.company_secondary_contactno.length !=14){
      formIsValid = false;
      errors["company_secondary_contactno"] = "Please enter 10 digits company secondary contact number";
    } */
    if(!this.state.company_primary_contact_firstname){
      formIsValid = false;
      errors["company_primary_contact_firstname"] = "Please enter firstname";
    }
    if(!this.state.company_primary_contact_lastname){
      formIsValid = false;
      errors["company_primary_contact_lastname"] = "Please enter lastname";
    }
    /*if(!this.state.company_primary_contact_phoneno){
      formIsValid = false;
      errors["company_primary_contact_phoneno"] = "Please enter phone number";
    } else if(this.state.company_primary_contact_phoneno.length !=14){
      formIsValid = false;
      errors["company_primary_contact_phoneno"] = "Please enter 10 digits phone number";     
    } */
    if(!this.state.company_primary_email){
      formIsValid = false;
      errors["company_primary_email"] = "Please enter email";
    }
    if(typeof this.state.company_primary_email !== "undefined"){
      let lastAtPos = this.state.company_primary_email.lastIndexOf('@');
      let lastDotPos = this.state.company_primary_email.lastIndexOf('.');

      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && this.state.company_primary_email.indexOf('@@') == -1 && lastDotPos > 2 && (this.state.company_primary_email.length - lastDotPos) > 2)) {
        formIsValid = false;
        errors["company_primary_email"] = "Please enter valid email";
      }
    }
    if(!this.state.naic_code.length){
      formIsValid = false;
      errors["naic_code"] = "Please select at-least one naic code";
    }
    /* if(!this.state.country){
      formIsValid = false;
      errors["country"] = "Please select country";
    } */
    if(!this.state.state){
      formIsValid = false;
      errors["state"] = "Please enter state";
    }  
    if(!this.state.city){
      formIsValid = false;
      errors["city"] = "Please enter city";
    }
    if(!this.state.zipcode){
      formIsValid = false;
      errors["zipcode"] = "Please enter zip code";
    }else if(this.state.zipcode.length > 15){
      formIsValid = false;
      errors["zipcode"] = "Please enter valid zipcode";
    }

    if(this.state.company_logo && this.state.company_logo.name && !this.state.company_logo.name.match(/.(jpg|jpeg|png)$/i)){
      formIsValid = false;
      errors["company_logo"] = "Please select valid image (Ex: jpg | jpeg | png )";
    }
    if(!this.state.company_business_model){
      formIsValid =false;
      errors["company_business_model"]="Please select business model";
    }

    if(this.state.company_website && !/^https?:\/\//i.test(this.state.company_website)){
        formIsValid = false;
        errors["company_website"] = "Please enter valid website";
    }

    this.setState({errors: errors});
    return formIsValid;
  }

  handleSubmit = event => {
    
    //console.log(this.props.auth.user)
    event.preventDefault();
    //alert('form submitted');    
     if(this.handleValidation()){
        this.setState({loading:1});  
      let formData = new FormData();
        formData.append('id', this.props.auth.user._id);
        formData.append('customer_id', this.props.auth.user._id);
        formData.append('company_username', this.state.company_username);
        formData.append('company_name', this.state.company_name);
        formData.append('company_logo', this.state.company_logo);
        formData.append('company_email', this.state.company_email);
        formData.append('company_type', this.state.company_type);
        formData.append('company_industrey', this.state.company_industrey);
        formData.append('company_address', this.state.company_address);
        formData.append('company_phoneno', this.state.company_phoneno);
        formData.append('company_website', this.state.company_website);
        formData.append('company_primary_contact_firstname', this.state.company_primary_contact_firstname);
        formData.append('company_primary_contact_lastname', this.state.company_primary_contact_lastname);
        formData.append('company_primary_contact_middlename', this.state.company_primary_contact_middlename);
        formData.append('company_primary_contact_title', this.state.company_primary_contact_title);
        formData.append('company_primary_contact_phoneno', this.state.company_primary_contact_phoneno);
        formData.append('company_primary_email', this.state.company_primary_email);
        formData.append('company_primary_cellno', this.state.company_primary_cellno);
        formData.append('company_primary_officeno', this.state.company_primary_officeno);
        formData.append('company_primary_department', this.state.company_primary_department);
        formData.append('company_secondary_contactno', this.state.company_secondary_contactno);
        formData.append('naic_code', JSON.stringify(this.state.naic_code));
        formData.append('locations', this.state.locations);
        formData.append('country', this.state.country);
        formData.append('state', this.state.state);
        formData.append('city', this.state.city);
        formData.append('zipcode', this.state.zipcode);
        formData.append('status', this.state.status);
        formData.append('deleted', 0);
        formData.append('prev_logo', this.state.prev_logo);
        formData.append('subindustries', JSON.stringify(this.state.subindustries));
        //formData.append('request_from', "admin");
        formData.append('company_business_model',this.state.company_business_model);

        const config = {
            headers: {
              'content-type': 'multipart/form-data'
            }
          };
          this.props.updateCompanyProfile(formData,config,this.props.history);
          
    } 
  };

  componentDidMount()
  { 
    this.props.dashboard("");       
    window.scrollTo(0, 0);
    //Get customers data
        fetch(AppConstants.API+'/cutomers/editcustomer/'+this.props.auth.user._id).then(response => response.json())
        .then(data => {
        //console.log(" customer naic codes  ", data.getcustomerdata.naic_code);
        if(data.Status == "Success")
        {
          let subcat;
          if(data.getcustomerdata.subindustries != undefined || data.getcustomerdata.subindustries != null){
              subcat = data.getcustomerdata.subindustries;
          }
          else
          {
            subcat = [];
          }
            this.setState({ 
            //getcustomersdata: data.getcustomerdata,
            customer_id: this.props.auth.user._id,
            company_username: data.getcustomerdata.company_username,
            company_name: data.getcustomerdata.company_name,
            company_email: data.getcustomerdata.company_email,
            company_logo : data.getcustomerdata.company_logo || '',
            company_type: data.getcustomerdata.company_type,
            company_industrey: data.getcustomerdata.company_industrey,
            company_address: data.getcustomerdata.company_address,
            company_phoneno: data.getcustomerdata.company_phoneno,
            company_website: data.getcustomerdata.company_website,
            company_primary_contact_firstname: data.getcustomerdata.company_primary_contact_firstname,
            company_primary_contact_lastname: data.getcustomerdata.company_primary_contact_lastname,
            company_primary_contact_middlename: data.getcustomerdata.company_primary_contact_middlename,
            company_primary_contact_title: data.getcustomerdata.company_primary_contact_title,
            company_primary_contact_phoneno: data.getcustomerdata.company_primary_contact_phoneno,
            company_primary_email: data.getcustomerdata.company_primary_email,
            company_primary_cellno: data.getcustomerdata.company_primary_cellno,
            company_primary_officeno: data.getcustomerdata.company_primary_officeno,
            company_primary_department: data.getcustomerdata.company_primary_department,
            company_secondary_contactno: data.getcustomerdata.company_secondary_contactno,
            naic_code: data.getcustomerdata.selected_naic_codes,
            locations: data.getcustomerdata.locations,
            country: data.getcustomerdata.country,
            state: data.getcustomerdata.state,
            city: data.getcustomerdata.city,
            zipcode: data.getcustomerdata.zipcode,
            status: data.getcustomerdata.status,
            deleted:0,
            view_logo: data.getcustomerdata.company_logo || nologo,
            prev_logo: data.getcustomerdata.company_logo,
            company_business_model:data.getcustomerdata.company_business_model,
            subindustries: subcat
            });
            //this.handleCountryChange();
            // this.handleStateChange();
            this.handleIndustryChange();
        }
        else
        {
            this.setState({ getcustomersdata: "" });
        }    
        });
        //Get Industries data
            fetch(AppConstants.API+'/industries/getallindustries').then(response => response.json())
            .then(data => {
            // console.log(data);
            if(data.Status == "Success")
            {
            this.setState({ industriesdata: data.industriesdata });
            }
            else
            {
            this.setState({ industriesdata: "" });
            }    
        });
        //Get companies data
        fetch(AppConstants.API+'/companies/getallcompanies').then(response => response.json())
            .then(data => {
            //console.log(" companies ", data);
            if(data.Status == "Success")
            {
            this.setState({ companiesdata: data.companiesdata });
            }
            else
            {
            this.setState({ companiesdata: "" });
            }    
        });
        //Get naic codes
        fetch(AppConstants.API+'/users/getallnaiccodes').then(response => response.json())
            .then(data => {
        //console.log(" naic codes ", data);
            if(data.Status == "Success")
            {
            this.setState({ naiccodes: data.naiccodes });
            }
            else
            {
            this.setState({ naiccodes: "" });
            }    
        });
    }
       
    componentWillReceiveProps(nextProps) {       		 
      if(nextProps.auth.isAuthenticated == true && nextProps.auth.user.type=='company') {
          console.log(nextProps.auth.user);
          this.setState({
              id:nextProps.auth.user._id,
              //successerrMsg:nextProps.auth.user.ms
            }); 
            if(nextProps.auth.user.Status=="Success"){
              this.setState({successerrMsg:nextProps.auth.user.msg})
            }
      }        
      if (nextProps.errors.Status == 'Failed')
      {
          this.setState({ errMsg: nextProps.errors.msg });
          this.setState({loading:''});
          setTimeout((e) => {
              this.setState({
                  errMsg: ''
              });
          },2000);
      } 
    }
  render() {

    let {imagePreviewUrl} = this.state;
    let $imagePreview = null;
    if (imagePreviewUrl) {
      $imagePreview = (<div className="hhRbZu"><img src={imagePreviewUrl} /></div>);
    } else {
      $imagePreview = (<div className="hhRbZu"><img src={this.state.view_logo} /></div>);
    }

    const checkboxItems = this.state.company_industrey != '' 
    ? this.state.subindustriesdata.map(planet => {
        return (
        <div className="form-group form-check inline cst-c-block cst-c-block-SM"><input type="checkbox" name="subindustries" value={planet.subcatid} onChange={this.handleSubCatChange} checked={this.state.subindustries.includes(planet.subcatid) || ''} class="form-control" id={planet.subcatid} /><label for={planet.subcatid}>{planet.subindustryname}</label></div>);
        })
    : null;
    if(localStorage.jwtToken != undefined){
    return (
        <div>
            <ReactTitle title="Company Profile" />
            <div className="inner-banner">
            </div>
            <div className="inner-content rfpviewdetail">
                <Container>
                <form name="vendorprofile" onSubmit= { this.handleSubmit }>
                
                   <div className="white-bg pad50">
                        <Row className="justify-content-center">
                                <Col md="10">                                
                                    {/* <h1 className="color-yellow">Company Profile</h1> */}
                                    <div>
                                        <div className="">
                                        <h3>Basic Information</h3> 
                                        <div className="form-field form-block ">
                                                <div className="avatar-block">
                                                <input type="hidden" name="prev_logo" value={this.state.prev_logo} />
                                                <input type="file" name="company_logo"  onChange={ this.handleInputFileChange } />
                                                {$imagePreview}
                                                <span>Logo</span>
                                                </div>
                                                <span className="error avatar-error" style={{color: 'red'}}>{this.state.errors["company_logo"]}</span>
                                                </div>

                                            <div className="form-block">
                                                <Row>
                                                    <div className="form-field col-md-6">
                                                        <MDBInput label="Company Name *"  name="company_name" value={this.state.company_name}
                                                        onChange={ this.handleInputChange } md-autofocus="true" />
                                                        <span className="error" style={{color: 'red'}}>{this.state.errors["company_name"]}</span>
                                                        </div>
                                                    <div className="form-field col-md-6">
                                                    <MDBInput label="Email *"  name="company_email" onChange={ this.handleInputChange } value={this.state.company_email} readOnly/>
                                                        <span className="error" style={{color: 'red'}}>{this.state.errors["company_email"]}</span>
                                                    </div>
                                                </Row>
                                                <Row>
                                                    <div className="form-field col-md-12">
                                                    <div className="md-form">
                                                        <select class="cs-select cs-skin-elastic form-control" name="company_type" value={this.state.company_type}  onChange={ this.handleInputChange }>
                                                            <option value="">Company Type *</option>
                                                            {
                                                            this.state.companiesdata.map((companies,index)=>{
                                                                if(companies.status == "Active"){
                                                                return <option key={index} value={companies._id}>{companies.companyname}</option> 
                                                                }                      
                                                            })
                                                            }
                                                        </select>
                                                        {this.state.company_type ? <label className="active">Company Type *</label> : <label></label>}
                                                        </div>
                                                        <span className="error" style={{color: 'red'}}>{this.state.errors["company_type"]}</span>
                                                    </div>
                                                    <div className="form-field col-md-12">
                                                    <div className="md-form">
                                                        <select class="cs-select cs-skin-elastic form-control" name="company_industrey" value={this.state.company_industrey}  onChange={ this.handleIndustryChange }>
                                                            <option value="">Industry Type *</option>
                                                            {
                                                            this.state.industriesdata.map((industries,index)=>{
                                                                if(industries.status == "Active"){
                                                                return <option key={index} value={industries._id}>{industries.industryname}</option> 
                                                                }                      
                                                            })
                                                            }
                                                        </select>
                                                        {this.state.company_industrey ? <label className="active">Industry Type *</label> : <label></label>}
                                                        </div>
                                                        <span className="error" style={{color: 'red'}}>{this.state.errors["company_industrey"]}</span>
                                                    </div>
                                                </Row>
                                                <Row>
                                                    <div className="form-field col-md-12">
                                                        <div className="md-form">
                                                            {checkboxItems}
                                                        </div>
                                                    </div>
                                                </Row>
                                                <Row>
                                                    <div className="form-field col-md-6">
                                                        <MDBInput label="Address *"  name="company_address" value={this.state.company_address}
                                                        onChange={ this.handleInputChange } />
                                                        <span className="error" style={{color: 'red'}}>{this.state.errors["company_address"]}</span>
                                                        </div>                                                  

                                                     <div className="form-field col-md-6">
                                                        <MDBInput label="City *"  name="city" value={this.state.city}
                                                        onChange={ this.handleInputChange } />
                                                        <span className="error" style={{color: 'red'}}>{this.state.errors["city"]}</span>
                                                        </div>


                                                </Row>
                                                <Row>
                                                <div className="form-field col-md-6">
                                                  {/* <div className="md-form">
                                                  <select class="cs-select cs-skin-elastic form-control" name="state" value={this.state.state}  onChange={ this.handleInputChange }>
                                                  <option value="">State *</option>
                                                  <option value="Alabama">Alabama</option>
                                                  <option value="Alaska">Alaska</option>
                                                  <option value="Arizona">Arizona</option>
                                                  <option value="Arkansas">Arkansas</option>
                                                  <option value="California">California</option>
                                                  <option value="Colorado">Colorado</option>
                                                  <option value="Connecticut">Connecticut</option>
                                                  <option value="Delaware">Delaware</option>
                                                  <option value="District Of Columbia">District Of Columbia</option>
                                                  <option value="Florida">Florida</option>
                                                  <option value="Georgia">Georgia</option>
                                                  <option value="Hawaii">Hawaii</option>
                                                  <option value="Idaho">Idaho</option>
                                                  <option value="Illinois">Illinois</option>
                                                  <option value="Indiana">Indiana</option>
                                                  <option value="Iowa">Iowa</option>
                                                  <option value="Kansas">Kansas</option>
                                                  <option value="Kentucky">Kentucky</option>
                                                  <option value="Louisiana">Louisiana</option>
                                                  <option value="Maine">Maine</option>
                                                  <option value="Maryland">Maryland</option>
                                                  <option value="Massachusetts">Massachusetts</option>
                                                  <option value="Michigan">Michigan</option>
                                                  <option value="Minnesota">Minnesota</option>
                                                  <option value="Mississippi">Mississippi</option>
                                                  <option value="Missouri">Missouri</option>
                                                  <option value="Montana">Montana</option>
                                                  <option value="Nebraska">Nebraska</option>
                                                  <option value="Nevada">Nevada</option>
                                                  <option value="New Hampshire">New Hampshire</option>
                                                  <option value="New Jersey">New Jersey</option>
                                                  <option value="New Mexico">New Mexico</option>
                                                  <option value="New York">New York</option>
                                                  <option value="North Carolina">North Carolina</option>
                                                  <option value="North Dakota">North Dakota</option>
                                                  <option value="Ohio">Ohio</option>
                                                  <option value="Oklahoma">Oklahoma</option>
                                                  <option value="Oregon">Oregon</option>
                                                  <option value="Pennsylvania">Pennsylvania</option>
                                                  <option value="Rhode Island">Rhode Island</option>
                                                  <option value="South Carolina">South Carolina</option>
                                                  <option value="South Dakota">South Dakota</option>
                                                  <option value="Tennessee">Tennessee</option>
                                                  <option value="Texas">Texas</option>
                                                  <option value="Utah">Utah</option>
                                                  <option value="Vermont">Vermont</option>
                                                  <option value="Virginia">Virginia</option>
                                                  <option value="Washington">Washington</option>
                                                  <option value="West Virginia">West Virginia</option>
                                                  <option value="Wisconsin">Wisconsin</option>
                                                  <option value="Wyoming">Wyoming</option>
                                                  </select>
                                                  {this.state.state ? <label className="active">State *</label> : <label></label>}
                                                  </div>
                                                  <span className="error" style={{color: 'red'}}>{this.state.errors["state"]}</span> */}
                                                    <MDBInput label="State *"  name="state" onChange={ this.handleInputChange } value={this.state.state}/>
                                                    <span className="error" style={{color: 'red'}}>{this.state.errors["state"]}</span>
                                                    </div>
                                                    <div className="form-field col-md-6">
                                                    <MDBInput label="Zip Code *"  name="zipcode" onChange={ this.onChangeNumberkey } value={this.state.zipcode}/>
                                                        <span className="error" style={{color: 'red'}}>{this.state.errors["zipcode"]}</span>
                                                    </div>
                                                </Row>

                                                <Row>
                                                    <div className="form-field col-md-6">
                                                        <MDBInput label="Department"  name="company_primary_department" value={this.state.company_primary_department}
                                                        onChange={ this.handleInputChange } />
                                                        </div>
                                                    <div className="form-field col-md-6">
                                                    <MDBInput label="Website"  name="company_website" onChange={ this.handleInputChange } value={this.state.company_website}/>
                                                    <span className="error" style={{color: 'red'}}>{this.state.errors["company_website"]}</span>
                                                    </div>
                                                </Row>
                                                
                                                <Row>
                                                  <div className="form-field col-md-6">
                                                  <h6>What is your Company Business Model? *</h6>
                                                  </div>
                                                  <div className="form-field col-md-6">
                                                  <div>
                                                <div class="form-group form-check inline">
                                                  <input type="radio" name="company_business_model" value="B2B"
                                                checked={this.state.company_business_model=='B2B'} 
                                                onChange={ this.handleCompanyModelChange } /> 
                                                  <label for="radio2"> B2B &nbsp;  &nbsp;</label>
                                              </div>
                                              <div class="form-group form-check inline">
                                                <input type="radio" name="company_business_model" 
                                                  checked={this.state.company_business_model=='B2C'}
                                                  value="B2C"  onChange={ this.handleCompanyModelChange }/> 
                                                  <label for="radio3"> B2C &nbsp;  &nbsp;</label>
                                              </div>
                                              <div class="form-group form-check inline">
                                                <input type="radio" name="company_business_model"
                                                  checked={this.state.company_business_model=='Both'}
                                                  value="Both"  onChange={ this.handleCompanyModelChange }/> 
                                                  <label for="radio4"> Both </label>
                                                  
                                              </div>
                                              </div>
                                              <span className="error" style={{color: 'red'}}>{this.state.errors["company_business_model"]}</span>
                                              </div>
                                              
                                              </Row>
                                               
                                                <Row>
                                                    <div className="form-field col-md-12 form-tags">
                                                    <div className="md-form">
                                                    <label>NAICS Codes *</label>
                                                    <Typeahead
                                                        clearButton
                                                        labelKey={option => `${option.NAICS_Description}`+` (${option.NAICS})`}
                                                        multiple
                                                        options={this.state.naiccodes}
                                                        
                                                        name="naic_code"
                                                        value={this.state.naiccodes}
                                                        onChange={(selected) => {
                                                            this.setState({naic_code:selected})
                                                        }}  class="form-control"
                                                        selected={this.state.naic_code}     
                                                    />
                                                    {/* {this.state.naic_code.length > 0 ? <label className="active">NAICS Codes *</label> : <label>NAICS Codes *</label>} */}
                                                    </div>
                                                <span className="error" style={{color: 'red'}}>{this.state.errors["naic_code"]}</span>
                                                    </div>
                                                </Row>
                                            </div>
                                            <div className="form-block">
                                            <h3 className="vi-title">Contact Information</h3>
                                                <Row>
                                                    <div className="form-field col-md-4">
                                                        <MDBInput label="First Name *"  name="company_primary_contact_firstname" value={this.state.company_primary_contact_firstname}
                                                        onChange={ this.handleInputChange } />
                                                        <span className="error" style={{color: 'red'}}>{this.state.errors["company_primary_contact_firstname"]}</span>
                                                        </div>
                                                        <div className="form-field col-md-4">
                                                        <MDBInput label="Middle Name"  name="company_primary_contact_middlename" onChange={ this.handleInputChange } value={this.state.company_primary_contact_middlename}/>
                                                        </div>
                                                    <div className="form-field col-md-4">
                                                    <MDBInput label="Last Name *"  name="company_primary_contact_lastname" onChange={ this.handleInputChange } value={this.state.company_primary_contact_lastname}/>
                                                        <span className="error" style={{color: 'red'}}>{this.state.errors["company_primary_contact_lastname"]}</span>
                                                    </div>
                                                </Row>
                                                {/* <Row>                                                   
                                                    <div className="form-field col-md-6">
                                                    <MDBInput label="Middle Name"  name="company_primary_contact_middlename" onChange={ this.handleInputChange } value={this.state.company_primary_contact_middlename}/>
                                                    </div>
                                                </Row> */}
                                                <Row>
                                                    <div className="form-field col-md-6">
                                                        <MDBInput label="Email *"  name="company_primary_email" value={this.state.company_primary_email}
                                                        onChange={ this.handleInputChange } />
                                                        <span className="error" style={{color: 'red'}}>{this.state.errors["company_primary_email"]}</span>
                                                        </div>
                                                    <div className="form-field col-md-6">
                                                    <MDBInput label="Title"  name="company_primary_contact_title" onChange={ this.handleInputChange } value={this.state.company_primary_contact_title}/>
                                                    </div>
                                                </Row>
                                                
                                                <Row>
                                                    {/* <div className="form-field col-md-6">
                                                    <div className="md-form">
                                                    <div className="input-field phoneblock">
                                                    <NumberFormat
                                                        format="(###) ###-####" mask=""
                                                        name="company_primary_contact_phoneno"
                                                        onChange={this.handleInputChange}
                                                        value={this.state.company_primary_contact_phoneno} class="form-control"/>
                                                        {this.state.company_primary_contact_phoneno ? <label className="active">Phone Number *</label> : <label>Phone Number *</label>}

                                                        </div> 
                                                        </div>
                                                        <span className="error" style={{color: 'red'}}>{this.state.errors["company_primary_contact_phoneno"]}</span> 
                                                    </div> */}

                                                    <div className="form-field col-md-6">
                                                          <MDBInput label="Phone Number" type="text" name="company_primary_contact_phoneno" value={this.state.company_primary_contact_phoneno} onChange={ this.handlePhoneChange } maxlength='15' /> 
                                                          {/* <span className="error" style={{color: 'red'}}>{this.state.errors["company_primary_contact_phoneno"]}</span> */}
                                                          {/* <MDBInput label="Phone Number" type="text" maxlength="15" name="company_primary_contact_phoneno" value={this.state.company_primary_contact_phoneno} onChange={ this.handleInputChange } />  */}
                                                        {/* <span className="error" style={{color: 'red'}}>{this.state.errors["company_primary_contact_phoneno"]}</span>  */}
                                                    </div>

                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                        </Row>
                        <Row className="justify-content-center">
                                <span style={{color: 'green'}}>{this.state.successerrMsg}</span>
                                <span className="error" style={{color: 'red'}}>{this.state.errMsg}</span>
                            <Col md="10"><span class="btn btn-sm fright" onClick={ this.handleSubmit } title="Update Company Profile">Update<div style={this.state.loading ? {} : { display: 'none' }} class="image-fill w-25 loader-login-div companyinfo_load"><img src={Loading} alt="No Image" className="can-click " /></div></span></Col>
                        </Row>
                
                   </div>
                </form>
                </Container>
            </div>
        </div>
        
        );
    }else
    {
        window.location.href = AppConstants.STAGRFP+'/';
    }
  }
}
companyinfo.propTypes = {
    updateCompanyProfile: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    dashboard:PropTypes.func.isRequired
  };
  const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors
  });
  
  //export default ChangepasswordPage;
  export  default connect(mapStateToProps, {updateCompanyProfile,dashboard})(companyinfo);
import { SET_COMPANY_USER,GET_COMPANY_DETAILS,GET_VENDOR_DETAILS, UPDATE_VENDOR,SET_USER,UPDATE_COMPANY,COMPANY_DASH_BOARD,VENDOR_DASH_BOARD} from '../actions/types';
import isEmpty from '../validation/is-empty';

const initialState = {
    isAuthenticated: false,
    user: {},
    user1:""
    //className1:""
}
export default function(state = initialState, action ) {
    //console.log(action.payload);
    switch(action.type) {
        case SET_COMPANY_USER:
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                user: action.payload
            }
            case SET_USER:
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                user: action.payload
            }
            case GET_COMPANY_DETAILS:
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                user: action.payload
            }
            case GET_VENDOR_DETAILS:
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                user: action.payload
            }
            case UPDATE_VENDOR:
            return {
              ...state,
              isAuthenticated: !isEmpty(action.payload),
              user: action.payload
            } 
            case UPDATE_COMPANY:
            return {
              ...state,
              isAuthenticated: !isEmpty(action.payload),
              user: action.payload
            } 
            case COMPANY_DASH_BOARD:
            return{
                ...state,
                activeClass : action.payload
            }
            case VENDOR_DASH_BOARD:
            return{
                ...state,
                activeClass : action.payload
            }
        default: 
            return state;
    }
}
import React, { Component } from 'react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import { Container, Row, Col } from 'reactstrap';
import AppConstants from '../AppConstants';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import {logoutUser,getProfile,dashboard} from '../actions/authentication';
import {ReactTitle} from 'react-meta-tags';
import PropTypes from 'prop-types';
//export default class  dashboard extends Component{
class allrfprfis extends React.Component {
    constructor(props) {
        //console.log(props);
        super(props);
        this.state = {      
            rfprfiprofilesdata: [],
            errors: {},
            errMsg:false
      };
    }

    componentDidMount() {
      this.props.dashboard("dashboard");        
      window.scrollTo(0, 0);
        fetch(AppConstants.API+'/rfprfiprofiles/getcompanypostedrfprfisdata/'+this.props.auth.user._id).then(response => response.json())
         .then(data => {
       // fetch(AppConstants.API+'/rfprfiprofiles/getallrfprfiprofiles').then(response => response.json())
        //.then(data => {
          if(data.Status == "Success")
          {
            this.setState({ rfprfiprofilesdata: data.rfprfiprofilesdata });
          }
          else
          {
            this.setState({ rfprfiprofilesdata: []});
          }    
        });       
    }

    renderShowsTotal(start, to, total) {
        return (
          <p className="page-show">
          Showing rows { start } to { to } of { total }
          </p>
        );
      }
      actionBtn(cell,row) {
    /* return  <div className="action-block"><a href="" onClick={this.Action} className="btn btn-sm"><i class="fa fa-eye" aria-hidden="true"></i>View</a><a href="" onClick={this.Action} className="btn btn-sm"><i class="fa fa-edit" aria-hidden="true"></i>Edit</a></div>;  */       
    return  <div className="action-block"><Link to={`${AppConstants.STAGRFP}${"/viewrfpinfo/"}${row._id}`} className="view-icon" title="View Opportunity Details"><i class="fa fa-eye" aria-hidden="true"></i></Link></div>; 
    }

    dateFormat(cell,row) {
            // var date = row.rfp_post_date;
            var posteddate = row.rfp_post_date;
            var posteddate= new Date(posteddate);             
            return   ("0" + (posteddate.getMonth() + 1)).slice(-2) + "-" + (posteddate.getDate() < 10 ? '0' : '')+ posteddate.getDate() + "-" +  posteddate.getFullYear();      
 
        }
 
      duedateFormat(cell,row){
          var duedate = row.due_dateandtime;
          var duedate= new Date(duedate);
          var hours = duedate.getHours();
          var minutes = duedate.getMinutes();
          var ampm = hours >= 12 ? 'PM' : 'AM';
          hours = hours % 12;
          hours = hours ? hours : 12; // the hour '0' should be '12'
          minutes = minutes < 10 ? '0'+minutes : minutes;
          var strTime = hours + ':' + minutes + ' ' + ampm;
          return  ("0" + (duedate.getMonth() + 1)).slice(-2) + "-" + (duedate.getDate() < 10 ? '0' : '')+ duedate.getDate() + "-" + duedate.getFullYear() + " " + strTime;
      }

      naicInfo(cell,row) {
        return  <div>
                  <span className="naiccodes">
                      <ul style={{listStyle:'none', padding:'0'}}>
                          {row.naiccodes.map(recipe => {
                                  return (
                                      <li>{recipe}<span>,</span></li>
                                      )
                                  })
                              }                           
                      </ul>
                      {row.naiccodes == '' && <span>No NAICS Codes</span>} 
                  </span>
              </div> ;        
        }
    
    
    
  render() {    
    
const options = {
  page: 1,  // which page you want to show as default
  sizePerPageList: [ {
    text: '10', value: 10
  }, {
    text: '25', value: 25
  }, {
    text: 'All', value: this.state.rfprfiprofilesdata.length
  } ], // you can change the dropdown list for size per page
  sizePerPage: 10,  // which size per page you want to locate as default
  pageStartIndex: 1, // where to start counting the pages
  paginationSize: 5,  // the pagination bar size.
  prePage: '<', // Previous page button text
  nextPage: '>', // Next page button text
  firstPage: 'First', // First page button text
  lastPage: 'Last', // Last page button text
  paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
  paginationPosition: 'bottom'  // default is bottom, top and both is all available
 
};
if(localStorage.jwtToken != undefined && this.props.auth.user.type =='company'){
    return (
        <div>
            <ReactTitle title="Opportunity List" />
            <div className="inner-banner">
            </div>
            <div className="inner-content">
                <Container>
                    <div className="dashboard-rfp-list">
                        <div className="dashboard-rfp-head">
                            <h6>Opportunity List</h6>
                            {/* <span className="applied">Applied</span>
                            <span className="not-applied">Not Applied</span>
                            <span className="liked">Liked</span> */}
                        </div>
                        <BootstrapTable data={this.state.rfprfiprofilesdata} pagination={ true } options={ options } search={true} searchPlaceholder={'Search by Opportunity Name or NAICS Code'}>
                            
                            {/* <TableHeaderColumn dataField='_id' isKey={ true } dataSort={ true } hidden={true}>ID</TableHeaderColumn> */}

                            <TableHeaderColumn dataField='name_ofrfp' isKey={ true } dataSort={ true }>Name Of Opportunity</TableHeaderColumn>
                            <TableHeaderColumn dataField='naiccodes' dataFormat={ this.naicInfo.bind(this) } dataSort={ true }>NAICS Code</TableHeaderColumn>
                            <TableHeaderColumn dataFormat={this.dateFormat.bind(this)} dataField='rfp_post_date' dataSort={ true }>Posted Date</TableHeaderColumn>
                            <TableHeaderColumn dataField='due_dateandtime' dataFormat={this.duedateFormat.bind(this)}  dataSort={ true }>Due Date & Time</TableHeaderColumn>
                            <TableHeaderColumn dataField='Action' dataFormat={ this.actionBtn.bind(this) }>Actions</TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                </Container>
            </div>
        </div>
        
        );
    }
    else
    {
      window.location.href = AppConstants.STAGRFP+'/'
    }
  }
}
allrfprfis.propTypes = {
  dashboard:PropTypes.func.isRequired
};
const mapStateToProps = (state) => ({
    auth: state.auth
  })
  //export default Header;
  export default connect(mapStateToProps, {logoutUser,getProfile,dashboard})(withRouter(allrfprfis));

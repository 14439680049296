import React, { Component } from 'react';
import Slider from '../components/Slider';
import { Container, Row, Col } from 'reactstrap';
import AppConstants from '../AppConstants';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {logoutUser,getProfile,getVendorProfile} from '../actions/authentication';
//import Cookies from 'universal-cookie';

//const cookies = new Cookies();

//export default class  home extends Component{
class home extends React.Component {
  constructor(props) {
    super(props);
    //console.log(props);
    this.state = {      
      vendors_count: 0,
      customers_count: 0,
      companies_count:0,
      data:0,
      title:'',
      description:'',
      howitworkstitle:'',
      howitworksdescription:'',
      companytitle:'',
      companydescription:'',
      vendortitle:'',
      vendordescription:'',
      partnertitle:'',
      partnerdescription:'',
      companyvideo:''
    };    
  }
  componentDidMount() {

    //console.log("David: ",window.location.pathname);

    //cookies.set('myurl', window.location.pathname);

    //var newurl_link = cookies.get('myurl');
    //console.log( "newurl_link1: ", newurl_link);

    

    //console.log(this.props.auth);
    
    //console.log("Full Link: ",window.location.href);

	  if(this.props.auth.isAuthenticated == false){
		  this.props.history.push(AppConstants.STAGRFP+'/');
	 }
    // this is needed, because InfiniteCalendar forces window scroll
    window.scrollTo(0, 0);
    fetch(AppConstants.API+'/reports/getalldashboardreports').then(response => response.json())
      .then(data => {
     // console.log("vendors data ", data.vendors_count);
      if(data.Status == "Success")
      {
        this.setState({ 
          vendors_count: data.vendors_count,
          customers_count: data.customers_count,
          companies_count: data.companies_count,
          rfprfi_count: data.rfprfi_count,
          //data: data
        });
      }         
    });

    //Comapny
    fetch(AppConstants.API+'/cms/aboutus/5d52ca27d906933546828c7b').then(response => response.json())
      .then(data => {
     //console.log("About us ", data.aboutus.text());
     //console.log(data);
      if(data.Status == "Success")
      {
        this.setState({ 
          companytitle: data.aboutus.title,
          companydescription: data.aboutus.description
        });
      }       
    });

    //Vendor
    fetch(AppConstants.API+'/cms/aboutus/5d52ca56d906933546828c7c').then(response => response.json())
      .then(data => {
     //console.log("About us ", data.aboutus.text());
     //console.log(data);
      if(data.Status == "Success")
      {
        this.setState({ 
          vendortitle: data.aboutus.title,
          vendordescription: data.aboutus.description
        });
      }       
    });

    //Partners
    fetch(AppConstants.API+'/cms/aboutus/5d52c9fed906933546828c7a').then(response => response.json())
      .then(data => {
     //console.log("About us ", data.aboutus.text());
     //console.log(data);
      if(data.Status == "Success")
      {
        this.setState({ 
          partnertitle: data.aboutus.title,
          partnerdescription: data.aboutus.description
        });
      }       
    });

    //How it works
    fetch(AppConstants.API+'/cms/howitworks/5cab606f44dd93553fa5f0ce').then(response => response.json())
      .then(data => {
     //console.log("About us ", data.aboutus.text());
      if(data.Status == "Success")
      {
        this.setState({ 
          howitworkstitle: data.howitworks.title,
          howitworksdescription: data.howitworks.description
        });
      }       
    });

    //About
    fetch(AppConstants.API+'/cms/aboutus/5c6257244498f815b5538eff').then(response => response.json())
      .then(data => {
     //console.log("About us ", data.aboutus.text());
     //console.log(data);
      if(data.Status == "Success")
      {
        this.setState({ 
          title: data.aboutus.title,
          description: data.aboutus.description
        });
      }       
    });

    



    if(localStorage.jwtToken != undefined && this.props.auth.isAuthenticated == true &&  this.props.auth.user.type =='vendor') {
      this.props.getVendorProfile(this.props.auth.user._id)
    }
    if(localStorage.jwtToken != undefined && this.props.auth.isAuthenticated == true && this.props.auth.user.type =='company'){
     // console.log(this.props.auth);
      this.props.getProfile(this.props.auth.user._id)
    }  
    
  }
  
  componentWillReceiveProps(nextProps) { 
    if(nextProps.auth.isAuthenticated == true && nextProps.auth.user._id !=''  && nextProps.auth.user.type=='vendor') {
      //console.log(nextProps.auth);
       //this.props.history.push('/dashboard');
      }
      if(nextProps.auth.isAuthenticated == true && nextProps.auth.user._id !=''  && nextProps.auth.user.type=='company')
      {
        //console.log(nextProps.auth);
        //this.props.history.push('/companydashboard');
      }     
    }
  render() {
    const articleContent = this.state.description
    return (
      <React.Fragment>
        <div id="home">
      { <Slider/> }
      </div>
      <div className="about-block m-120"  id="works">
      <Container>
      <h2 className="color-yellow">How It Works</h2>
      <Row> 
          <Col md="6 left-block">
              <h4>{this.state.companytitle}</h4>
              {/* <h4>Company</h4> */}
              {/* <p>Are you interested in posting opportunities and Requests for Information/Requests for Proposal on the BidLink portal? </p> */}
              <div dangerouslySetInnerHTML={{__html: this.state.companydescription}}></div>
              <div>
                <iframe width="500" height="300" src="https://www.youtube.com/embed/Tylh4i-CzIM?rel=0" frameborder="0" allowFullScreen></iframe>
              </div>
              {/* <h4>{this.state.vendortitle}</h4> */}
             
          </Col>
          <Col md="6 right-block">
          <h4>{this.state.vendortitle}</h4>
              {/* <p>Are you interested in listing your business in our free directory? Do you want to find new business opportunities and bid on projects? </p>               */}
              <div dangerouslySetInnerHTML={{__html: this.state.vendordescription}}></div>
              <div>
                <iframe width="500" height="300" src="https://www.youtube.com/embed/ACoOcGGPhJ4?rel=0" frameborder="0" allowFullScreen></iframe>
              </div>

            {/* <div className="about-images">
              <div className="about-img image-fill"><img src={require('../images/howitworks_img1.jpg')} /></div>
              <div className="about-img1 image-fill"><img src={require('../images/howitworks_img2.jpg')} /></div>
            </div> */}
          </Col>
        </Row>
      </Container>
    </div>
    <div className="stats-block">
      <Container>
        <Row>
          <Col md="4">
            <div className="stats-inner">
                <div className="stats-icon">
                  <i class="fa fa-building" aria-hidden="true"></i>
                </div>
                <div className="stats-content">
                  <h2>{this.state.customers_count}</h2>
                  <h6>Companies Registered</h6>
                </div>
            </div>
          </Col>
          <Col md="4">
            <div className="stats-inner">
                <div className="stats-icon">
                <i class="fa fa-users" aria-hidden="true"></i>
                </div>
                <div className="stats-content">
                  <h2>{this.state.vendors_count}</h2>
                  <h6>Vendors Registered</h6>
                </div>
            </div>
          </Col>
          <Col md="4">
            <div className="stats-inner">
                <div className="stats-icon">
                <i class="fa fa-clock-o" aria-hidden="true"></i>
                </div>
                <div className="stats-content">
                  <h2>{this.state.rfprfi_count}</h2>
                  <h6>Opportunities Posted</h6>
                </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
    <div className="partners-block  m-120"  id="partners">
      <h2 className="color-yellow text-center">{this.state.partnertitle}</h2> 
      <Container>
      <div class="content-block" dangerouslySetInnerHTML={{__html: this.state.partnerdescription}}></div>
        {/* <p>BidLink is a collaborative initiative to promote the growth and sustainability of minority-owned, women-owned and locally-owned businesses in the Greater Memphis region by connecting them with contracting opportunities that can take their business to the next level. Our goal is to strengthen MWBEs and LOSBs in Memphis and Shelby County by providing more opportunities to our thriving local business community.</p>
        <p>BidLink is a proud partnership of the Greater Memphis Chamber Chairman’s Circle, the City of Memphis, Shelby County government and the The Mid-South Minority Business Continuum.</p> */}
      <Row className="justify-content-md-center">
        <Col md="4">
          <div className="partner-img"><img src={require('../images/partner_img1.png')} />  </div>           
        </Col>
        <Col md="4">
        <div className="partner-img"><img src={require('../images/partner_img2.png')} />  </div> 
        </Col>
        <Col md="4">
        <div className="partner-img"><img src={require('../images/partner_img3.png')} />  </div> 
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col md="10">
        <div className="partner-img partner-img1"><img src={require('../images/partner_img4.png')} />  </div>
        </Col>
        
      </Row> 
      </Container>              
    </div>
    

    <div className="about-block m-120" id="about">
      <Container>
        <Row> 
          <Col md="6">
            <div className="about-images">
              <h2 className="color-yellow">About Us</h2>
              <div className="about-img image-fill"><img src={require('../images/about_img1.jpg')} /></div>
              <div className="about-img1 image-fill"><img src={require('../images/about_img2.jpg')} /></div>
            </div>
          </Col>
          <Col md="6">
              <h2 className="color-yellow displ">{this.state.title}</h2>
              <div dangerouslySetInnerHTML={{__html: this.state.description}}></div>
              {/* <p>BidLink is an informationally, open source portal that connects local business owners with business opportunities from companies across the Greater Memphis region. This free resource is designed to help connect minority-owned, women-owned and locally owned businesses with contracting opportunities and information that can take their business to the next level.</p>           
              <p>BidLink is a portal that connects local business owners with bid opportunity information from hundreds of companies across the Greater Memphis region. The BidLink portal includes:</p>
              <ul>
                <li>A searchable list of opportunities by business name and by industry.</li>
                <li>A detailed summary for each opportunity.</li>
                <li>Contact information and/or link to the website (if applicable) of the originating entity.</li>
                <li>Email notifications associated with your company’s NAICs code when new opportunities are posted (as available).</li>
                <li>Opportunity to follow specific companies and receive email notifications as new opportunities are posted for those companies.</li>
                </ul> */}
                {/* <p>BidLink is a portal that connects local business owners with bid opportunity information from hundreds of companies across the Greater Memphis region.The BidLink portal includes:

                  * A searchable list of opportunities by business name and by industry.<br />

                  * A detailed summary for each opportunity <br />

                  * Contact information and/or link to the website (if applicable) of the originating entity <br />

                  * Email notifications associated with your company’s NAICs code when new opportunities are posted (as available) <br />

                  * Opportunity to follow specific companies and receive email notifications as new opportunities are posted for those companies <br />

                  BidLink is a collaborative initiative to promote the growth and sustainability of minority-owned, women-owned and locally-owned businesses in the Greater Memphis region by connecting them with contracting opportunities that can take their business to the next level. Our goal is to strengthen MWBEs and LOSBs in Memphis and Shelby County by providing more opportunities to our thriving local business community. <br />

                  BidLink is a proud partnership of the Greater Memphis Chamber Chairman’s Circle, the City of Memphis, Shelby County government and the The Mid-South Minority Business Continuum.
                          </p> */}
              {/*<a href="#" className="btn btn-sm">Read More<i className="fa fa-angle-right" aria-hidden="true"></i></a>*/}
          </Col>
        </Row>
      </Container>
    </div>

     </React.Fragment>
  );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth
})
//export default Header;
export default connect(mapStateToProps, {getProfile,getVendorProfile,logoutUser})(withRouter(home));

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Line} from 'react-chartjs-2';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import { Container, Row, Col, Form, FormGroup, Label, Input,  Button, Modal, ModalHeader, ModalBody, ModalFooter,Card,CardHeader,CardBody } from 'reactstrap';
import AppConstants from '../AppConstants';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DatePicker from "react-datepicker";
import {logoutUser,getProfile,getVendorProfile,handlevendordashboard} from '../actions/authentication';
import {ReactTitle} from 'react-meta-tags';
import swal from 'sweetalert';
import PropTypes from 'prop-types';
//export default class  dashboard extends Component{
class dashboard extends React.Component {
    constructor(props) {
        //console.log("dashboard",props);
        super(props);
        this.state = {      
          "rfprfiprofilesdata": [],
          errors: {},
          errMsg:false,
          successMsg:false,
          "vendordashboarddata":[],
          "likesrfprfiscount":0,
          "appliedrfprfiscount":0,
          "subscribeddata":0,
          "rfprfi_count":0,
          "rfprfiactivecount":0,
          "notappliedvendorscount":0,
          "wondata":0,
          "notwon":0,
          modal: false,
          rfprfi_id:'',
          company_id:'',
          name_ofrfp:'',          
          genLineMAS:{},
          genLineDT:{},
          child_id:this.props.auth.user._id,
          rfprfiprofilesdatacount:0,
          feedback:'',
          placeholderSD:'MM/DD/YYYY',
          placeholderTD:'MM/DD/YYYY',
          type:'',
          start_date:null,
          end_date:null,
          startDate:'',
          endDate:'',
          type_error:'',
          type_error2:'',
          range_date:'',
          related_rfps_all_dates:[],
          lineone_values:[],
          applied_rfp_all_dates:[],
          lineone_values1:[],
          liked_rfp_values:[],
          lineone_values2:[],
          applyanimate: "applyoldClass",
          likedanimate: "likeoldClass",
          allanimate: "alloldClass",
          likeddashboarddata:0,
          applieddashboarddata:0,
          wondashboarddata:0,
          notwondashboarddata:0

      };
      this.toggle = this.toggle.bind(this);
      this.handleChange = this.handleChange.bind(this);      

      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleStartdateChange = this.handleStartdateChange.bind(this);
      this.handleEndDateChange = this.handleEndDateChange.bind(this); 
    }

    handleInputChange(e) {
        //console.log(e);
        this.setState({
            [e.target.name]: e.target.value
        });
        var Type = e.target.value;
       //console.log(Type);
      //console.log(this.state.start_date);
      //console.log(this.state.end_date);
    
    
      this.getSDate(Type);
      if(Type == "Custom" && this.state.start_date !=null && this.state.end_date!=null) {
          this.getDPReport(Type,this.state.start_date,this.state.end_date);
          //this.getMASReport(Type,this.state.start_date,this.state.end_date);
      }
      else if(Type == "ThisWeek" || Type == "Monthly" ||  Type == "Yearly") {
        this.getDPReport(Type,this.state.start_date,this.state.end_date);
        //this.getMASReport(Type,this.state.start_date,this.state.end_date);
      }
    }

    handleStartdateChange (date) {
        //console.log('dfdsfdsfds');
        this.setState({
          start_date: date
          //end_date :date
        });
        //console.log("start_date",date);
        //console.log(this.state.start_date);
        //console.log(this.state.end_date);
        if(date && date!=null && this.state.end_date != null){
          this.getDPReport("Custom",date,this.state.end_date);
          //this.getMASReport("Custom",date,this.state.end_date);
          this.state.type_error='';
          this.state.type_error2='';
        }
        if(date && date==null)
        this.state.type_error = "Please select start date";
        else if(date && date!=null) {
        this.state.type_error = "";
        this.state.type = "Custom";
        }
      }
      getSDate(type) {
        let errors = {};
        let formIsValid = true;
      
        //console.log(this.state.start_date);
       // console.log(this.state.end_date);
            
        if(type == "Custom" && this.state.start_date == null && this.state.end_date == null){
            formIsValid = false;
            this.state.type = "Custom";
            this.state.type_error = "Please select start date";
            this.state.type_error2="Please select end date";
            this.state.placeholderSD = "MM/DD/YYYY";
            this.state.placeholderTD = "MM/DD/YYYY";
            return false;
        } else {
          this.state.type_error='';
          this.state.type_error2='';
          this.state.start_date=null;
          this.state.end_date=null;
        }
      };
      handleEndDateChange(date1) {
        this.setState({
          //start_date: date,
          end_date :date1
          
        });
        //console.log("end_date",date1);
        //console.log("start_date",this.state.start_date);
       // console.log(date1);
        if(date1 && date1!=null && this.state.start_date != null){
          this.getDPReport("Custom",this.state.start_date,date1);
          //this.getMASReport("Custom",this.state.start_date,date1);
          this.state.type_error='';
          this.state.type_error2='';
        }
        
        if(date1 && date1==null)
        this.state.type_error2="Please select end date";
        else if(date1 && date1!=null){
        this.state.type_error2 = "";
        this.state.type = "Custom";
        }
      
        if(new Date(date1).toISOString() >= new Date(this.state.start_date).toISOString()) {
          this.getDPReport("Custom",this.state.start_date,date1);
          //this.getMASReport("Custom",this.state.start_date,date1);
        } else {
          this.state.type_error2="To date should be greater than From date";
        }     
        
      }

      getDPReport(Type,custom_date,end_date){
          
     //Vendor Naic code related RFPs  
      var UserID = this.props.auth.user._id;
      fetch(AppConstants.API+'/reports/graphreportforvendor/'+ UserID +'/'+Type+'/'+custom_date+'/'+end_date).then(response => response.json())
        .then(data => { 
          //console.log(data);
          if(data.status == "success")
          {
            fetch(AppConstants.API+'/reports/graphreportforappliedrfpvendor/'+ UserID +'/'+Type+'/'+custom_date+'/'+end_date).then(response => response.json())
            .then(data1 => { 
              //console.log(data);
              if(data1.status == "success")
              {
                //console.log("Raj", data1);   

                fetch(AppConstants.API+'/reports/graphreportforlikedrfpvendor/'+ UserID +'/'+Type+'/'+custom_date+'/'+end_date).then(response => response.json())
                .then(data2 => {
                    if(data2.status == "success")
                    {
                        var genLineDTv={
                            labels: data.related_rfps_all_dates,
                            datasets: [
                              {
                                label: 'Total',
                                backgroundColor: '#d3aa55',
                                borderColor: '#d3aa55',
                                borderWidth: 1,
                                data: data.related_rfps_values,
                                fill:false
                              },
                              {
                                label: 'Applied',
                                backgroundColor: '#449d44',
                                borderColor: '#449d44',
                                borderWidth: 1,
                                data: data1.applied_rfp_values,
                                fill:false
                              },
                              {
                                label: 'Liked',
                                backgroundColor: '#56607a',
                                borderColor: '#56607a',
                                borderWidth: 1,
                                data: data2.liked_rfp_values,
                                fill:false
                               }
                            ],
                          };    
                          var sd = data.related_rfps_all_dates[0];
                          var ed = data.related_rfps_all_dates[data.related_rfps_all_dates.length-1];          
                          this.setState({ all_dates: data.related_rfps_all_dates,lineone_values:data.related_rfps_values,lineone_values1:data1.applied_rfp_values,lineone_values2:data1.liked_rfp_values,genLineDT:genLineDTv,type:Type,placeholderSD:sd,placeholderTD:ed }); 
                    }
                    else
                    {
                        this.setState({ liked_rfp_all_dates: [] });
                    }
                });
                
              }
              else
              {
                this.setState({ applied_rfp_all_dates: [] });
              }
            });     
          }
          else
          {
            this.setState({ related_rfps_all_dates: [] });
          }
        });
      }

    toggle() {
        this.setState({
          modal: !this.state.modal,
          errors: false,
          feedback:''
        });
      }
    /** Validations */
  handleValidation(){
      console.log(this.state.feedback);
    let errors = {};
    let formIsValid = true;
    if(!this.state.feedback || this.state.feedback == undefined){
        formIsValid = false;
        errors["feedback"] = "Please give feedback";
    }    
    this.setState({errors: errors});
        return formIsValid;
    }

    handleSubmit = event => {
        event.preventDefault();
        if(this.handleValidation()){
         const feedbackdetails = {
            rfprfi_id:this.state.rfprfi_id,
            vendor_id: this.props.auth.user._id,
            feedback:this.state.feedback
         }
         //console.log(feedbackdetails);
             
         axios.post(AppConstants.API+'/vendorfeedback/feedback',feedbackdetails)
         .then(res => {
             if(res.data.Status == "Success"){                                        
                setTimeout((e) => {         
                    this.setState({
                        modal: !this.state.modal,
                        errors: false,
                        feedback:'',
                        errMsg:'',
                        successMsg:''
                    });
                  }, 2000)
                                 
                this.setState({
                    successMsg: "Thank you for giving feedback"
                });
                fetch(AppConstants.API+'/rfprfiprofiles/getallrfprfisrelatedtovendornaics/'+this.props.auth.user._id+'/all').then(response => response.json())
                .then(data => {
                    //console.log(data);
                    if(data.Status == "Success")
                    {
                        this.setState({ 
                            rfprfiprofilesdata: data.rfprfiprofilesdata,
                            rfprfiprofilesdatacount:data.rfprfiprofilesdata.length
                        });
                    }
                    else
                    {
                        this.setState({ rfprfiprofilesdata: [],rfprfiprofilesdatacount:0});
                    }    
                });  
             }
             else
             {
                 this.setState({
                     errMsg: "Please try again"
                 });
             }
         });
             
        }
        
    }


    componentDidMount() {
        this.props.handlevendordashboard("vendordashboard");
        window.scrollTo(0, 0)
        //Dashboard Data
        fetch(AppConstants.API+'/vendors/getvendordashboarddata/'+this.props.auth.user._id).then(response => response.json())
         .then(data => {
             //console.log(this.props.auth.user._id);
          if(data.Status == "Success")
          {
            this.setState({

                //likesrfprfiscount: data.vendordashboarddata.likesrfprfiscount,
                //appliedrfprfiscount: data.vendordashboarddata.appliedrfprfiscount,
                subscribeddata: data.vendordashboarddata.subscribeddata,
               // wondata:data.vendordashboarddata.wondata,
               // notwon:data.vendordashboarddata.notwon
            
            });
          }
          else
          {
            this.setState({ rfprfiprofilesdata: []});
          }    
        });
        fetch(AppConstants.API+'/vendors/getvendorlikedrfps/'+this.props.auth.user._id).then(response => response.json())
         //.then(data => {
        //fetch(AppConstants.API+'/reports/likedrfprfisreport/'+this.props.auth.user._id).then(response => response.json())
        .then(data => {
          if(data.Status == "Success")
          {
            this.setState({
                likeddashboarddata: data.likeddashboarddata,
                //likeddashboarddata: data.likesreport.length,
            });
          }
          else
          {
            this.setState({ likeddashboarddata: 0});
          }    
        });
        fetch(AppConstants.API+'/vendors/getvendorappliedrfps/'+this.props.auth.user._id).then(response => response.json())
         .then(data => {
          if(data.Status == "Success")
          {
            this.setState({
                applieddashboarddata: data.applieddashboarddata,
            });
          }
          else
          {
            this.setState({ applieddashboarddata: 0});
          }    
        });
        fetch(AppConstants.API+'/vendors/getvendorwonrfps/'+this.props.auth.user._id).then(response => response.json())
         .then(data => {
             //console.log(data.wondashboarddata)
          if(data.Status == "Success")
          {
            this.setState({
                wondashboarddata: data.wondashboarddata,
            });
          }
          else
          {
            this.setState({ wondashboarddata: 0});
          }    
        });
        fetch(AppConstants.API+'/vendors/getvendornotwonrfps/'+this.props.auth.user._id).then(response => response.json())
         .then(data => {
          if(data.Status == "Success")
          {
            this.setState({
                notwondashboarddata: data.notwondashboarddata,
            });
          }
          else
          {
            this.setState({ notwondashboarddata: 0});
          }    
        });
        fetch(AppConstants.API+'/reports/getalldashboardreports').then(response => response.json())
         .then(data => {             
          if(data.Status == "Success")
          {
            this.setState({
                rfprfi_count: data.rfprfi_count            
            });
          }            
        });
        // fetch(AppConstants.API+'/vendors/getallactiverfprfiscount').then(response => response.json())
        //  .then(data => {             
        //   if(data.Status == "Success")
        //   {
        //     this.setState({
        //         rfprfiactivecount: data.rfprfiactivecount            
        //     });
        //   }            
        // });

        fetch(AppConstants.API+'/reports/getactiverfprfis').then(response => response.json())
         .then(data => {             
          if(data.Status == "Success")
          {
            this.setState({
                rfprfiactivecount: data.activedata           
            });
          }            
        });

        fetch(AppConstants.API+'/vendors/notappliedvendorscount/'+this.props.auth.user._id).then(response => response.json())
         .then(data => {             
          if(data.Status == "Success")
          {
            this.setState({
                notappliedvendorscount: data.notappliedvendorscount            
            });
          }            
        });
        //fetch(AppConstants.API+'/rfprfiprofiles/getallrfprfiprofiles').then(response => response.json())
         // .then(data => {
         //console.log(data);
        fetch(AppConstants.API+'/rfprfiprofiles/getallrfprfisrelatedtovendornaics/'+this.props.auth.user._id+'/all').then(response => response.json())
         .then(data => {
             //console.log(data);
          if(data.Status == "Success")
          {
            this.setState({ 
                rfprfiprofilesdata: data.rfprfiprofilesdata,
                rfprfiprofilesdatacount:data.rfprfiprofilesdata.length,allanimate: "allnewClass"
             });
          }
          else
          {
            this.setState({ rfprfiprofilesdata: [],rfprfiprofilesdatacount:0});
          }    
        });       

        this.getDPReport("ThisWeek",null,null);

    }

    apply = params => e => {
        e.preventDefault();
        fetch(AppConstants.API+'/rfprfiprofiles/getallrfprfisrelatedtovendornaics/'+this.props.auth.user._id+'/apply').then(response => response.json())
            .then(data => {
            //console.log(data);
            if(data.Status == "Success")
            {
                this.setState({ rfprfiprofilesdata: data.rfprfiprofilesdata ,applyanimate:"applynewClass",likedanimate:'likeoldClass',allanimate: "alloldClass"});
            }
            else
            {
                this.setState({ rfprfiprofilesdata: [],applyanimate:"applynewClass",likedanimate:'likeoldClass',allanimate: "alloldClass"});
            }    
        });
    }

    Allrelateddata = params => e => {
        e.preventDefault();
        fetch(AppConstants.API+'/rfprfiprofiles/getallrfprfisrelatedtovendornaics/'+this.props.auth.user._id+'/all').then(response => response.json())
            .then(data => {
            //console.log(data);
            if(data.Status == "Success")
            {
                this.setState({ rfprfiprofilesdata: data.rfprfiprofilesdata,applyanimate:'applyoldClass',likedanimate:'likeoldClass',allanimate: "allnewClass"});
            }
            else
            {
                this.setState({ rfprfiprofilesdata: [],applyanimate:'applyoldClass',likedanimate:'likeoldClass',allanimate: "allnewClass"});
            }    
        });
    }


    liked = params => e => {
        e.preventDefault();
        fetch(AppConstants.API+'/rfprfiprofiles/getallrfprfisrelatedtovendornaics/'+this.props.auth.user._id+'/liked').then(response => response.json())
            .then(data => {
            //console.log(data);
            if(data.Status == "Success")
            {
                this.setState({ rfprfiprofilesdata: data.rfprfiprofilesdata,likedanimate:"likenewClass",applyanimate:"applyoldClass",allanimate: "alloldClass" });
            }
            else
            {
                this.setState({ rfprfiprofilesdata: [], likedanimate:"likenewClass",applyanimate:"applyoldClass",allanimate: "alloldClass" });
            }    
        });
    }
    applyRfp = (rfp_id,company_id) => e => {
        e.preventDefault();
        swal({
            title: "Are you sure?",
            text: "You want to Apply for this Opportunity?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
            })
            .then((willDelete) => {
            if (willDelete) {
            swal("Opportunity has been Applied!", {
            icon: "success",
            });
             
            const appliedrfp = {
                vendor_id:this.props.auth.user._id,
                rfprfi_id:rfp_id,
                company_id:company_id
            }             
                axios.post(AppConstants.API+'/rfprfiinterestes/interest',appliedrfp)
                    .then(res => {
                        if(res.data.Status == "Success"){                                        
                            fetch(AppConstants.API+'/rfprfiprofiles/getallrfprfisrelatedtovendornaics/'+this.props.auth.user._id+'/all').then(response => response.json())
                                .then(data => {
                                //console.log(data);
                                if(data.Status == "Success")
                                {
                                    this.setState({ rfprfiprofilesdata: data.rfprfiprofilesdata });

                                    fetch(AppConstants.API+'/vendors/getvendordashboarddata/'+this.props.auth.user._id).then(response => response.json())
                                    .then(data => {
                                        //console.log(data.vendordashboarddata.likesrfprfiscount);
                                        if(data.Status == "Success")
                                        {
                                            this.setState({

                                               // likesrfprfiscount: data.vendordashboarddata.likesrfprfiscount,
                                               // appliedrfprfiscount: data.vendordashboarddata.appliedrfprfiscount,
                                                subscribeddata: data.vendordashboarddata.subscribeddata,
                                               // wondata:data.vendordashboarddata.wondata,
                                               // notwon:data.vendordashboarddata.notwon
                                            
                                            });
                                        }
                                        else
                                        {
                                            this.setState({ rfprfiprofilesdata: []});
                                        }    
                                    });
                                    fetch(AppConstants.API+'/vendors/getvendorlikedrfps/'+this.props.auth.user._id).then(response => response.json())
                                    .then(data => {
                                        //console.log(data.vendordashboarddata.likesrfprfiscount);
                                        if(data.Status == "Success")
                                        {
                                            this.setState({
                                                likeddashboarddata: data.likeddashboarddata,
                                            });
                                        }
                                        else
                                        {
                                            this.setState({ likeddashboarddata: 0});
                                        }    
                                    });

                                    fetch(AppConstants.API+'/vendors/getvendorappliedrfps/'+this.props.auth.user._id).then(response => response.json())
                                    .then(data => {
                                    if(data.Status == "Success")
                                        {
                                            this.setState({
                                                applieddashboarddata: data.applieddashboarddata,
                                            });
                                        }
                                        else
                                        {
                                            this.setState({ applieddashboarddata: 0});
                                        }    
                                    });
                                    fetch(AppConstants.API+'/vendors/getvendorwonrfps/'+this.props.auth.user._id).then(response => response.json())
                                    .then(data => {
                                    if(data.Status == "Success")
                                        {
                                            this.setState({
                                                wondashboarddata: data.wondashboarddata,
                                            });
                                        }
                                        else
                                        {
                                            this.setState({ wondashboarddata: 0});
                                        }    
                                    });
                                    fetch(AppConstants.API+'/vendors/getvendornotwonrfps/'+this.props.auth.user._id).then(response => response.json())
                                    .then(data => {
                                        if(data.Status == "Success")
                                        {
                                            this.setState({
                                                notwondashboarddata: data.notwondashboarddata,
                                            });
                                        }
                                        else
                                        {
                                            this.setState({ notwondashboarddata: 0});
                                        }    
                                    });

                                    fetch(AppConstants.API+'/vendors/notappliedvendorscount/'+this.props.auth.user._id).then(response => response.json())
                                    .then(data => {             
                                    if(data.Status == "Success")
                                        {
                                            this.setState({
                                                notappliedvendorscount: data.notappliedvendorscount            
                                            });
                                        }            
                                    });
                                }
                                else
                                {
                                    this.setState({ rfprfiprofilesdata: []});
                                }    
                            });
                        }
                        else
                        {
                            this.setState({
                                errMsg: res.data.msg
                            });
                        }
                    });             
                }
            });

    }
    likeRfp = (rfp_id,company_id) => e => {
        e.preventDefault();
        const vendorlikerfp = {
            vendor_id:this.props.auth.user._id,
            rfprfi_id:rfp_id,
            company_id:company_id
          }
          axios.post(AppConstants.API+'/rfprfilikes/like',vendorlikerfp)
          .then(res => {
            if(res.data.Status == "Success"){                                        
                fetch(AppConstants.API+'/rfprfiprofiles/getallrfprfisrelatedtovendornaics/'+this.props.auth.user._id+'/all').then(response => response.json())
                    .then(data => {
                    //console.log(data);
                    if(data.Status == "Success")
                    {
                        this.setState({ rfprfiprofilesdata: data.rfprfiprofilesdata });
                        fetch(AppConstants.API+'/vendors/getvendordashboarddata/'+this.props.auth.user._id).then(response => response.json())
                        .then(data => {
                            //console.log(data.vendordashboarddata.likesrfprfiscount);
                            if(data.Status == "Success")
                            {
                                this.setState({

                                   // likesrfprfiscount: data.vendordashboarddata.likesrfprfiscount,
                                   // appliedrfprfiscount: data.vendordashboarddata.appliedrfprfiscount,
                                    subscribeddata: data.vendordashboarddata.subscribeddata,
                                   // wondata:data.vendordashboarddata.wondata,
                                   // notwon:data.vendordashboarddata.notwon
                                
                                });
                            }
                            else
                            {
                                this.setState({ rfprfiprofilesdata: []});
                            }    
                        });
                        fetch(AppConstants.API+'/vendors/getvendorlikedrfps/'+this.props.auth.user._id).then(response => response.json())
                        .then(data => {
                            //console.log(data.vendordashboarddata.likesrfprfiscount);
                            if(data.Status == "Success")
                            {
                                this.setState({
                                    likeddashboarddata: data.likeddashboarddata,
                                });
                            }
                            else
                            {
                                this.setState({ likeddashboarddata: 0});
                            }    
                        });
                        fetch(AppConstants.API+'/vendors/getvendorappliedrfps/'+this.props.auth.user._id).then(response => response.json())
                        .then(data => {
                        if(data.Status == "Success")
                            {
                                this.setState({
                                    applieddashboarddata: data.applieddashboarddata,
                                });
                            }
                            else
                            {
                                this.setState({ applieddashboarddata: 0});
                            }    
                        });
                        fetch(AppConstants.API+'/vendors/getvendorwonrfps/'+this.props.auth.user._id).then(response => response.json())
                        .then(data => {
                        if(data.Status == "Success")
                            {
                                this.setState({
                                    wondashboarddata: data.wondashboarddata,
                                });
                            }
                            else
                            {
                                this.setState({ wondashboarddata: 0});
                            }    
                        });

                        fetch(AppConstants.API+'/vendors/getvendornotwonrfps/'+this.props.auth.user._id).then(response => response.json())
                        .then(data => {
                        if(data.Status == "Success")
                            {
                                this.setState({
                                    notwondashboarddata: data.notwondashboarddata,
                                });
                            }
                            else
                            {
                                this.setState({ notwondashboarddata: 0});
                            }    
                        });

                    }
                    else
                    {
                        this.setState({ rfprfiprofilesdata: []});
                    }    
                });
              }
              else
              {
                this.setState({
                    errMsg: res.data.msg
                  });
              }
          });
    }
    unlikelikeRfp = (rfp_id,company_id) => e => {
        e.preventDefault();
        const vendorlikerfp = {
            vendor_id:this.props.auth.user._id,
            rfprfi_id:rfp_id,
            company_id:company_id
          }
          axios.post(AppConstants.API+'/rfprfilikes/unlike',vendorlikerfp)
          .then(res => {
            if(res.data.Status == "Success"){                                        
                fetch(AppConstants.API+'/rfprfiprofiles/getallrfprfisrelatedtovendornaics/'+this.props.auth.user._id+'/all').then(response => response.json())
                    .then(data => {
                    //console.log(data);
                    if(data.Status == "Success")
                    {
                        this.setState({ rfprfiprofilesdata: data.rfprfiprofilesdata });
                        fetch(AppConstants.API+'/vendors/getvendordashboarddata/'+this.props.auth.user._id).then(response => response.json())
                        .then(data => {
                            //console.log(data.vendordashboarddata.likesrfprfiscount);
                            if(data.Status == "Success")
                            {
                                this.setState({

                                    //likesrfprfiscount: data.vendordashboarddata.likesrfprfiscount,
                                    //appliedrfprfiscount: data.vendordashboarddata.appliedrfprfiscount,
                                    subscribeddata: data.vendordashboarddata.subscribeddata,
                                    //wondata:data.vendordashboarddata.wondata,
                                   // notwon:data.vendordashboarddata.notwon
                                
                                });
                            }
                            else
                            {
                                this.setState({ rfprfiprofilesdata: []});
                            }    
                        });
                        fetch(AppConstants.API+'/vendors/getvendorlikedrfps/'+this.props.auth.user._id).then(response => response.json())
                        .then(data => {
                        if(data.Status == "Success")
                            {
                                this.setState({
                                    likeddashboarddata: data.likeddashboarddata,
                                });
                            }
                            else
                            {
                                this.setState({ likeddashboarddata: 0});
                            }    
                        });
                        fetch(AppConstants.API+'/vendors/getvendorappliedrfps/'+this.props.auth.user._id).then(response => response.json())
                        .then(data => {
                        if(data.Status == "Success")
                            {
                                this.setState({
                                    applieddashboarddata: data.applieddashboarddata,
                                });
                            }
                            else
                            {
                                this.setState({ applieddashboarddata: 0});
                            }    
                        });
                        fetch(AppConstants.API+'/vendors/getvendorwonrfps/'+this.props.auth.user._id).then(response => response.json())
                        .then(data => {
                        if(data.Status == "Success")
                            {
                                this.setState({
                                    wondashboarddata: data.wondashboarddata,
                                });
                            }
                            else
                            {
                                this.setState({ wondashboarddata: 0});
                            }    
                        });
                        fetch(AppConstants.API+'/vendors/getvendornotwonrfps/'+this.props.auth.user._id).then(response => response.json())
                        .then(data => {
                        if(data.Status == "Success")
                            {
                                this.setState({
                                    notwondashboarddata: data.notwondashboarddata,
                                });
                            }
                            else
                            {
                                this.setState({ notwondashboarddata: 0});
                            }    
                        });
                    }
                    else
                    {
                        this.setState({ rfprfiprofilesdata: []});
                    }    
                });
              }
              else
              {
                this.setState({
                    errMsg: res.data.msg
                  });
              }
          });
    }

    Feedback (event,rfprfiid,vendorid,rfpname,feedback) {
        this.setState({
            modal: !this.state.modal,rfprfi_id:rfprfiid,vendor_id:vendorid,name_ofrfp:rfpname,feedback:feedback
          });
        event.preventDefault();
    };

    handleChange (e){
        this.setState({
           [e.target.name]: e.target.value
       }); 
    } 

    renderShowsTotal(start, to, total) {
        return (
          <p className="page-show">
          Showing rows { start } to { to } of { total }
          </p>
        );
      }
      actionBtn(cell,row) {
          //console.log(row.rfp_id);
          if(row.likestatus == 1 ){
            if(row.appliedstatus == 1){
                if(row.wonstatus == 'won'){
                    if(row.feedback && row.feedback != ''){
                        return  <div className="action-block"><Link to={`${AppConstants.STAGRFP}${"/viewrfpinfo/"}${row.rfp_id}`} className="view-icon" title="View Opportunity Details"><i class="fa fa-eye" aria-hidden="true"></i></Link>           
                        <a href="javascript:void(0);" title="Won Opportunity" className="applied"></a>
                        <a href="javascript:void(0);" onClick={ this.unlikelikeRfp(row.rfp_id,row.company_id) } className="applied liked" title="Opportunity Liked" ></a> 
                        <a href="javascript:void(0);" className="view-icon feedback-taken" title="Feedback Added"  onClick={(e) =>{this.Feedback(e, row.rfp_id,this.props.auth.user._id,row.name_ofrfp,row.feedback)}} ><i class="fa fa-comments" aria-hidden="true"></i></a></div>;
                    }
                    else
                    {
                        return  <div className="action-block"><Link to={`${AppConstants.STAGRFP}${"/viewrfpinfo/"}${row.rfp_id}`} className="view-icon" title="View Opportunity Details"><i class="fa fa-eye" aria-hidden="true"></i></Link>           
                        <a href="javascript:void(0);" title="Won Opportunity" className="applied"></a>
                        <a href="javascript:void(0);" onClick={ this.unlikelikeRfp(row.rfp_id,row.company_id) } className="applied liked" title="Opportunity Liked" ></a> 
                        <a href="javascript:void(0);" className="view-icon feedback" title="No Feedback"  onClick={(e) =>{this.Feedback(e, row.rfp_id,this.props.auth.user._id,row.name_ofrfp,row.feedback)}} ><i class="fa fa-comments" aria-hidden="true"></i></a></div>;
                    }
                    
                }
                else
                {
                    if(row.feedback && row.feedback != ''){
                        return  <div className="action-block"><Link to={`${AppConstants.STAGRFP}${"/viewrfpinfo/"}${row.rfp_id}`} className="view-icon" title="View Opportunity Details"><i class="fa fa-eye" aria-hidden="true"></i></Link>
                        <a href="javascript:void(0);" title="Applied Opportunity" className="applied"></a> 
                        <a href="javascript:void(0);" onClick={ this.unlikelikeRfp(row.rfp_id,row.company_id) } className="applied liked" title="Opportunity Liked" ></a>
                        <a href="javascript:void(0);" className="view-icon feedback-taken" title="Feedback Added"  onClick={(e) =>{this.Feedback(e, row.rfp_id,this.props.auth.user._id,row.name_ofrfp,row.feedback)}} ><i class="fa fa-comments" aria-hidden="true"></i></a></div>;
                    }
                    else
                    {
                        return  <div className="action-block"><Link to={`${AppConstants.STAGRFP}${"/viewrfpinfo/"}${row.rfp_id}`} className="view-icon" title="View Opportunity Details"><i class="fa fa-eye" aria-hidden="true"></i></Link>
                        <a href="javascript:void(0);" title="Applied Opportunity" className="applied"></a> 
                        <a href="javascript:void(0);" onClick={ this.unlikelikeRfp(row.rfp_id,row.company_id) } className="applied liked" title="Opportunity Liked" ></a>
                        <a href="javascript:void(0);" className="view-icon feedback" title="No Feedback"  onClick={(e) =>{this.Feedback(e, row.rfp_id,this.props.auth.user._id,row.name_ofrfp,row.feedback)}} ><i class="fa fa-comments" aria-hidden="true"></i></a></div>;
                    }
                    
                }
               
            }
            else if(row.wonstatus == 'notwon'){
                if(row.feedback && row.feedback != ''){
                    return  <div className="action-block"><Link to={`${AppConstants.STAGRFP}${"/viewrfpinfo/"}${row.rfp_id}`} className="view-icon" title="View Opportunity Details"><i class="fa fa-eye" aria-hidden="true"></i></Link> 
                    <a href="javascript:void(0);"className="applied not-applied" title="Lost Opportunity"></a>
                    
                    <a href="javascript:void(0);" onClick={ this.unlikelikeRfp(row.rfp_id,row.company_id) } className="applied liked " title="Opportunity Liked" ></a> 
                    <a href="javascript:void(0);" className="view-icon feedback-taken" title="Feedback Added"  onClick={(e) =>{this.Feedback(e, row.rfp_id,this.props.auth.user._id,row.name_ofrfp,row.feedback)}} ><i class="fa fa-comments" aria-hidden="true"></i></a></div>; 
                }
                else
                {
                    return  <div className="action-block"><Link to={`${AppConstants.STAGRFP}${"/viewrfpinfo/"}${row.rfp_id}`} className="view-icon" title="View Opportunity Details"><i class="fa fa-eye" aria-hidden="true"></i></Link> 
                    <a href="javascript:void(0);"className="applied not-applied" title="Lost Opportunity"></a>
                    
                    <a href="javascript:void(0);" onClick={ this.unlikelikeRfp(row.rfp_id,row.company_id) } className="applied liked " title="Opportunity Liked" ></a> 
                    <a href="javascript:void(0);" className="view-icon feedback" title="No Feedback"  onClick={(e) =>{this.Feedback(e, row.rfp_id,this.props.auth.user._id,row.name_ofrfp,row.feedback)}} ><i class="fa fa-comments" aria-hidden="true"></i></a></div>; 
                }
                
            }
            else
            {
                if(row.feedback && row.feedback != ''){
                    return  <div className="action-block"><Link to={`${AppConstants.STAGRFP}${"/viewrfpinfo/"}${row.rfp_id}`} className="view-icon" title="View Opportunity Details"><i class="fa fa-eye" aria-hidden="true"></i></Link> 
                    <a href="javascript:void(0);" onClick={ this.applyRfp(row.rfp_id,row.company_id) } className="applied not-applied" title="Apply Opportunity"></a> 
                    
                    <a href="javascript:void(0);" onClick={ this.unlikelikeRfp(row.rfp_id,row.company_id) } className="applied liked" title="Opportunity Liked"></a> 
                    <a href="javascript:void(0);" className="view-icon feedback-taken" title="Feedback Added"  onClick={(e) =>{this.Feedback(e, row.rfp_id,this.props.auth.user._id,row.name_ofrfp,row.feedback)}} ><i class="fa fa-comments" aria-hidden="true"></i></a></div>;
                }
                else
                {
                    return  <div className="action-block"><Link to={`${AppConstants.STAGRFP}${"/viewrfpinfo/"}${row.rfp_id}`} className="view-icon" title="View Opportunity Details"><i class="fa fa-eye" aria-hidden="true"></i></Link> 
                    <a href="javascript:void(0);" onClick={ this.applyRfp(row.rfp_id,row.company_id) } className="applied not-applied" title="Apply Opportunity"></a> 
                    
                    <a href="javascript:void(0);" onClick={ this.unlikelikeRfp(row.rfp_id,row.company_id) } className="applied liked" title="Opportunity Liked"></a> 
                    <a href="javascript:void(0);" className="view-icon feedback" title="No Feedback"  onClick={(e) =>{this.Feedback(e, row.rfp_id,this.props.auth.user._id,row.name_ofrfp,row.feedback)}} ><i class="fa fa-comments" aria-hidden="true"></i></a></div>;
                }
                  
            }
              
            
          }
          else{

            if(row.appliedstatus == 1){
                if(row.wonstatus == 'won'){
                    if(row.feedback && row.feedback != ''){
                        return  <div className="action-block"><Link to={`${AppConstants.STAGRFP}${"/viewrfpinfo/"}${row.rfp_id}`} className="view-icon" title="View Opportunity Details"><i class="fa fa-eye" aria-hidden="true"></i></Link>
                        <a href="javascript:void(0);" title="Won Opportunity" className="applied"></a> 
                        <a href="javascript:void(0);" onClick={ this.likeRfp(row.rfp_id,row.company_id) } className="applied unliked" title="Like Opportunity"></a> 
                        <a href="javascript:void(0);" className="view-icon feedback-taken" title="Feedback Added"  onClick={(e) =>{this.Feedback(e, row.rfp_id,this.props.auth.user._id,row.name_ofrfp,row.feedback)}} ><i class="fa fa-comments" aria-hidden="true"></i></a></div>;
                    }
                    else
                    {
                        return  <div className="action-block"><Link to={`${AppConstants.STAGRFP}${"/viewrfpinfo/"}${row.rfp_id}`} className="view-icon" title="View Opportunity Details"><i class="fa fa-eye" aria-hidden="true"></i></Link>
                        <a href="javascript:void(0);" title="Won Opportunity" className="applied"></a> 
                        <a href="javascript:void(0);" onClick={ this.likeRfp(row.rfp_id,row.company_id) } className="applied unliked" title="Like Opportunity"></a> 
                        <a href="javascript:void(0);" className="view-icon feedback" title="No Feedback"  onClick={(e) =>{this.Feedback(e, row.rfp_id,this.props.auth.user._id,row.name_ofrfp,row.feedback)}} ><i class="fa fa-comments" aria-hidden="true"></i></a></div>;
                    }
                     
                }
                else
                {
                    if(row.feedback && row.feedback != ''){
                        return  <div className="action-block"><Link to={`${AppConstants.STAGRFP}${"/viewrfpinfo/"}${row.rfp_id}`} className="view-icon" title="View Opportunity Details"><i class="fa fa-eye" aria-hidden="true"></i></Link> 
                        <a href="javascript:void(0);" title="Applied Opportunity" className="applied"></a> 
                        <a href="javascript:void(0);" onClick={ this.likeRfp(row.rfp_id,row.company_id) } className="applied unliked" title="Like Opportunity"></a> 
                        <a href="javascript:void(0);" className="view-icon feedback-taken" title="Feedback Added"  onClick={(e) =>{this.Feedback(e, row.rfp_id,this.props.auth.user._id,row.name_ofrfp,row.feedback)}} ><i class="fa fa-comments" aria-hidden="true"></i></a></div>; 
                    }
                    else
                    {
                        return  <div className="action-block"><Link to={`${AppConstants.STAGRFP}${"/viewrfpinfo/"}${row.rfp_id}`} className="view-icon" title="View Opportunity Details"><i class="fa fa-eye" aria-hidden="true"></i></Link> 
                        <a href="javascript:void(0);" title="Applied Opportunity" className="applied"></a> 
                        <a href="javascript:void(0);" onClick={ this.likeRfp(row.rfp_id,row.company_id) } className="applied unliked" title="Like Opportunity"></a> 
                        <a href="javascript:void(0);" className="view-icon feedback" title="No Feedback"  onClick={(e) =>{this.Feedback(e, row.rfp_id,this.props.auth.user._id,row.name_ofrfp,row.feedback)}} ><i class="fa fa-comments" aria-hidden="true"></i></a></div>; 
                    }
                    
                }
                
            }
            else if(row.wonstatus && row.wonstatus == 'notwon'){
                if(row.feedback && row.feedback != ''){
                    return  <div className="action-block"><Link to={`${AppConstants.STAGRFP}${"/viewrfpinfo/"}${row.rfp_id}`} className="view-icon" title="View Opportunity Details"><i class="fa fa-eye" aria-hidden="true"></i></Link> 
            
                    <a href="javascript:void(0);"className="applied not-applied" title="Lost Opportunity"></a>
                    
                    <a href="javascript:void(0);" onClick={ this.unlikelikeRfp(row.rfp_id,row.company_id) } className="applied liked" title="Opportunity Liked" ></a>
                    <a href="javascript:void(0);" className="view-icon feedback-taken" title="Feedback Added"  onClick={(e) =>{this.Feedback(e, row.rfp_id,this.props.auth.user._id,row.name_ofrfp,row.feedback)}} ><i class="fa fa-comments" aria-hidden="true"></i></a></div>; 
                }
                else
                {
                    return  <div className="action-block"><Link to={`${AppConstants.STAGRFP}${"/viewrfpinfo/"}${row.rfp_id}`} className="view-icon" title="View Opportunity Details"><i class="fa fa-eye" aria-hidden="true"></i></Link> 
            
                    <a href="javascript:void(0);"className="applied not-applied" title="Lost Opportunity"></a>
                
                    <a href="javascript:void(0);" onClick={ this.unlikelikeRfp(row.rfp_id,row.company_id) } className="applied liked" title="Opportunity Liked" ></a>
                    <a href="javascript:void(0);" className="view-icon feedback" title="No Feedback"  onClick={(e) =>{this.Feedback(e, row.rfp_id,this.props.auth.user._id,row.name_ofrfp,row.feedback)}} ><i class="fa fa-comments" aria-hidden="true"></i></a></div>; 
                }
                
            }
            else
            {
                if(row.feedback && row.feedback != ''){
                    return  <div className="action-block"><Link to={`${AppConstants.STAGRFP}${"/viewrfpinfo/"}${row.rfp_id}`} className="view-icon" title="View Opportunity Details"><i class="fa fa-eye" aria-hidden="true"></i></Link> 
            
                    <a href="javascript:void(0);" onClick={ this.applyRfp(row.rfp_id,row.company_id) } title="Apply Opportunity" className="applied not-applied"></a> 
                        
                    <a href="javascript:void(0);" onClick={ this.likeRfp(row.rfp_id,row.company_id) } className="applied unliked" title="Like Opportunity"></a> 
                    <a href="javascript:void(0);" className="view-icon feedback-taken" title="Feedback Added" onClick={(e) =>{this.Feedback(e, row.rfp_id,this.props.auth.user._id,row.name_ofrfp,row.feedback)}} ><i class="fa fa-comments" aria-hidden="true"></i></a></div>;
                }
                else
                {
                    return  <div className="action-block"><Link to={`${AppConstants.STAGRFP}${"/viewrfpinfo/"}${row.rfp_id}`} className="view-icon" title="View Opportunity Details"><i class="fa fa-eye" aria-hidden="true"></i></Link> 
            
                    <a href="javascript:void(0);" onClick={ this.applyRfp(row.rfp_id,row.company_id) } title="Apply Opportunity" className="applied not-applied"></a> 
                        
                    <a href="javascript:void(0);" onClick={ this.likeRfp(row.rfp_id,row.company_id) } className="applied unliked" title="Like Opportunity"></a> 
                    <a href="javascript:void(0);" className="view-icon feedback" title="No Feedback" onClick={(e) =>{this.Feedback(e, row.rfp_id,this.props.auth.user._id,row.name_ofrfp,row.feedback)}} ><i class="fa fa-comments" aria-hidden="true"></i></a></div>;
                }
                
            }
            //return  <div className="action-block"><Link to={"/viewrfpinfo/"+row.rfp_id} className="btn btn-sm" title="View Rfp/Rfis Details"><i class="fa fa-eye" aria-hidden="true"></i>View</Link> &nbsp; &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;
            
            {/* <a href="javascript:void(0);" className="applied"></a> &nbsp; */}
            
           // <a href="javascript:void(0);" onClick={ this.applyRfp(row.rfp_id) } title="Apply RFP/RFIs" className="applied not-applied"></a> &nbsp;
            
            //<a href="javascript:void(0);" onClick={ this.likeRfp(row.rfp_id) } className="applied liked" title="Like RFP/RFI"></a></div>; 
          }
               
    }

    

        dateFormat(cell,row) {
            // var date = row.rfp_post_date;
            var posteddate = row.rfp_post_date;
            var posteddate= new Date(posteddate);             
            return   ("0" + (posteddate.getMonth() + 1)).slice(-2) + "-" + (posteddate.getDate() < 10 ? '0' : '')+ posteddate.getDate() + "-" +  posteddate.getFullYear(); 
        }
        duedateFormat(cell,row){
            var duedate = row.due_dateandtime;
            var duedate= new Date(duedate);
            var hours = duedate.getHours();
            var minutes = duedate.getMinutes();
            var ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes < 10 ? '0'+minutes : minutes;
            var strTime = hours + ':' + minutes + ' ' + ampm;
            return  ("0" + (duedate.getMonth() + 1)).slice(-2) + "-" + (duedate.getDate() < 10 ? '0' : '')+ duedate.getDate() + "-" + duedate.getFullYear() + " " + strTime;
        }
        
    
    
  render() {    
    
const options = {
  page: 1,  // which page you want to show as default
  sizePerPageList: [ {
    text: '10', value: 10
  }, {
    text: '25', value: 25
  }, {
    text: 'All', value: this.state.rfprfiprofilesdata.length
  } ], // you can change the dropdown list for size per page
  sizePerPage: 10,  // which size per page you want to locate as default
  pageStartIndex: 1, // where to start counting the pages
  paginationSize: 5,  // the pagination bar size.
  prePage: '<', // Previous page button text
  nextPage: '>', // Next page button text
  firstPage: 'First', // First page button text
  lastPage: 'Last', // Last page button text
  paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
  paginationPosition: 'bottom'  // default is bottom, top and both is all available
 
};
if(localStorage.jwtToken != undefined && this.props.auth.user.type =='vendor'){
    return (
        
        <div>
            <ReactTitle title="Dashboard" />
            <Modal isOpen={this.state.modal} toggle={this.toggle} >
                <ModalHeader  toggle={this.toggle} className="justify-content-center">Vendor Feedback</ModalHeader>
                <ModalBody >
                <span className="error-inva" style={{color:'green',position:'relative',top:'-10px', padding: "0px 0 0 86px"}}>{this.state.successMsg}</span>
                <span className="error-inva" style={{color:'red',position:'relative',top:'-10px',padding: "0px 0 0 86px"}}>{this.state.errMsg}</span>
                <p>{this.state.name_ofrfp}</p>
                
                {/* <span className="error" style={{color: 'red'}}>{this.state.errMsg}</span> */}
                    
                    <div className="row m-0 item-block mb-2">
                    <div className="col-8 p-0"></div>
                        <FormGroup className="w-100 mt-2">
                            <Input type="textarea" name="text" name="feedback"   onChange={ this.handleChange }  value={this.state.feedback} style={{border: "1px solid #d3aa55",padding: "5px"}}/>
                            <span className="error" style={{color: 'red'}}>{this.state.errors["feedback"]}</span>
                        </FormGroup>
                    </div>
                </ModalBody>
                <ModalFooter className="justify-content-center border-0">
                    <Button className="btn-reg" onClick={this.handleSubmit}>Send Feedback</Button>
                </ModalFooter>
            </Modal>
            <div className="inner-banner">
            </div>
            <div className="inner-content">
                <Container>
                    <h1>Dashboard</h1>
                    <div className="dash-stats-block">
                        <Row className="row-flex  justify-content-md-center">
                            <Col lg="4" md="4"> 
                                <Link to={`${AppConstants.STAGRFP}${"/rfprfis"}`}>
                                    <div className="dash-stats-inner com-stat" title="Related opportunities on the platform">
                                        <h2>{this.state.rfprfiprofilesdatacount}</h2>
                                        <h6>Total</h6>
                                        <span>Related Opportunities on the platform</span>
                                        <div className="stat-icon"></div>
                                    </div>
                                </Link>
                             {/* 
                                <Link to="/rfprfis">
                                    <div className="dash-stats-inner com-stat">
                                        <h2>{this.state.rfprfi_count}</h2>
                                        <h6>Total</h6>
                                        <div className="stat-icon"></div>
                                    </div>
                                </Link>*/}
                            </Col>
                            <Col lg="4" md="4">
                                <Link to={`${AppConstants.STAGRFP}${"/activerfprfis"}`}>
                                    <div className="dash-stats-inner ven-stat" title="All opportunities on the platform">
                                        <h2>{this.state.rfprfiactivecount}</h2>
                                        <h6>Active</h6>
                                        <span>All Opportunities on the platform</span>
                                        <div className="stat-icon"></div>
                                    </div>
                                </Link>
                            </Col>
                            <Col lg="4" md="4">
                                <Link to={`${AppConstants.STAGRFP}${"/likedrfprfis"}`}>
                                    <div className="dash-stats-inner post-stat" title="Liked opportunities on the platform">
                                        <h2>{this.state.likeddashboarddata}</h2>
                                        <h6>Liked</h6>
                                        <span>Liked Opportunities on the platform</span>
                                        <div className="stat-icon"></div>
                                    </div>
                                </Link>
                            </Col>                           
                            <Col lg="4" md="4">
                                <Link to={`${AppConstants.STAGRFP}${"/appliedrfpsreport"}`}>
                                    <div className="dash-stats-inner applied-stat" title="Applied opportunities on the platform">
                                        <h2>{this.state.applieddashboarddata}</h2>
                                        <h6>Applied</h6>
                                        <span>Applied Opportunities on the platform</span>
                                        <div className="stat-icon"></div>
                                    </div>
                                </Link>
                            </Col>
                            <Col lg="4" md="4"> 
                                <Link to={`${AppConstants.STAGRFP}${"/notappliedrfpsreport"}`}>
                                    <div className="dash-stats-inner not-applied-stat" title="Not Applied opportunities on the platform">
                                        <h2>{this.state.notappliedvendorscount}</h2>
                                        <h6>Not Applied</h6>
                                        <span>Not Applied Opportunities on the platform</span>
                                        <div className="stat-icon"></div>
                                    </div>
                                </Link>
                            </Col>
                            <Col lg="4" md="4">
                                <Link to={`${AppConstants.STAGRFP}${"/subscribedcompanyreports"}`}>
                                    <div className="dash-stats-inner companies-stat" title="Companies Subscribed">
                                        <h2>{this.state.subscribeddata}</h2>
                                        <h6>Subscribed</h6>
                                        <span>Companies You Subscribed</span>
                                        <div className="stat-icon"></div>
                                    </div>
                                </Link>
                            </Col>
                            {/* <Col lg="3" md="4">
                                <Link to={`${AppConstants.STAGRFP}${"/wonrfprfisreport"}`}>
                                    <div className="dash-stats-inner won-stat" title="Won opportunities on the platform">
                                        <h2>{this.state.wondashboarddata}</h2>
                                        <h6>Won</h6>
                                        <span>Won Opportunities on the platform</span>
                                        <div className="stat-icon"></div>
                                    </div>
                                </Link>
                            </Col>
                            <Col lg="3" md="4">
                                <Link to={`${AppConstants.STAGRFP}${"/lostrfprfisreport"}`}>
                                    <div className="dash-stats-inner lost-stat" title="Lost opportunities on the platform">
                                        <h2>{this.state.notwondashboarddata}</h2>
                                        <h6>Lost</h6>
                                        <span>Lost Opportunities on the platform</span>
                                        <div className="stat-icon"></div>
                                    </div>
                                </Link>
                            </Col> */}
                        </Row>                                                                                          
                    </div>
                    
                    <div className="dashboard-rfp-list">
                        <div className="dashboard-rfp-head dashboard-rfp-icon">
                            <h6>Opportunity List</h6>                                            
                            <span className={this.state.allanimate +" all"}  onClick={ this.Allrelateddata('alldata') } title="All Opportunities">All</span>
                            <span className={this.state.applyanimate +" applied"}  onClick={ this.apply('apply') } title="Applied Opportunities">Applied</span>
                            <span className={this.state.likedanimate +" liked"} onClick={ this.liked('liked') }  title="Liked Opportunities">Liked</span>
                            
                        </div>
                        <BootstrapTable data={this.state.rfprfiprofilesdata} pagination={ true } options={ options } search={true} searchPlaceholder={'Search by Opportunity Name or Company Name'}>
                            
                            {/* <TableHeaderColumn dataField='_id' isKey={ true } dataSort={ true } hidden={true}>ID</TableHeaderColumn> */}

                            <TableHeaderColumn dataField='name_ofrfp' isKey={ true } dataSort={ true }>Name Of Opportunity</TableHeaderColumn>
                            <TableHeaderColumn dataField='company_name' dataSort={ true }>Company Name </TableHeaderColumn>
                            <TableHeaderColumn dataFormat={this.dateFormat.bind(this)} dataField='rfp_post_date' dataSort={ true }>Posted Date </TableHeaderColumn>
                            <TableHeaderColumn dataField='due_dateandtime' dataFormat={this.duedateFormat.bind(this)} dataSort={ true }>Due Date & Time</TableHeaderColumn>
                            <TableHeaderColumn dataField='Action' dataFormat={ this.actionBtn.bind(this) }>Actions</TableHeaderColumn>
                        </BootstrapTable>
                    </div>

                   
                            
                            <div className="graph-block">
                                <div className="graph-date">
                                <Row className="justify-content-md-center ">
                                <div className="col-md-2 mb-0">
                                <Input type="select" name = "reports"  onChange={ this.handleInputChange } value={this.state.type}>
                                    <option value="ThisWeek">This Week </option>
                                    <option value="Monthly">This Month</option>
                                    <option value="Yearly">Yearly</option>
                                    <option value="Custom">Custom</option>
                                </Input>
                                </div>
                                <div className="form-field  col-md-2">
                                    <div className="graph-field">
                                        <DatePicker
                                            name="start_date"
                                            className="form-control"
                                            //placeholderText="MM-DD-YYYY"
                                            placeholderText={this.state.placeholderSD}
                                            //placeholderText="02/09/2019"
                                            selected={this.state.start_date}
                                            selectsStart
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            startDate={this.state.startDate}
                                            endDate={this.state.endDate}
                                            onChange={this.handleStartdateChange}
                                            autoComplete = "off"
                                            minDate={new Date().setFullYear(new Date().getFullYear() - 5)}   
                                            maxDate={new Date()}
                                             />
                                        <i class="fa fa-calendar" aria-hidden="true"></i>
                                        <span className="error" style={{color: 'red'}}>{this.state.type_error}</span>
                                        </div>
                                    </div>
                                    <div className="form-field  col-md-2">
                                        
                                        <div className="graph-field">
                                        <DatePicker
                                            name="end_date"
                                            className="form-control"
                                            //placeholderText="MM-DD-YYYY"
                                            placeholderText={this.state.placeholderTD}                    
                                            selected={this.state.end_date}
                                            selectsEnd
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            //startDate={this.state.startDate}
                                            //
                                            endDate={this.state.endDate}
                                            onChange={this.handleEndDateChange}
                                            autoComplete = "off"

                                            minDate={new Date().setFullYear(new Date().getFullYear() - 5)}   
                                            maxDate={new Date()}
                                            className="form-control" />
                                        <i class="fa fa-calendar" aria-hidden="true"></i>
                                        <span className="error" style={{color: 'red'}}>{this.state.type_error2}</span>
                                        </div>
                                    </div>
                                    <span className="error">{this.state.errors["range_date"]}</span>
                                    </Row>
                                    </div>                                
                                <Line data={this.state.genLineDT} width={100} height={50} options={options} />
                                </div>
                        

                </Container>
            </div>
        </div>
        
        );
    }
    else
    {
        window.location.href = AppConstants.STAGRFP+'/';
    }
  }
}
dashboard.propTypes = {
    handlevendordashboard:PropTypes.func.isRequired
};
const mapStateToProps = (state) => ({
    auth: state.auth
  })
  //export default Header;
  export default connect(mapStateToProps, {handlevendordashboard})(withRouter(dashboard));

import React, { Component } from 'react';
import { MDBInput,MDBBtn  } from 'mdbreact';
import SlidingPane from 'react-sliding-pane';
import {Row} from 'reactstrap';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import axios from 'axios';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AppConstants from '../AppConstants';
import Vendorpassword from '../pages/vendorpassword';

let vendorid = '';
function LoadPane(props) {
  //const isOpenPaneName = props.isOpenPaneName;  
  return <Vendorpassword vendor_id={vendorid}/>;
}

class vendorotp extends React.Component {
    constructor(props){
      console.log(props);
        super(props);
        this.state = {
            fields: {},
            errors: {},
            invalidUser:'',
            errMsg:'',
            successMsg:'',
            "vendor_id":this.props.vendor_id,
            "otp":'',
            "vendorid":''
        }
    }    
    
    handleChange(field, e){    		
        let fields = this.state.fields;
        fields[field] = e.target.value;        
        this.setState({fields});
    }
    /** Validations */
  handleValidation(){
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    
    if(!fields["otp"]){
      formIsValid = false;
      errors["otp"] = "Please enter Verification Code";
    }
    this.setState({errors: errors});
    return formIsValid;
    }
    handleSubmit = event => {
        //console.log(process.env.REACT_APP_API_URL+'/users/login');
        event.preventDefault();
        if(this.handleValidation()){
          const vendorotp = {
            vendor_id:this.props.vendor_id,
            otp: this.state.fields['otp']
          }
          //console.log(adminforgotpwd);
          axios.post(AppConstants.API+'/vendors/vendor_otp_auth',vendorotp)
            .then(res => {   
              //console.log(res);   
             if(res.data.Status == "Success"){
              vendorid = res.data.vendor_id;
              setTimeout((e) => {
                this.setState({ isPaneOpen: true,paneName:'vendorpassword'});
            }, 5000)         
              this.setState({
                successMsg: res.data.msg
              });          
            }
            else{          
              this.setState({
                errMsg: res.data.msg
              });
              setTimeout((e) => {
                this.setState({
                  errMsg: ''
                });
               },5000);       
            } 
          });      
        }
      };
      ResendOtpSubmit = event => {
        event.preventDefault();
        const resendvendorrefno = {
          vendor_id:this.props.vendor_id
        }
        axios.post(AppConstants.API+'/vendors/vendor_otp_resend',resendvendorrefno)
        .then(res => {   
         if(res.data.Status == "Success"){
          setTimeout((e) => {
            this.setState({
              successMsg: false
            });
           },5000);     
          this.setState({
            successMsg: res.data.msg
          });          
        }
        else{          
          this.setState({
            errMsg: res.data.msg
          });
          setTimeout((e) => {
            this.setState({
              errMsg: ''
            });
           },5000);       
        } 
      });
    }
      componentDidMount() {
        console.log("registered vendor id: "+this.props.vendor_id);
        //console.log("test ", this.props);
      }
      

    render() {     
      return (          
          <div className="login-block">
          {this.state.companyid}
              <h6 className="color-yellow">Verification Code has been sent to your email.<br/>
              Please enter the Verification Code below to verify your email address.</h6>
              
              <div className="form-block">
                <form name="otpauthentication" onSubmit= {this.handleSubmit.bind(this)}>
                    <span className="error-inva" style={{color: 'green', position: 'relative',    top: '-10px'}}>{this.state.successMsg}</span>
                    <span className="error-inva" style={{color: 'red', position: 'relative',    top: '-10px'}}>{this.state.errMsg}</span>
                    <Row>
                    <div className="form-field col-md-12">
                        <MDBInput label="Enter Verification Code *" type="text" name="otp" onChange={this.handleChange.bind(this, "otp")} value={this.state.fields["otp"]} />
                        <span className="error" style={{color: 'red'}}>{this.state.errors["otp"]}</span>
                    </div>
                    <div className="form-field col-md-12">
                        <MDBBtn type="submit" title="Submit Verification Code">Submit</MDBBtn>
                    </div>
                    
                      <div className="forgot-block text-center col-md-12">Re-send Verification Code <a onClick={this.ResendOtpSubmit.bind(this)} title="Re-send Verification Code"> Click Here</a></div>
                  </Row>
                </form>                
                </div>
                <SlidingPane
                    className={this.state.paneName}
                    overlayClassName='some-custom-overlay-class'
                    isOpen={ this.state.isPaneOpen }
                    onRequestClose={ () => {
                    this.setState({ isPaneOpen: false });
                    } }>
                    <LoadPane isOpenPaneName={this.state.paneName} />
                </SlidingPane>                             
          </div>          
      );
    }
  }

  vendorotp.propTypes = {
    //companyid: PropTypes.object.isRequired
  };
  const mapStateToProps = (state) => ({
    //company__id:state
  });
  export  default connect(mapStateToProps, {})(vendorotp);